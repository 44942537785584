import React, { useContext, useEffect, useState } from 'react';
import { Row, Form, Col, Input, Checkbox, Button } from 'antd';
import { GlobalOutlined, SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';

import styles from './Os.module.css';
import TargetingContext from '../../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../../contexts/campaignContext';
import { TargetingPreview } from '../../../../components/TargetingPreview';
import { TargetingSelect } from '../../../../components/TargetingSelect';

export const OSTab = ({ changeActiveTab }) => {
  const { osAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [shownOS, setShownOS] = useState([]);
  const [simpleOsAll, setSimpleOsAll] = useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let osArray = osAll.map((el) => {
      return { label: el.name, value: el.id };
    });
    // if (targeting.os.length < 1) {
    //   const selectedC = osAll.map((os) => {
    //     return { id: os.id };
    //   });
    //   setSelectedAll(true);
    //   dispatchTargeting({ type: 'os', payload: selectedC });
    // } else {
    //   setShowVersions(true);
    // }
    setSimpleOsAll(osArray);
    setShownOS(osArray);
    setIsLoading(false);
  }, []);

  const onSearch = (e) => {
    // problem ?
    let osAll = simpleOsAll.filter((os) => {
      return os.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    osAll = osAll.length > 0 ? osAll.concat(simpleOsAll.filter((item) => !osAll.some((os) => os.label === item.label))) : [];
    setShownOS(osAll);
  };

  const setSelectedItems = (checked) => {
    dispatchTargeting({ type: 'os', payload: checked });
    setSelectedAll(checked.length === osAll.length);
  };

  const onCheckAllChange = (e) => {
    const selectedC = osAll.map((os) => {
      return { id: os.id };
    });
    dispatchTargeting({ type: 'os', payload: e.target.checked ? selectedC : [] });
    setSelectedAll(e.target.checked);
    setShowVersions(false);
  };

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Target Operating Systems</p>
          </div>
          <p>Target Operating Systems or Versions of Operating Systems</p>
        </div>
      </Row>
      <Row>
        <Form style={{ width: '100%' }}>
          <Row className={styles.targetingSelectContainer}>
            <Col className={styles.targetingSelectBox}>
              <Row className={styles.targetingSelectDivLeft}>
                <Form.Item className={styles.targetingSearchItem} name="search">
                  <Input onChange={onSearch} prefix={<SearchOutlined />} placeholder="Search OS" />
                </Form.Item>
                <Checkbox
                  className={styles.checkboxVersion}
                  checked={showVersions}
                  onChange={(e) => setShowVersions(e.target.checked)}
                  disabled={selectedAll}
                >
                  Show OS Versions
                </Checkbox>
              </Row>
              <TargetingSelect
                allItems={osAll}
                simpleItems={shownOS}
                showVersions={showVersions}
                checkAll={selectedAll}
                selectedItems={targeting.os.map((item) => {
                  return { ...item, id: String(item.id) };
                })}
                handleItemSelect={setSelectedItems}
                handleCheckAll={onCheckAllChange}
                itemName="Operating Systems"
                isLoading={isLoading}
                hasVersions={true}
              />
            </Col>
            <Col className={styles.targetingPreviewBox}>
              <Row className={styles.targetingSelectDiv} style={{ padding: '10px' }}>
                <h3 style={{ width: '100%', color: '#58a54f' }}>Included</h3>
                {targeting.os.length === 0 ? (
                  <Row className={styles.countBox}>All Operating Systems</Row>
                ) : (
                  <Row className={styles.countBox}>
                    <span>
                      Operating Systems <span className={styles.countDisplay}>{targeting.os.length}</span>
                    </span>
                  </Row>
                )}
              </Row>
              <TargetingPreview
                allItems={simpleOsAll}
                allItemsSelected={selectedAll}
                selectedItems={targeting.os.map((item) => {
                  return { ...item, id: String(item.id) };
                })}
                handleItemSelect={setSelectedItems}
                showVersions={showVersions}
                hasVersions={true}
                itemName="Operating Systems"
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('browsers')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
