import { Radio } from 'antd';
import React, { useState } from 'react';
import styles from './VariationPreview.module.css';

const options = [
  { label: 'Mobile', value: 'mobile' },
  { label: 'Desktop', value: 'desktop' }
];

export const VariationPreview = ({ type, title, message, icon, image }) => {
  const [currentDevice, setCurrentDevice] = useState('mobile');
  return (
    <div className={styles.notPreviewContainer}>
      {/* <p className={styles.sendNotifText}>Notification Sample</p> */}
      <Radio.Group
        options={options}
        value={currentDevice}
        onChange={(e) => setCurrentDevice(e.target.value)}
        optionType="button"
        buttonStyle="solid"
        className={styles.radioSelect}
      />

      <div className={styles.mainNotificationPreview}>
        <div
          style={{
            backgroundImage: currentDevice === 'desktop' ? "url('/images/winPreview.png')" : "url('/images/mobile.jpg')"
          }}
          className={currentDevice === 'mobile' ? styles.deviceBackground : styles.deviceBackgroundWindows}
        >
          <div className={styles.coreNotifBox}>
            <div className={styles.iconTextWrapper}>
              <div className={styles.dynamicTextWrapper}>
                <div>{title || 'Notification title'}</div>
                <div>{message || 'Notification message'}</div>
              </div>
              {type !== 'Native' && <img className={styles.dynamicIcon} src={icon || '/images/notification.png'} alt="Notificon" />}
            </div>
            <img className={styles.dynamicImage} src={image || '/images/messageNotification.png'} alt="Notifimage" />
          </div>
        </div>
      </div>
    </div>
  );
};
