import { Divider, Spin } from 'antd';
import React from 'react';
import { VectorMap } from 'react-jvectormap';
import styles from '../Dashboard.module.css';

const colors = ['#A3A1FB', '#50D8BB', '#FFA167', '#FF7CC3', '#81C9FF', '#FF8474'];

export const EarningsMap = ({ allCountries, topCountries, mapData, loading }) => {
  const renderEarningsList = () => {
    let countriesSection = [];
    if (topCountries.length > 0 && allCountries) {
      countriesSection = topCountries.map((country, index) => {
        let countryName = allCountries.filter((countrySingle) => countrySingle.code === country.country);
        return (
          <p key={country.country}>
            <span
              id="example-one"
              className={styles.mapEarningsList}
              style={{
                border: `3px solid ${colors[index]}`
              }}
            />{' '}
            {countryName.length > 0 ? countryName[0].name : country.country}
            <span style={{ float: 'right' }}>{country.spent && country.spent.toFixed(2)} $</span>
          </p>
        );
      });
    }
    return countriesSection;
  };

  return (
    <Spin wrapperClassName={styles.earningsMapContainer} spinning={loading}>
      <div className={styles.earningsMapHeader}>
        <p>Spent on Map</p>
        {/* <span>Show All List</span> */}
      </div>
      <VectorMap
        map={'world_mill'}
        backgroundColor="transparent"
        zoomOnScroll={false}
        containerStyle={{
          width: '100%',
          height: '200px'
        }}
        containerClassName="map"
        regionStyle={{
          initial: {
            fill: '#e4e4e4',
            'fill-opacity': 0.9,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 0
          },
          hover: {
            'fill-opacity': 0.8,
            cursor: 'pointer'
          }
        }}
        series={{
          regions: [
            {
              values: mapData
            }
          ]
        }}
      />
      <Divider />
      {renderEarningsList()}
    </Spin>
  );
};
