import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

import styles from './PageNotFound.module.css';

export const PageNotFound = ({ from }) => {
  useEffect(() => {
    if (from) {
      // replace browser url to show original url instead of page-not-found:
      window.history.replaceState(null, '', from);
    }
  }, [from]);

  const history = useHistory();

  return (
    <div className={styles.container} style={{ backgroundImage: 'url(images/background.png)' }}>
      <div className={styles.containerGroup}>
        <p className={styles.title}>404</p>
        <p className={styles.paragraphEmptySpaceship}>This spaceship is empty</p>
        <p className={styles.paragraphNotExistPage}>The page you’re looking for doesn’t exist.</p>
        <Button type="primary" onClick={history.goBack} className={styles.goBack}>
          Go Back
        </Button>
        <img src="/images/empty-spaceship.svg" alt="page-not-found" />
      </div>
    </div>
  );
};
