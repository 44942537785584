import React from 'react';
import styles from './Targeting.module.css';
import { Row, Button, Col, Tag } from 'antd';
import { GlobalOutlined, CloseOutlined } from '@ant-design/icons';
import { ListRow } from './ListRow';

export const TargetingPreview = (props) => {
  const selectedItemsWithVersions = props.selectedItems;
  const simpleSelectedItems = props.selectedItems.map((el) => el.id);
  const showVersions = props.showVersions;
  const hasVersions = props.hasVersions;

  const removeItem = (item) => {
    let newSelectedArray = props.selectedItems.filter((elem) => {
      if (elem.id) {
        return elem.id !== item;
      } else {
        return elem !== item;
      }
    });
    props.handleItemSelect(newSelectedArray);
  };

  const removeList = (item) => {
    let newSelectedArray = props.selectedLists.filter((elem) => {
      if (elem.id) {
        return elem.id !== item;
      } else {
        return elem !== item;
      }
    });
    props.setSelectedLists(newSelectedArray);
  };

  const ItemRowWithVersion = (item) => {
    let itemName = props.fromTextArea ? item : props.allItems.filter((x) => x.value === item.id)[0];
    if (!props.fromTextArea) {
      itemName = typeof itemName !== 'undefined' ? itemName.label : '';
    }
    return (
      <Row key={item.id} style={{ padding: '10px', borderBottom: '1px solid #cccccc', alignItems: 'center' }}>
        <Col style={{ width: '90%' }}>
          <span>{itemName}</span>{' '}
          {item.version && item.version.length ? (
            <span className={styles.previewVersionTypeSpan}>{item.op === 'min_version' ? 'min version' : 'exact version'}</span>
          ) : (
            <Tag color="blue">All Versions</Tag>
          )}
        </Col>
        <Col style={{ width: '10%' }}>
          <Button type="danger" ghost size="small" shape="circle" className={styles.countryRowCancel} onClick={(e) => removeItem(item.id)}>
            <CloseOutlined />
          </Button>
        </Col>
        <Row className={styles.versionsContainer}>
          {item.version &&
            item.version.map((el) => {
              return (
                <div key={el}>
                  <span style={{ color: '#ccc' }}>--</span>
                  <span className={styles.previewWithVersionSpan}>{el}</span>
                </div>
              );
            })}
        </Row>
      </Row>
    );
  };

  const ItemRow = (item) => {
    let itemName = props.fromTextArea ? item : props.allItems.filter((x) => x.value === item)[0];
    if (!props.fromTextArea) {
      itemName = typeof itemName !== 'undefined' ? itemName.label : '';
    }
    return (
      <div key={item} className={styles.selectionTag}>
        {itemName}
        <CloseOutlined onClick={() => removeItem(item)} />
      </div>
    );
    // return (
    //   <Row key={item} style={{ padding: '10px', borderBottom: '1px solid #cccccc', alignItems: 'center' }}>
    //     <Col style={{ width: '90%' }}>
    //       <span>{itemName}</span>
    //     </Col>
    //     <Col style={{ width: '10%' }}>
    //       <Button
    //         style={{ color: '#d95c5c', borderColor: '#d95c5c' }}
    //         ghost
    //         size="small"
    //         shape="circle"
    //         className={styles.countryRowCancel}
    //         onClick={(e) => removeItem(item)}
    //       >
    //         <CloseOutlined />
    //       </Button>
    //     </Col>
    //   </Row>
    // );
  };

  return (
    <div className={styles.targetingPreviewParentDiv} style={props.height ? { height: props.height } : {}}>
      {props.allItemsSelected || props.selectedItems.length === 0 ? (
        <div className={styles.allCountriesSelectedDiv}>
          <div>
            <GlobalOutlined />
            <p>All {props.itemName} are included in the targeting of this campaign.</p>
          </div>
          <p>Make a selection on the left to include specific targeting</p>
        </div>
      ) : (
        <div>
          {props.hasLists &&
            props.selectedLists.map((item) => (
              <ListRow key={item} item={item} targetingLists={props.targetingLists} removeList={removeList} />
            ))}
          {/* {simpleSelectedItems.length >= 1 ? (
            <Row style={{ padding: '7px', display: 'flex', justifyContent: 'flex-end' }}>
              {' '}
              <Button
                onClick={(e) => {
                  props.handleItemSelect([]);
                }}
                size="small"
                danger
              >
                Clear all
              </Button>
            </Row>
          ) : (
            ''
          )} */}
          <div className={styles.selectedCountries}>
            {simpleSelectedItems.length >= 1 ? (
              <Button
                onClick={(e) => {
                  props.handleItemSelect([]);
                }}
                style={{ marginLeft: '0.5rem', marginTop: '0.4rem' }}
                size="small"
                danger
              >
                Clear all
              </Button>
            ) : (
              ''
            )}
            {hasVersions
              ? showVersions
                ? selectedItemsWithVersions.map((item) => ItemRowWithVersion(item))
                : simpleSelectedItems.map((item) => ItemRow(item))
              : selectedItemsWithVersions.map((item) => ItemRow(item))}
          </div>
        </div>
      )}
      {/* <Row className={styles.potentialReach}>
        <h3 style={{ paddingLeft: '10px' }}>Potential reach</h3>
        <span style={{ padding: '10px 10px 0px 10px' }}>565k - 750k impressions</span>
        <div style={{ background: '#DEDEDE', height: '15px', width: '100%', margin: '10px' }}></div>
      </Row> */}
    </div>
  );
};
