import React from 'react';
import styles from './Targeting.module.css';
import { GlobalOutlined } from '@ant-design/icons';
import { TargetingPreviewGroup } from './TargetingPreviewGroup';

export const TargetingPreviewV2 = ({ targeting, categoriesMatchAll, categoriesCount }) => {
  return (
    <div className={styles.targetingPreviewParentDiv}>
      {categoriesCount === 0 && (
        <div className={styles.allCountriesSelectedDiv}>
          <div>
            <GlobalOutlined />
            <p>Your campaign is now uncategorized</p>
          </div>
          <p>Make a selection on the left to assign category to your campaign</p>
        </div>
      )}
      {Object.keys(targeting).map((item, id) => {
        if (targeting[item].length > 0) {
          return <TargetingPreviewGroup key={id} subcategoriesAll={categoriesMatchAll} title={item} subcategories={targeting[item]} />;
        }
      })}
    </div>
  );
};
