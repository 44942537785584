import { Tag } from 'antd';
import React from 'react';
import styles from '../CampaignReview.module.css';

export const renderCountries = (countries, countriesAll) => {
  const parsedCountries = countriesAll
    .filter((country) => countries.item.includes(country.value))
    .map((country) => (
      <span key={country.value} className={styles.targetingInfoValue}>
        {country.label}
      </span>
    ));
  return (
    <div style={{ marginBottom: 6 }}>
      <span className={styles.targetingInfoType} style={{ color: countries.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {countries.op}d
      </span>
      {parsedCountries}
    </div>
  );
};

export const renderCountryList = (listTargeting, countryListAll) => {
  const parsedList = countryListAll
    .filter((list) => listTargeting.list.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: listTargeting.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {listTargeting.op}d
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};
export const renderIpList = (listTargeting, ipListAll) => {
  const parsedList = ipListAll
    .filter((list) => listTargeting.list.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: listTargeting.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {listTargeting.op + 'd'}
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};
export const renderSubidList = (listTargeting, subidListAll) => {
  const parsedList = subidListAll
    .filter((list) => listTargeting.list.includes(list.id))
    .map((list) => (
      <span key={list.id} className={styles.targetingInfoValueList}>
        {list.name} <Tag color="blue">List</Tag>
      </span>
    ));
  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: listTargeting.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {listTargeting.op + 'd'}
      </span>
      {listTargeting.list && listTargeting.list.length > 0 && parsedList}
    </div>
  );
};

export const renderOS = (targetedOs, osAll) => {
  const parsedOs = osAll
    .filter((os) => targetedOs.some((target) => Number(target.id) === Number(os.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {osAll.length === parsedOs.length ? (
        <span className={styles.targetingInfoType}>All Operating Systems</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: '#95B79A' }}>
            Included
          </span>{' '}
          {parsedOs}
        </>
      )}
    </div>
  );
};
export const renderBrowsers = (targetBrowsers, browsersAll) => {
  const parsedBrowsers = browsersAll
    .filter((browser) => targetBrowsers.some((target) => Number(target.id) === Number(browser.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return (
    <div>
      {browsersAll.length === parsedBrowsers.length ? (
        <span className={styles.targetingInfoType}>All Browsers</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: '#95B79A' }}>
            Included
          </span>{' '}
          {parsedBrowsers}
        </>
      )}
    </div>
  );
};

export const renderAges = (targetedAges, agesAll) => {
  const parseAges = () => {
    return agesAll
      .filter((age) => targetedAges.item.includes(age.value))
      .map((item) => (
        <span key={item.value} className={styles.targetingInfoValue}>
          {item.label}
        </span>
      ));
  };
  return (
    <div>
      {targetedAges.item.length === agesAll.length ? (
        <span className={styles.targetingInfoType}>All Types</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: '#95B79A' }}>
            Included
          </span>
          {parseAges()}
        </>
      )}
    </div>
  );
};

export const renderDays = (targetedDays) => {
  return (
    <div>
      {targetedDays.item.length === 7 ? (
        <span className={styles.targetingInfoType}>Every Day</span>
      ) : (
        <div>
          <span className={styles.targetingInfoType} style={{ color: targetedDays.op === 'include' ? '#95B79A' : '#D95C5C' }}>
            {targetedDays.op}d
          </span>
          <span className={targetedDays.item.includes('monday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>MON</span>
          <span className={targetedDays.item.includes('tuesday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>TUE</span>
          <span className={targetedDays.item.includes('wednesday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>WED</span>
          <span className={targetedDays.item.includes('thursday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>THU</span>
          <span className={targetedDays.item.includes('friday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>FRI</span>
          <span className={targetedDays.item.includes('saturday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>SAT</span>
          <span className={targetedDays.item.includes('sunday') ? styles.statusActiveSpan : styles.statusInactiveSpan}>SUN</span>
        </div>
      )}
    </div>
  );
};

export const renderHours = (targetedHours) => {
  const hours = {
    0: '00:00-01:00',
    1: '01:00-02:00',
    2: '02:00-03:00',
    3: '03:00-04:00',
    4: '04:00-05:00',
    5: '05:00-06:00',
    6: '06:00-07:00',
    7: '07:00-08:00',
    8: '08:00-09:00',
    9: '09:00-10:00',
    10: '10:00-11:00',
    11: '11:00-12:00',
    12: '12:00-13:00',
    13: '13:00-14:00',
    14: '14:00-15:00',
    15: '15:00-16:00',
    16: '16:00-17:00',
    17: '17:00-18:00',
    18: '18:00-19:00',
    19: '19:00-20:00',
    20: '20:00-21:00',
    21: '21:00-22:00',
    22: '22:00-23:00',
    23: '23:00-00:00'
  };
  const parseHours = () => {
    return targetedHours.item.map((hour) => (
      <span key={hour} className={styles.targetingInfoValue}>
        {hours[Number(hour)]}
      </span>
    ));
  };
  return (
    <div>
      {targetedHours.item.length === 1 && targetedHours.item[0] === '24' ? (
        <span className={styles.targetingInfoType}>24 hours a Day</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: targetedHours.op === 'include' ? '#95B79A' : '#D95C5C' }}>
            {targetedHours.op}d
          </span>
          {parseHours()}
        </>
      )}
    </div>
  );
};
export const renderConnections = (targetConnections, connections) => {
  const parseConnections = () => {
    return connections
      .filter((connection) => targetConnections.item.includes(connection.value.toString()))
      .map((item) => (
        <span key={item.value} className={styles.targetingInfoValue}>
          {item.label}
        </span>
      ));
  };
  return (
    <div>
      {targetConnections.item.length === connections.length ? (
        <span className={styles.targetingInfoType}>All Connections</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: targetConnections.op === 'include' ? '#95B79A' : '#D95C5C' }}>
            {targetConnections.op + 'd'}
          </span>
          {parseConnections()}
        </>
      )}
    </div>
  );
};

export const renderDeviceTypes = (targetedDeviceTypes, typesAll) => {
  const parseDeviceTypes = () => {
    return targetedDeviceTypes.item.map((type) => (
      <span key={type} className={styles.targetingInfoValue}>
        {type}
      </span>
    ));
  };
  return (
    <div>
      {targetedDeviceTypes.item.length === typesAll.length ? (
        <span className={styles.targetingInfoType}>All Types</span>
      ) : (
        <>
          <span className={styles.targetingInfoType} style={{ color: targetedDeviceTypes.op === 'include' ? '#95B79A' : '#D95C5C' }}>
            {targetedDeviceTypes.op + 'd'}
          </span>
          {parseDeviceTypes()}
        </>
      )}
    </div>
  );
};
export const renderIpIsp = (targetedIpIsp) => {
  const parseIps = () => {
    return targetedIpIsp.item.map((ip) => (
      <span key={ip} className={styles.targetingInfoValue}>
        {ip}
      </span>
    ));
  };
  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: targetedIpIsp.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {targetedIpIsp.op}d
      </span>
      {parseIps()}
    </div>
  );
};
export const renderDeviceManufacturers = (targetedDeviceManufacturers, manufacturersAll) => {
  const parseDeviceManufacturers = manufacturersAll
    .filter((manufacturer) => targetedDeviceManufacturers.item.includes(manufacturer.value))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));

  return (
    <div>
      {targetedDeviceManufacturers.item.length === manufacturersAll.length ? (
        <span className={styles.targetingInfoType}>All Manufacturers</span>
      ) : (
        <>
          <span
            className={styles.targetingInfoType}
            style={{ color: targetedDeviceManufacturers.op === 'include' ? '#95B79A' : '#D95C5C' }}
          >
            {targetedDeviceManufacturers.op + 'd'}
          </span>
          {parseDeviceManufacturers}
        </>
      )}
    </div>
  );
};

export const renderCategories = (targetedCategories, categoriesAll) => {
  const parseTargetedCategories = categoriesAll
    .filter((category) => targetedCategories.item.includes(category.value))
    .map((item) => (
      <span key={item.label} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));

  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: targetedCategories.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {targetedCategories.op}d
      </span>
      {parseTargetedCategories}
    </div>
  );
};

export const renderSubIDPlacements = (targetedSubID) => {
  const parseSubIDs = () => {
    return targetedSubID.item.map((subId, id) => (
      <span key={id} className={styles.targetingInfoValue}>
        {subId.replace(/\.\*$/, '')}
      </span>
    ));
  };
  return (
    <div>
      <span className={styles.targetingInfoType} style={{ color: targetedSubID.op === 'include' ? '#95B79A' : '#D95C5C' }}>
        {targetedSubID.op}d
      </span>
      {parseSubIDs()}
    </div>
  );
};

export const renderConnectionsBidding = (targetConnections, connections) => {
  const parsedConnections = connections
    .filter((connection) => targetConnections.includes(connection.value.toString()))
    .map((item) => (
      <span key={item.value} className={styles.targetingInfoValue}>
        {item.label}
      </span>
    ));
  return parsedConnections;
};
export const renderCountriesBidding = (countries, countriesAll) => {
  const parsedCountries = countriesAll
    .filter((country) => countries.includes(country.value))
    .map((country) => (
      <span key={country.value} className={styles.targetingInfoValue}>
        {country.label}
      </span>
    ));
  return parsedCountries;
};

export const renderOSBidding = (targetedOs, osAll) => {
  const parsedOs = osAll
    .filter((os) => targetedOs.some((target) => Number(target) === Number(os.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return parsedOs;
};
export const renderBrowsersBidding = (targetBrowsers, browsersAll) => {
  const parsedBrowsers = browsersAll
    .filter((browser) => targetBrowsers.some((target) => Number(target) === Number(browser.id)))
    .map((item) => (
      <span key={item.id} className={styles.targetingInfoValue}>
        {item.name}
      </span>
    ));
  return parsedBrowsers;
};
