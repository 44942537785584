import React, { useContext } from 'react';
import { Row } from 'antd';
import styles from '../CampaignReview.module.css';
import { renderCategories, renderSubIDPlacements } from '../utils/targetingItemRender';
import TargetingContext from '../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../contexts/campaignContext';

export const TrafficInfo = () => {
  const { categoriesAll } = useContext(TargetingContext);
  const { targeting } = useContext(CampaignContext);
  const categoriesParsedAll = [].concat.apply([], Object.values(categoriesAll.map((item) => item.subcategories)));
  const targetingCategory = [].concat.apply([], Object.values(targeting.category.item));
  return (
    <Row style={{ marginBottom: '30px', width: '100%' }}>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Traffic Categories</h3>
        {targetingCategory.length > 0 ? (
          renderCategories({ ...targeting.category, item: targetingCategory }, categoriesParsedAll)
        ) : (
          <span className={styles.targetingInfoType}>All Categories</span>
        )}
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>SubID & Placements</h3>
        {targeting.subid && targeting.subid.item && targeting.subid.item.length > 0 ? (
          renderSubIDPlacements(targeting.subid)
        ) : (
          <span className={styles.targetingInfoType}>No Targeting</span>
        )}
      </Row>
    </Row>
  );
};
