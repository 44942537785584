import React, { useContext } from 'react';
import { Row } from 'antd';
import styles from '../CampaignReview.module.css';
import moment from 'moment';
import CampaignContext from '../../../../../contexts/campaignContext';

export const GaneralConfigInfo = () => {
  const { campaign } = useContext(CampaignContext);
  const { pricing, limit, general } = campaign;
  return (
    <Row style={{ marginBottom: '30px', width: '100%' }}>
      <h3 className={styles.generalInfoHeader}>Campaign General Info</h3>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Campaign Name</span>{' '}
        <span className={styles.generalInfoValue}>{general.name && general.name}</span>
      </Row>
      {/* <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Account Name</span> <span className={styles.generalInfoValue}>Example Advertiser</span>
      </Row> */}
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Current Status</span>{' '}
        <span className={styles.generalInfoValue}>{general.status ? general.status : 'PENDING'}</span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Status after approval</span>{' '}
        <span className={styles.generalInfoValue}>{general.status_after_approval}</span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Campaign Vertical</span>{' '}
        <span className={styles.generalInfoValue}>{general.vertical && general.vertical}</span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Prelander</span>{' '}
        <span className={styles.generalInfoValue}>{general.has_prelander === true ? 'YES' : 'NO'}</span>
      </Row>
      {/* <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Start Date</span>
        <span className={styles.generalInfoValue}>
          {general.start_time ? moment(general.start_time).format('MM Do YYYY, h:mm a') : 'Instant after approval'}{' '}
        </span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>End Date</span>
        <span className={styles.generalInfoValue}>
          {general.end_time ? moment(general.end_time).format('MM Do YYYY, h:mm a') : 'No end date'}{' '}
        </span>
      </Row> */}
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Cost per Click</span>
        <span className={styles.generalInfoValue}>{pricing && pricing.price ? '$ ' + pricing.price : 'Not provided'} </span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Campaign Budget</span>
        <span className={styles.generalInfoValue}>
          {limit && limit.limit_value ? '$ ' + limit.limit_value : 'Not provided'} per{' '}
          {limit && limit.period ? limit.period : 'Not provided'}{' '}
        </span>
      </Row>
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Conversion Tracking</span>
        <span className={styles.generalInfoValue}>{general.conversion_tracking_type ? general.conversion_tracking_type : 'Default'}</span>
      </Row>{' '}
      <Row className={styles.generalInfoRows}>
        <span className={styles.generalInfoLabel}>Conversion Value</span>
        <span className={styles.generalInfoValue}>
          {general.conversion_tracking_value ? '$ ' + general.conversion_tracking_value : 'Auto'}
        </span>
      </Row>
    </Row>
  );
};
