import React, { useEffect, useCallback, useState, useContext } from 'react';
import { userNotificationsService } from '../services/userNotifications';
import UserContext from './userContext';

const NotificationContext = React.createContext({});
export default NotificationContext;

export const NotificationContextProvider = ({ children }) => {
  const { userInfo } = useContext(UserContext);

  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [userNotificationsCount, setUserNotificationsCount] = useState(0);
  const [unreadCount, setUnreadCount] = useState(0);
  const [broadcastNotifications, setBroadcastNotifications] = useState([]);

  const [userNotificationPage, setUserNotificationPage] = useState(1);
  const [userNotificationPageSize, setUserNotificationPageSize] = useState(5);

  const [showUnread, setShowUnread] = useState(null);

  const getBalanceMessage = (limit, balance) => {
    if (!limit && balance > 10 && balance < 50) {
      return 'Your balance is currently under the $50. Your campaigns will not be active when your balance is under $10, so make sure to top up your funds!';
    } else if (!(limit ? limit - 10 > -balance : balance > 10)) {
      return 'Your campaigns are not active because your balance is under $10, so make sure to top up your funds!';
    } else if (!(limit ? limit - 50 > -balance : balance > 50)) {
      return 'You have got less than $50 until reaching credit limit. Your campaigns will not be active when your balance is under $10, so make sure to top up your funds!';
    }
    return false;
  };

  useEffect(() => {
    const description = getBalanceMessage(parseFloat(userInfo.credit_limit), parseFloat(userInfo.total_balance));
    if (description) {
      setBroadcastNotifications((prevState) => [
        ...prevState,
        {
          icon: '',
          description
        }
      ]);
    }
  }, [userInfo.credit_limit, userInfo.total_balance]);

  const getNotifications = useCallback(
    async () => {
      setLoadingNotifications(true);
      try {
        const res = await userNotificationsService.getUserNotifications(
          userNotificationPage,
          userNotificationPageSize,
          showUnread
        );
        if (res.success) {
          setBroadcastNotifications(res.data.broadcast_notifications);
          setUserNotifications(res.data.notification_bar_notifications);
          setUserNotificationsCount(res.data.notification_bar_notifications_count);
          setUnreadCount(res.data.notification_bar_notifications_unread_count);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingNotifications(false);
      }
    }, [userNotificationPage, userNotificationPageSize, showUnread]
  );

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  return (
    <NotificationContext.Provider
      value={{
        loadingNotifications,
        broadcastNotifications,
        userNotifications,
        setUserNotifications,
        userNotificationsCount,
        unreadCount,
        setUnreadCount,
        userNotificationPage,
        setUserNotificationPage,
        userNotificationPageSize,
        setUserNotificationPageSize,
        showUnread,
        setShowUnread
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
