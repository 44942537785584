import React from 'react';
import { Tooltip } from 'antd';

import styles from './Show.module.css';

export const StatusTableCol = ({ status }) => {
  let statusTitle = '';
  switch (status) {
    case 'daily-limit':
    case 'hourly-limit':
    case 'monthly-limit':
      statusTitle = 'Budget cap';
      break;
    default:
      statusTitle = status;
  }
  return <div>
    <Tooltip placement="topLeft" title={statusTitle == 'disabled' ? 'This campaign is unable to run because you don’t have sufficient funds. As soon as you top up your balance, you’ll be able to run it immediately.' : ''}>
      <div className={`${styles.status} ${styles['status' + status]}`}>
        {statusTitle}
      </div>
    </Tooltip>
  </div >;
};
