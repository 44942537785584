import React, { useContext, useEffect } from 'react';
import { Button, Form, InputNumber, Select, Tooltip } from 'antd';
import { InfoCircleTwoTone, LoadingOutlined } from '@ant-design/icons';
import styles from './BudgetAndBids.module.css';
import CampaignContext from '../../../../contexts/campaignContext';

const { Option } = Select;

export const BudgetAndBids = ({ bidData, campaignType, form, onNext, isEdit, setMaxTabPage, maxTabPage, bidDataLoading }) => {
  const { campaign, dispatchCampaign } = useContext(CampaignContext);

  useEffect(() => {
    if (campaign.general) {
      const initialValues = {
        price: campaign.pricing && campaign.pricing.price ? campaign.pricing.price : null
      };
      form.setFieldsValue(initialValues);
    }
  }, [campaign]);

  useEffect(() => {
    if (!!campaign.limit) {
      form.setFieldsValue({ limit_value: campaign.limit.limit_value, period: campaign.limit.period ? campaign.limit.period : 'day' });
    }
  }, [campaign.limit]);

  const onButtonNextClick = (values) => {
    isEdit
      ? dispatchCampaign({ type: 'limit', payload: { limit_value: values.limit_value, period: values.period, id: campaign.limit.id } })
      : dispatchCampaign({ type: 'limit', payload: { limit_value: values.limit_value, period: values.period } });
    dispatchCampaign({ type: 'pricing', payload: { price: values.price } });
    if (maxTabPage === 4) {
      setMaxTabPage(5);
    }
  };

  const setSuggestedBid = () => {
    bidData.suggested_bid &&
      form.setFieldsValue({
        price: Number(bidData.suggested_bid.toFixed(3))
      });
  };

  return (
    <div className={styles.generalWrapper}>
      <div className={styles.generalContentWrapper}>
        <Form layout="horizontal" form={form} name="GeneralConfig" onFinish={onButtonNextClick}>
          <div className={styles.useSuggestedBid}>
            <Form.Item
              label={
                <span>
                  Cost per Click{' '}
                  <Tooltip
                    title={
                      <p>
                        Here you can set your base CPC - the amount you pay each time a user clicks on your ad. You can adjust your CPC
                        later by source or location on the Reports page. The floor amount depends on your GEO targeting, while the maximum
                        amount is $1000.
                        {/* Here you can set your base CPC - the amount you pay each time a user clicks on your ad. You can adjust your CPC
                        later by source or location on Reports page. The floor amount you can enter is $
                        {campaignType === 'Push' && bidData.floor_bid ? bidData.floor_bid.toFixed(3) : 0.001}, while maximum amount is
                        $1000. */}
                      </p>
                    }
                  >
                    <InfoCircleTwoTone style={{ marginRight: '0.1rem' }} />
                  </Tooltip>
                </span>
              }
              name="price"
              className={styles.campaignBid}
              rules={[
                {
                  required: true,
                  message: 'Please select campaign cost per click'
                }
              ]}
            >
              <InputNumber
                step={0.001}
                max={1000}
                min={campaignType === 'Push' && bidData.floor_bid ? bidData.floor_bid.toFixed(3) : 0.001}
                placeholder="Not set"
              />
            </Form.Item>
            {campaignType === 'Push' ? (
              <Button type="primary" ghost onClick={setSuggestedBid} disabled={bidDataLoading}>
                Use suggested bid: {bidDataLoading ? <LoadingOutlined /> : bidData.suggested_bid && bidData.suggested_bid.toFixed(3)}
              </Button>
            ) : (
              ''
            )}
          </div>
          <div className={styles.budgetAndPeriod}>
            <Form.Item
              label="Campaign Budget"
              name="limit_value"
              className={styles.campaignBudget}
              rules={[
                {
                  required: true,
                  message: 'Please select campaign budget'
                }
              ]}
            >
              <InputNumber style={{ width: '100%' }} step={1} min={1} placeholder="Not set" />
            </Form.Item>
            <Form.Item
              label="Period"
              name="period"
              className={styles.campaignBudgetPeriod}
              rules={[
                {
                  required: true,
                  message: 'Please select period'
                }
              ]}
            >
              <Select>
                <Option value="hour">Hour</Option>
                <Option value="day">Day</Option>
                <Option value="month">Month</Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
      <Button
        type="primary"
        className={styles.proceedButton}
        onClick={async () => {
          try {
            await form.validateFields();
            form.submit();
            onNext();
          } catch (e) {
            console.log(e);
          }
        }}
        style={{ float: 'right', marginTop: '20px' }}
      >
        Proceed to Links
      </Button>
    </div>
  );
};
