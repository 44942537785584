import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Col, Row, Spin, Button, Tooltip, Form } from 'antd';
import { FlagOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { GeneralConfigTab } from './steps/GeneralConfiguration/GeneralConfig';
import { TargetingTab } from './steps/Targeting';
import { TrafficSourcesTab } from './steps/TrafficSources';
import { CreativesTab } from './steps/NewCreatives';
import { CampaignReview } from './steps/CampaignReview';
import styles from './NewCampaign.module.css';
import TargetingContext from '../../contexts/targetingContext';
import { Prompt, useHistory } from 'react-router-dom';
import CampaignContext from '../../contexts/campaignContext';
import { ModalStep } from './steps/ModalStep';
import { useResize } from '../../utils/hooks/useResize';
import { BudgetAndBids } from './steps/BudgetAndBids';
import { Links } from './steps/Links';
import { newCampaignService } from '../../services/newCampaign';
import { handleSaveCampaign } from './utils';
import { openErrorNotification, openSuccessNotification } from '../../utils/notifications';

const { TabPane } = Tabs;

const tabNames = {
  1: 'General Configuration',
  2: 'Targeting',
  3: 'Traffic Sources',
  4: 'Budget & Bids',
  5: 'Links',
  6: 'Manage Creatives',
  7: 'Review Campaign'
};

export const NewCampaign = (props) => {
  const { editLoading, setEditId, campaign, dispatchTargeting, dispatchCampaign, dispatchCreatives, targeting, creatives } =
    useContext(CampaignContext);
  const { loading, setLoading, browserAll, osAll, connectionAll, manufacturerAll, typeDeviceAll } = useContext(TargetingContext);
  const [activeTab, setActiveTab] = useState('1');
  const [maxTabPage, setMaxTabPage] = useState(1);
  const history = useHistory();
  const [isCreativeValid, setIsCreativeValid] = useState(true);
  const [preventLeave, setPreventLeave] = useState(true);
  const width = useResize();

  const [bidData, setBidData] = useState({});
  const [bidDataLoading, setBidDataLoading] = useState(false);

  const [campaignType, setCampaignType] = useState('Smartlink');

  const [showCampaignOverview, setShowCampaignOverview] = useState(false);

  const isEdit = !!Number(props.match.params.id);

  const [campaignTypeSelected, setCampaignTypeSelected] = useState(isEdit);

  const [form] = Form.useForm();
  const [bidsForm] = Form.useForm();
  const [linksForm] = Form.useForm();

  const [isTrackingManual, setIsTrackingManual] = useState(false);

  useEffect(() => {
    if (Number(props.match.params.id)) {
      setMaxTabPage(7);
      setEditId(props.match.params.id);
      setShowCampaignOverview(true);
    }
    return () => {
      dispatchTargeting({ type: 'reset' });
      dispatchCampaign({ type: 'reset' });
      dispatchCreatives({ type: 'reset' });
      setEditId(null);
    };
  }, []);

  useEffect(() => {
    if (campaign.general.type) {
      setCampaignType(campaign.general.type);
    }
  }, [campaign]);

  const getCurrentTabHeaderValue = () => {
    return activeTab + '. ' + tabNames[activeTab];
  };

  const goToNextTab = () => {
    setActiveTab((Number(activeTab) + 1).toString());
  };

  const goToPreviousTab = () => {
    setActiveTab((Number(activeTab) - 1).toString());
  };

  const confirmLeaveCampaign = () => {
    setPreventLeave(false);
    history.push('/campaigns');
  };

  const handleCampaignSelect = (type) => {
    setCampaignType(type);
    setCampaignTypeSelected(true);
  };

  const handleCampaignSave = async () => {
    try {
      await form.validateFields();
      const valForm = form.getFieldsValue();
      const valLinks = linksForm.getFieldsValue();
      const cmpGeneral = {
        ...campaign.general,
        ...valForm,
        ...valLinks,
        conversion_tracking_value: valLinks.conversion_tracking_value && isTrackingManual ? valLinks.conversion_tracking_value : false
      };
      await bidsForm.validateFields();
      const valBids = bidsForm.getFieldsValue();
      const cmpLimit = {
        ...campaign.limit,
        limit_value: valBids.limit_value || campaign.limit.limit_value,
        period: valBids.period || campaign.limit.period,
        id: campaign.limit.id
      };
      const cmpPricing = { ...campaign.pricing, price: valBids.price || campaign.pricing.price };

      const campaignSaveObj = {
        general: cmpGeneral,
        limit: cmpLimit,
        pricing: cmpPricing
      };

      addOrEditCamp({ campaignSaveObj });
    } catch (e) {
      console.log(e);
    }
  };

  const addOrEditCamp = async ({ campaignSaveObj }) => {
    setLoading(true);
    const parsedCmp = campaignSaveObj ? campaignSaveObj : campaign;
    try {
      const data = await handleSaveCampaign(
        campaignType,
        parsedCmp,
        targeting,
        osAll,
        browserAll,
        connectionAll,
        typeDeviceAll,
        manufacturerAll,
        isEdit,
        creatives
      );

      const res = isEdit
        ? await newCampaignService.editCampaign(data, Number(props.match.params.id))
        : await newCampaignService.createCampaign(data);
      setPreventLeave(false);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        dispatchCampaign({ type: 'reset' });
        dispatchTargeting({ type: 'reset' });
        dispatchCreatives({ type: 'reset' });
        setEditId(null);
        history.push('/campaigns');
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
      openErrorNotification({ message: 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    activeTab === '4' &&
      (async () => {
        setBidDataLoading(true);
        const targetingRefactored = { ...targeting };
        if (targeting.freshness_type === 'hour') {
          targetingRefactored.age = null;
        } else {
          targetingRefactored.subscribed_age = null;
        }
        try {
          const res = await newCampaignService.getSuggestedBid({ targeting: targetingRefactored });
          setBidData(res.data);
        } catch (e) {
          console.log(e);
        } finally {
          setBidDataLoading(false);
        }
      })();
  }, [activeTab]);

  return (
    <>
      <ModalStep handleCampaignSelect={handleCampaignSelect} campaignTypeSelected={campaignTypeSelected} />
      <Spin spinning={editLoading || loading}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Row style={{ marginBottom: '30px' }}>
            <Col className={styles.topCampaignTitle}>
              <img src="../../images/bull-horn-announcer.svg" alt="rocket-campaign" />
              <p>{Number(props.match.params.id) ? 'Edit Campaign' : 'New Campaign'}</p>
            </Col>
            <Col className={styles.headerStepRight}>
              {/* <span className={styles.newStepSpan}>{Number(props.match.params.id) ? 'Edit' : 'New'}</span> */}
              <span className={styles.headerCurrentTabText}>{getCurrentTabHeaderValue()}</span>
            </Col>
          </Row>
          <div>
            <Button className={styles.closeBtnTop} onClick={confirmLeaveCampaign}>
              {Number(props.match.params.id) ? 'Leave Editing Mode' : 'Leave Creating Mode'}
            </Button>
            {!!Number(props.match.params.id) && (
              <Button className={styles.saveCampaignBtn} onClick={handleCampaignSave}>
                Save Campaign
              </Button>
            )}
          </div>
        </div>
        <Row className={styles.newCampaignTabsRow}>
          <Tabs
            activeKey={activeTab}
            tabPosition={width > 1100 ? 'left' : 'top'}
            animated={false}
            onChange={async (key) => {
              try {
                if (activeTab === '1') {
                  await form.validateFields();
                  form.submit();
                  setActiveTab(key);
                } else if (activeTab === '4') {
                  await bidsForm.validateFields();
                  bidsForm.submit();
                  setActiveTab(key);
                } else if (activeTab === '5') {
                  await linksForm.validateFields();
                  linksForm.submit();
                  setActiveTab(key);
                } else {
                  setActiveTab(key);
                }
              } catch (e) {
                console.log(e);
              }
            }}
          >
            <TabPane
              tab={
                <div className={styles.tabNameDiv}>
                  {maxTabPage < 2 ? (
                    <span className={styles.circleNumber}>1</span>
                  ) : (
                    <span className={styles.checkNotification}>
                      <CheckCircleFilled style={{ fontSize: '20px' }} />
                    </span>
                  )}
                  <span className={styles.tabName}> {tabNames['1']}</span>
                </div>
              }
              key="1"
              className={styles.customTab}
            >
              <GeneralConfigTab
                onNext={goToNextTab}
                isEdit={isEdit}
                loading={editLoading}
                setMaxTabPage={setMaxTabPage}
                maxTabPage={maxTabPage}
                form={form}
              />
            </TabPane>

            <TabPane
              tab={
                <div className={styles.tabNameDiv}>
                  {maxTabPage < 3 ? (
                    <span className={styles.circleNumber}>2</span>
                  ) : (
                    <span className={styles.checkNotification}>
                      <CheckCircleFilled style={{ fontSize: '20px' }} />
                    </span>
                  )}
                  <span className={styles.tabName}> {tabNames['2']}</span>
                </div>
              }
              key="2"
              disabled={maxTabPage < 2}
            // onClick={() => console.log('Start new campaign')}
            >
              <TargetingTab type={campaignType} onNext={goToNextTab} setMaxTabPage={setMaxTabPage} maxTabPage={maxTabPage} />
            </TabPane>

            <TabPane
              tab={
                <div className={styles.tabNameDiv}>
                  {maxTabPage < 4 ? (
                    <span className={styles.circleNumber}>3</span>
                  ) : (
                    <span className={styles.checkNotification}>
                      <CheckCircleFilled style={{ fontSize: '20px' }} />
                    </span>
                  )}
                  <span className={styles.tabName}> {tabNames['3']}</span>
                </div>
              }
              key="3"
              disabled={maxTabPage < 3}
            >
              <TrafficSourcesTab
                onNext={goToNextTab}
                setMainActiveTab={setActiveTab}
                setMaxTabPage={setMaxTabPage}
                maxTabPage={maxTabPage}
                type={campaignType}
              />
            </TabPane>

            <TabPane
              tab={
                <div className={styles.tabNameDiv}>
                  {maxTabPage < 5 ? (
                    <span className={styles.circleNumber}>4</span>
                  ) : (
                    <span className={styles.checkNotification}>
                      <CheckCircleFilled style={{ fontSize: '20px' }} />
                    </span>
                  )}
                  <span className={styles.tabName}> {tabNames['4']}</span>
                </div>
              }
              key="4"
              disabled={maxTabPage < 4}
            >
              <BudgetAndBids
                bidData={bidData}
                campaignType={campaignType}
                form={bidsForm}
                onNext={goToNextTab}
                isEdit={isEdit}
                setMaxTabPage={setMaxTabPage}
                maxTabPage={maxTabPage}
                setBidDataLoading={setBidDataLoading}
                bidDataLoading={bidDataLoading}
              />
            </TabPane>

            <TabPane
              tab={
                <div className={styles.tabNameDiv}>
                  {maxTabPage < 6 ? (
                    <span className={styles.circleNumber}>5</span>
                  ) : (
                    <span className={styles.checkNotification}>
                      <CheckCircleFilled style={{ fontSize: '20px' }} />
                    </span>
                  )}
                  <span className={styles.tabName}> {tabNames['5']}</span>
                </div>
              }
              key="5"
              disabled={maxTabPage < 5}
            >
              <Links
                form={linksForm}
                onNext={goToNextTab}
                setMaxTabPage={setMaxTabPage}
                maxTabPage={maxTabPage}
                isManual={isTrackingManual}
                setIsManual={setIsTrackingManual}
              />
            </TabPane>

            <TabPane
              tab={
                <div className={styles.tabNameDivCreatives}>
                  {!isCreativeValid && (
                    <Tooltip title="No creatives!">
                      <span className={styles.warningNotification}>
                        <ExclamationCircleFilled style={{ fontSize: '18px' }} />
                      </span>
                    </Tooltip>
                  )}
                  <span className={styles.tabNameCreatives}> {tabNames['6']}</span>
                </div>
              }
              key="6"
              disabled={maxTabPage < 6}
            >
              <CreativesTab
                setIsCreativeValid={setIsCreativeValid}
                onNext={goToNextTab}
                type={campaignType}
                isCampaignEdit={isEdit}
                setMaxTabPage={setMaxTabPage}
                maxTabPage={maxTabPage}
                setShowCampaignOverview={setShowCampaignOverview}
              />
            </TabPane>

            {showCampaignOverview ? (
              <TabPane
                tab={
                  <div className={styles.tabNameDiv} style={{ marginLeft: '1.2rem' }}>
                    <FlagOutlined style={{ marginRight: 0 }} />
                    <span className={styles.tabName}> {tabNames['7']}</span>
                  </div>
                }
                key="7"
              >
                <CampaignReview
                  setPreventLeave={setPreventLeave}
                  onNext={goToNextTab}
                  type={campaignType}
                  confirmLeaveCampaign={confirmLeaveCampaign}
                  onPrevious={goToPreviousTab}
                  isCreativeValid={isCreativeValid}
                  addOrEditCamp={addOrEditCamp}
                />
              </TabPane>
            ) : (
              ''
            )}
          </Tabs>
        </Row>
      </Spin>
      <Prompt when={preventLeave} message="You have unsaved changes, are you sure you want to leave?" />
    </>
  );
};
