import React, { useState } from 'react';
import { Button, Row, Tabs } from 'antd';

import styles from './Targeting.module.css';

import { GeosTab } from './tabs/geos';
import { OSTab } from './tabs/os';
import { BrowsersTab } from './tabs/browsers';
import { IPTab } from './tabs/ips';
// import { ConnectionsTab } from './tabs/connections';
import { DevicesTab } from './tabs/devices';
import { DaysTab } from './tabs/days';
import { AgesTab } from './tabs/ages';

const { TabPane } = Tabs;

export const TargetingTab = ({ onNext, setMaxTabPage, maxTabPage, type }) => {
  const [activeTab, setActiveTab] = useState('geos');

  const proceedClick = () => {
    if (maxTabPage === 2) {
      setMaxTabPage(3);
    }
    onNext();
  };

  return (
    <div className={styles.targetingContainer}>
      <Tabs activeKey={activeTab} onChange={(item) => setActiveTab(item)} defaultActiveKey="geos" animated={false}>
        <TabPane tab="Geos" key="geos">
          <GeosTab changeActiveTab={setActiveTab} />
        </TabPane>

        <TabPane tab="Operating Systems" key="oss">
          <OSTab changeActiveTab={setActiveTab} />
        </TabPane>

        <TabPane tab="Browsers" key="browsers">
          <BrowsersTab type={type} changeActiveTab={setActiveTab} />
        </TabPane>
        {type === 'Push' && (
          <TabPane tab="User Freshness" key="ages">
            <AgesTab changeActiveTab={setActiveTab} />
          </TabPane>
        )}

        <TabPane tab="Day & Time" key="days">
          <DaysTab changeActiveTab={setActiveTab} />
        </TabPane>

        <TabPane tab="IP & ISPs" key="ips">
          <IPTab changeActiveTab={setActiveTab} />
        </TabPane>

        {/* <TabPane tab="Connections" key="connections">
          <ConnectionsTab changeActiveTab={setActiveTab} />
        </TabPane> */}

        <TabPane tab="Device Manufacturers" key="deviceManufacturers">
          <DevicesTab changeActiveTab={setActiveTab} />
        </TabPane>
      </Tabs>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button type="primary" className={styles.proceedButton} onClick={proceedClick}>
          Proceed to Traffic Sources
        </Button>
      </Row>
    </div>
  );
};
