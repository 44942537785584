import { Checkbox } from 'antd';
import React, { useMemo } from 'react';
import { TimeRow } from './TimeRow';
import { dayHours, weekDays } from './utils';
import styles from './TimesTable.module.css';
import classnames from 'classnames';

export const TimesTable = ({ handleAddHour, handleRemoveHour, handleAddDay, handleRemoveDay, targeting }) => {
  const bothSelected = useMemo(() => {
    return !!targeting.hour.item.length && !!targeting.day.item.length;
  }, [targeting.hour, targeting.day]);
  const classesArray = dayHours.map((item) => [styles[`times-column-${item}`], targeting.hour.item.includes(item)]);
  const classes = classnames(styles[`timeTable-${bothSelected}`], Object.fromEntries(classesArray));

  return (
    <div className={styles.timesTableWrapper}>
      <table className={classes}>
        <thead>
          <tr>
            <th colSpan="3"></th>
            <th></th>
            {dayHours.map((item) => (
              <td key={item} align="center">
                {('0' + item).slice(-2)}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="3"></td>
            <td></td>
            {dayHours.map((item) => (
              <td className={styles.hoursCheckbox} key={item} align="center">
                <Checkbox
                  checked={targeting.hour.item.includes(item)}
                  onChange={(e) => (e.target.checked ? handleAddHour(item) : handleRemoveHour(item))}
                />
              </td>
            ))}
          </tr>
          {weekDays.map((day) => (
            <TimeRow key={day} title={day} handleAddDay={handleAddDay} handleRemoveDay={handleRemoveDay} selected={targeting.day.item} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
