import React from 'react';
import Lottie from 'react-lottie';
import coinisLoader from './coinis_loading.json';

export const CoinisLoader = () => {
  return (
    <Lottie
      id="offerAnimation"
      options={{
        loop: true,
        autoplay: true,
        animationData: coinisLoader
      }}
      height={400}
      // width={350}
    />
  );
};
