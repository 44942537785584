import React, { useEffect, useState } from 'react';
import styles from './CreativeBuilder.module.css';
import { NewImageModal } from './components/NewImageModal';

import { GeneratorForm } from './components/GeneratorForm';
import { SingleCreativeForm } from './components/GeneratorSingleForm';

export const CreativeBuilder = ({
  type,
  setGeneratorVisible,
  generateCreatives,
  handleUpdateCreatives,
  currentCreative,
  editCreative,
  genCreatives,
  headlines,
  setHeadlines,
  images,
  setImages,
  icon,
  setIcon,
  landingUrl,
  setLandingUrl,
  description,
  setDescription,
  formBulk,
  generatorVisible,
  handleClearAll
}) => {
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [singleImage, setSingleImage] = useState([]);
  const [singleIcon, setSingleIcon] = useState([]);

  // creatives update (regenerate all creatives)
  useEffect(() => {
    if (headlines.length && images.length) {
      generateCreatives(headlines, images, landingUrl, description, icon);
    }
  }, [headlines, images]);

  // optimized update (won't call generateCreatives function)
  useEffect(() => {
    handleUpdateCreatives({ landingUrl, description, icon: icon[0] });
  }, [landingUrl, description, icon]);

  return (
    <div className={styles.creativeBuilder}>
      <div className={styles.headerTitle}>
        <p>Creative Details</p>
      </div>
      {currentCreative === false ? (
        <GeneratorForm
          type={type}
          images={images}
          setImages={setImages}
          icon={icon}
          setIcon={setIcon}
          headlines={headlines}
          setHeadlines={setHeadlines}
          landingUrl={landingUrl}
          setLandingUrl={setLandingUrl}
          setImageModalVisible={setImageModalVisible}
          description={description}
          setDescription={setDescription}
          formBulk={formBulk}
        />
      ) : currentCreative ? (
        <SingleCreativeForm
          type={type}
          setGeneratorVisible={setGeneratorVisible}
          genCreatives={genCreatives}
          currentCreative={currentCreative}
          editCreative={editCreative}
          singleImage={singleImage}
          setSingleImage={setSingleImage}
          singleIcon={singleIcon}
          setSingleIcon={setSingleIcon}
          headlines={headlines}
          setHeadlines={setHeadlines}
          setLandingUrl={setLandingUrl}
          setImageModalVisible={setImageModalVisible}
          generatorVisible={generatorVisible}
          handleClearAll={handleClearAll}
        />
      ) : null}
      <NewImageModal
        type={type}
        imageModalVisible={imageModalVisible}
        setImageModalVisible={setImageModalVisible}
        setImages={setImages}
        setIcon={setIcon}
        setSingleImage={setSingleImage}
        setSingleIcon={setSingleIcon}
        images={images}
        singleImage={singleImage}
        singleIcon={singleIcon}
      />
    </div>
  );
};
