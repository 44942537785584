import React from 'react';
import { Button, Col, Popover, Row, Tag } from 'antd';
import styles from './Targeting.module.css';
import { CloseOutlined } from '@ant-design/icons';

export const ListRow = ({ item, removeList, targetingLists }) => {
  let listName = targetingLists.filter((list) => list.id === item);
  let countries = [];
  if (listName.length) {
    countries = listName[0].items.split(',');
    listName = (
      <span>
        {listName[0].name} <Tag color="blue">List</Tag>
      </span>
    );
  }

  return (
    <Row key={item} className={styles.listRow}>
      <Col style={{ width: '90%' }}>
        <Popover
          title="Countries"
          content={
            <div className={styles.listCountries}>
              {countries.map((item) => (
                <span key={item}>{item}</span>
              ))}
            </div>
          }
        >
          <span style={{ cursor: 'pointer' }}>{listName}</span>
        </Popover>
      </Col>
      <Col style={{ width: '10%' }}>
        <Button type="primary" ghost size="small" shape="circle" className={styles.countryRowCancel} onClick={(e) => removeList(item)}>
          <CloseOutlined />
        </Button>
      </Col>
    </Row>
  );
};
