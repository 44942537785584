import React, { useState } from 'react';

import { Row, Col, Switch, Button, Space } from 'antd';
import { Tracker } from './Tracker';
import { OtherTrackerOrCpaNetwork } from './OtherTrackerOrCpaNetwork';
import './Trackers.css';

export const Trackers = () => {
  const [selectedTracker, updateSelectedTracker] = useState('general');

  const trackers = [
    {
      id: 2,
      imgPath: 'images/voluum.svg',
      selected: true,
      trackerName: 'voluum'
    },
    {
      id: 1,
      imgPath: 'images/binom.svg',
      selected: false,
      trackerName: 'binom'
    },

    {
      id: 3,
      imgPath: 'images/affise.png',
      selected: false,
      trackerName: 'affise'
    },
    {
      id: 4,
      imgPath: '',
      name: 'Other Tracker or CPA Network',
      selected: true,
      trackerName: 'general'
    }
  ];
  return (
    <>
      <h2 className="tracking-main-title">Conversion Tracking</h2>
      <div>
        <div className="formSmallWritings">
          Set up S2S tracking to see conversions in your statistics. The S2S Postback URL that you will generate here can be used in all
          your campaigns.
        </div>
        <p className="formSmallWritings">Select a Tracker or a CPA network</p>
        <Row gutter={16} justify="start">
          {trackers.map((tracker, index) => {
            return (
              <Space key={index}>
                <Col className="gutter-row" span={3}>
                  <Tracker
                    key={tracker.id}
                    className="trackerImg"
                    updateSelectedTracker={updateSelectedTracker}
                    trackerName={tracker.trackerName}
                    name={tracker.name}
                    url={tracker.url}
                    selectedTracker={selectedTracker}
                    imgPath={tracker.imgPath}
                  />
                </Col>
              </Space>
            );
          })}
        </Row>
        <br />
        <br />
        <OtherTrackerOrCpaNetwork selectedTracker={selectedTracker} />
      </div>
    </>
  );
};
