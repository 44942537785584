import React, { useEffect, useState, useContext } from 'react';
import { Table as AntTable, Pagination, Button } from 'antd';
import { PlusOutlined, ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { ActionsTableCol } from './ActionsTableCol';
import { StatusTableCol } from './StatusTableCol';
import { BudgetCol } from './BudgetCol';
import { CampaignNameCol } from './CampaignNameCol';
import { CampaignTypeCol } from './CampaignTypeCol';
import { DateCreatedCol } from './DateCreatedCol';

import styles from './Show.module.css';
import { Link } from 'react-router-dom';
import { mediaCampaignsService } from '../../../services/campaigns';
import { CampaignFilters } from './CampaignsFilters';
import { ColumnsGroup } from './ColumnsGroup';
import UserContext from '../../../contexts/userContext';
import { openErrorNotification, openSuccessNotification } from '../../../utils/notifications';
import { CpcCol } from './CpcCol';
import { numberWithSpaces } from '../../../utils/abbreviateNumber';

export const Table = ({
  dateRange,
  setDateRange,
  submit,
  campaigns,
  exportCampaings,
  sumOfData,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  onActivate,
  onPause,
  onDelete,
  checkedColumns,
  setCheckedColumns,
  selectedFilters,
  onActivateAllCampaigns,
  setBudgetModalVisible,
  setCampaignId,
  setBudgetInfo
}) => {
  const { userInfo } = useContext(UserContext);
  const [disabledExists, setDisabledExists] = useState(false);
  const [enabling, setEnabling] = useState(false);

  const [actionPending, toggleActionPending] = useState(false);
  const [campaignsList, setCampaignsList] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const filteredColumns = allColumns.filter((item) => {
      return Object.keys(checkedColumns).some((key) => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setSelectedColumns([...staticColumns, ...filteredColumns, actionTableColumn]);
  }, [selectedFilters]);

  const staticColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      className: styles.statusColumn,
      render: (status) => {
        return <StatusTableCol status={status} />;
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
      render: (id) => <div className={styles.campaignId}>{id}</div>,
      onCell: ({ id }) => {
        return {
          onClick: () => {
            window.open(`/campaigns/${id}`, '_blank', 'noopener,noreferrer');
          }
        };
      }
    }
  ];

  const actionTableColumn = {
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    align: 'center',
    render: ({ id, name, status }) => {
      return (
        <ActionsTableCol
          id={id}
          title={name}
          status={status}
          onActivate={onActivate}
          onPause={onPause}
          onDelete={onDelete}
          toggleActionPending={toggleActionPending}
        />
      );
    }
  };

  const allColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      render: (channel) => {
        return <CampaignNameCol channel={channel} />;
      },
      onCell: ({ id }) => {
        return {
          onClick: () => {
            window.open(`/campaigns/${id}`, '_blank', 'noopener,noreferrer');
          }
        };
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      className: styles.campaignTypeColumn,
      render: (campaignType) => {
        return <CampaignTypeCol campaignType={campaignType} />;
      }
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (date_created) => {
        return <DateCreatedCol date={date_created && moment(date_created).format('DD MMM YYYY hh:mm:ss')} />;
      }
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      render: (budget, record) => {
        return (
          <BudgetCol
            budget={budget}
            setBudgetInfo={setBudgetInfo}
            id={record.id}
            setCampaignId={setCampaignId}
            setBudgetModalVisible={setBudgetModalVisible}
          />
        );
      }
    },
    {
      title: 'CPC',
      dataIndex: 'base_price',
      align: 'center',
      render: (cpc, record) => {
        return (
          <CpcCol
            cpc={cpc}
            campaignId={record.id}
          />
        );
      }
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions'
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks'
    },
    {
      title: 'Conversions',
      dataIndex: 'leads'
    },
    // {
    //   title: 'CTR',
    //   dataIndex: 'ctr'
    // },
    {
      title: 'CTR',
      dataIndex: 'ctr'
    },
    {
      title: 'Spent',
      dataIndex: 'spent',
      render: (spent) => (spent ? '$ ' + parseFloat(spent).toFixed(4) : '')
    }
    // {
    //   title: 'Cost',
    //   dataIndex: 'cose'
    // }
  ];
  const [selectedColumns, setSelectedColumns] = useState([...staticColumns, ...allColumns, actionTableColumn]);

  useEffect(() => {
    const fetchCampaignsList = async () => {
      try {
        const { data } = await mediaCampaignsService.getAllCampaignsAutocomplete();
        if (data) {
          setCampaignsList(data);
        }
      } catch (e) {
        console.log(e);
      } finally {
      }
    };
    fetchCampaignsList();
  }, []);

  useEffect(() => {
    const status = selectedFilters?.filters?.status;
    if (status) {
      setFilters({ ...filters, status });
    }
  }, [selectedFilters]);

  useEffect(() => {
    const disabledCampaigns = async () => {
      try {
        const res = await mediaCampaignsService.disabledCampaigns();
        setDisabledExists(res.success);
      } catch (e) {
        console.log(e);
      } finally {
      }
    };
    disabledCampaigns();
  }, []);

  const balanceInvalid = () => {
    const limit = parseFloat(userInfo.credit_limit);
    const balance = parseFloat(userInfo.total_balance);
    if (!(limit ? limit - 10 > -balance : balance > 10)) {
      return 'Please top up your credit in order to start campaigns.';
    }
    return false;
  };

  const activateAllDisabled = async () => {
    setEnabling(true);
    try {
      const res = await mediaCampaignsService.activateAllDisabled();
      if (res.success) {
        setDisabledExists(false);
        openSuccessNotification({ message: res.message });
        onActivateAllCampaigns();
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setEnabling(false);
    }
  };

  const sumOfDataRefactor = () => {
    if (sumOfData) {
      let sumOfDataRefactored = { ...sumOfData };
      if (sumOfData.clicks) {
        sumOfDataRefactored.clicks = numberWithSpaces(sumOfData.clicks);
      }
      if (sumOfData.ctr) {
        sumOfDataRefactored.ctr = parseFloat(sumOfData.ctr).toFixed(2) + ' %';
      }
      if (sumOfData.impressions) {
        sumOfDataRefactored.impressions = numberWithSpaces(sumOfData.impressions);
      }
      if (sumOfData.leads) {
        sumOfDataRefactored.leads = numberWithSpaces(sumOfData.leads);
      }
      if (sumOfData.spent) {
        sumOfDataRefactored.spent = '$ ' + parseFloat(sumOfData.spent).toFixed(4);
      }
      return sumOfDataRefactored;
    }
  };

  return (
    <div className={styles.campaignsTable}>
      <div className={styles.titleContent}>
        <h3>Campaigns</h3>
        <Link to="/campaigns/new-campaign">
          <Button type="primary" className={styles.newCampaignBtn} icon={<PlusOutlined className={styles.plusIconCamp} />}>
            Start New Campaign
          </Button>
        </Link>
      </div>
      <CampaignFilters
        setDateRange={setDateRange}
        submit={submit}
        dateRange={dateRange}
        campaignsList={campaignsList}
        filters={filters}
        setFilters={setFilters}
      />
      <div className={styles.extActionGroup}>
        <div>
          {!disabledExists || balanceInvalid() ? (
            ''
          ) : (
            <Button loading={enabling} type="primary" onClick={() => activateAllDisabled()}>
              Activate all your disabled campaigns
            </Button>
          )}
        </div>
        <div className={styles.extActionRightGroup}>
          <Button onClick={() => exportCampaings(filters, dateRange)}>
            <ExportOutlined /> Export to CSV
          </Button>
          {/* <Button className={styles.columnsBtn}>
            <MenuOutlined rotate={90} /> Columns
          </Button> */}
          <ColumnsGroup
            className={styles.columnsBtn}
            onChange={(value) => {
              setCheckedColumns(value);
            }}
            showUpdateButton={true}
            onUpdate={() => {
              submit(filters, dateRange);
            }}
            checkedColumns={checkedColumns}
          />
          <Button
            onClick={() => {
              submit(filters, dateRange);
            }}
          >
            Reload <ReloadOutlined />
          </Button>
        </div>
      </div>

      <AntTable
        className={styles.antTableContent}
        columns={selectedColumns}
        dataSource={campaigns}
        loading={loadingCampaigns || actionPending}
        pagination={false}
        rowKey={({ id }) => id}
        footer={() => {
          return (
            <Pagination
              className="ant-table-pagination ant-table-pagination-right"
              total={totalRows}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger
              pageSizeOptions={['10', '20', '30', '50', '100']}
              onChange={onPaginationChange}
              onShowSizeChange={onPaginationChange}
            />
          );
        }}
        summary={() => {
          const sumOfDataRefactored = sumOfDataRefactor();
          return sumOfData ? (
            <tr className="ant-table-row ant-table-row-level-0">
              {selectedColumns.map((el, index) => (
                <td className="ant-table-cell" key={index} index={index}>
                  {sumOfDataRefactored[el.dataIndex]}
                </td>
              ))}
            </tr>
          ) : null;
        }}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      />
    </div>
  );
};
