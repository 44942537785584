import React, { useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { getAllowedRoutes } from '../../routes';
import { Logo } from '../../components/Logo';
import { ModuleName } from './ModuleName';

const generateSidebarMenu = (routes, parentPath = '') => {
  return routes.map((route) => {
    if (route.skipRender) {
      return null;
    }
    const path = route.linkPath || route.path;

    if (route.children) {
      return (
        <Menu.SubMenu
          key={path}
          title={
            <span className="submenuTitleRestyle">
              {route.icon}
              <span>{route.title}</span>
            </span>
          }
        >
          {generateSidebarMenu(route.children, path)}
        </Menu.SubMenu>
      );
    }

    const fullPath = parentPath + path;
    return (
      <Menu.Item key={fullPath}>
        <div className="menuItemRestyle">
          <Link to={fullPath}>
            {!parentPath && route.icon}
            <span>{route.title}</span>
          </Link>
        </div>
      </Menu.Item>
    );
  });
};

export const Sidebar = ({ collapsed, setCollapsed, isSmallScreen, selectedKey }) => {

  const onCollapsed = (collapsed) => {
    setCollapsed(collapsed);
    localStorage.setItem('collapse', collapsed);
  };

  useEffect(() => {
    isSmallScreen ? onCollapsed(true) : onCollapsed(false);
  }, [isSmallScreen]);

  return (
    <Layout.Sider
      style={{
        zIndex: 200,
        minHeight: '100vh'
      }}
      collapsedWidth={isSmallScreen ? 0 : 80}
      className="sidebarcontent"
      // collapsible
      collapsed={collapsed}
    // onCollapse={onCollapse}
    >
      <div>
        <Logo collapsed={collapsed} />

        <Menu inlineIndent={13} theme="dark" mode="inline" selectedKeys={[selectedKey]}>
          {generateSidebarMenu(getAllowedRoutes())}
        </Menu>
      </div>
      {!isSmallScreen && <ModuleName collapsed={collapsed} onCollapsed={onCollapsed} />}
    </Layout.Sider>
  );
};
