import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, InputNumber, Select, Button } from 'antd';
import CampaignContext from '../../../../contexts/campaignContext';

import { newCampaignService } from '../../../../services/newCampaign';

import styles from "./BudgetModal.module.css";
import { openErrorNotification, openSuccessNotification } from "../../../../utils/notifications";

const { Option } = Select;

export const BudgetModal = ({
    budgetModalVisible,
    setBudgetModalVisible,
    campaignId,
    budgetInfo,
    onUpdate
}) => {
    const [loading, setLoading] = useState(false);
    const { campaign } = useContext(CampaignContext);

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            limit_value: Number(budgetInfo.split(" ")[0].substring(1)),
            period: budgetInfo.split(" ")[1] === "Daily" ? "day" : budgetInfo.split(" ")[1] === "Hourly" ? "hour" : "month"
        });
    }, [campaignId]);

    const handleBudgetEdit = async (values) => {
        setLoading(true);
        const data = {
            limits: {
                ...campaign.limit,
                limit_value: values.limit_value,
                period: values.period,
            },
        };
        try {
            const res = await newCampaignService.updateCampaign(data, campaignId);
            if (res.success) {
                openSuccessNotification({ message: res.message });
                setBudgetModalVisible(false);
                onUpdate();
            } else {
                openErrorNotification({ message: res.message });
            }
        } catch (e) {
            console.log(e);
            openErrorNotification({ message: 'Something went wrong!' });
        } finally {
            setLoading(false);
        }
    };

    const closeBudgetModal = () => {
        setBudgetModalVisible(false)
    }

    return (
        <Modal
            title="Edit campaign budget"
            visible={budgetModalVisible}
            onCancel={closeBudgetModal}
            footer={false}
            centered={true}
            width={350}
            wrapClassName={styles.budgetModalWrapper}
        >
            <Form
                layout="vertical"
                onFinish={handleBudgetEdit}
                form={form}
            >
                <div className={styles.inputDollar}>
                    <Form.Item
                        label="Insert campaign budget:"
                        name="limit_value"
                        rules={[
                            {
                                required: true,
                                message: 'Please select campaign budget'
                            }
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            step={1} min={1} placeholder="Insert value here"
                        />
                    </Form.Item>
                    <div className={styles.dollarBox}>$</div>
                </div>
                <Form.Item
                    label="Period"
                    name="period"
                    rules={[
                        {
                            required: true,
                            message: 'Please select period'
                        }
                    ]}
                >
                    <Select
                        placeholder="Select budget limit"
                    >
                        <Option value="hour">Hour</Option>
                        <Option value="day">Day</Option>
                        <Option value="month">Month</Option>
                    </Select>
                </Form.Item>
                <Form.Item className={styles.actionButtons}>
                    <Button onClick={closeBudgetModal}>
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Save budget
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}