import React from 'react';
import {
  HomeOutlined,
  BarChartOutlined,
  CreditCardOutlined,
  ReadOutlined,
  UserOutlined,
  SwapOutlined,
  // BarsOutlined,
  NotificationOutlined
} from '@ant-design/icons';

// import { auth } from '../services/auth';
import { MediaCampaigns } from '../pages/campaigns';
import { NewCampaign } from '../pages/new-campaign';
import { Profile } from '../pages/general';
// import { Lists } from '../pages/lists';
import { Tracking } from '../pages/tracking';
import { ApiIntegration } from '../pages/api-integration';
import { Dashboard } from '../pages/dashboard';
import { Reports } from '../pages/reports';
import { AddFunds } from '../pages/finance/AddFunds';
import { AccountStatements } from '../pages/finance/AccountStatements';
import { InvoiceHistory } from '../pages/finance/InvoiceHistory';
import { PaymentDetails } from '../pages/finance/PaymentDetails';

export const appRoutes = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    icon: <HomeOutlined />,
    permission_name: 'view_campaign'
  },
  {
    title: 'Campaigns',
    path: '/campaigns',
    component: MediaCampaigns,
    icon: <NotificationOutlined />,
    permission_name: 'view_list'
  },
  {
    title: 'Campaigns',
    path: '/campaigns/:id',
    component: NewCampaign,
    icon: <NotificationOutlined />,
    permission_name: 'view_list',
    skipRender: true
  },
  {
    title: 'Payment Details',
    path: '/payment-details/:id',
    component: PaymentDetails,
    permission_name: 'view_list',
    skipRender: true
  },
  // {
  //   title: 'Lists',
  //   path: '/lists',
  //   component: Lists,
  //   icon: <BarsOutlined />,
  //   permission_name: 'view_list'
  // },
  {
    title: 'Reports',
    path: '/reports',
    component: Reports,
    icon: <BarChartOutlined />,
    permission_name: 'view_landingpage'
  },
  {
    title: 'Tracking',
    path: '/tracking',
    component: Tracking,
    icon: <SwapOutlined />,
    permission_name: 'view_company'
  },
  {
    title: 'Finance',
    path: '/finance',
    component: () => <h1>Finance</h1>,
    icon: <CreditCardOutlined />,
    permission_name: 'view_channels',

    children: [
      {
        title: 'Add Funds',
        path: '/add',
        component: AddFunds,
        permission_name: 'view_channels'
      },
      {
        title: 'Account Statement',
        path: '/account',
        component: AccountStatements,
        permission_name: 'view_zones'
      },
      {
        title: 'Invoice History',
        path: '/history',
        component: InvoiceHistory,
        permission_name: 'view_channels'
      }
    ]
  },
  {
    title: 'API Integration',
    path: '/integration-api',
    component: ApiIntegration,
    icon: <ReadOutlined />,
    permission_name: 'view_product'
  },
  {
    title: 'My Profile',
    path: '/my-profile',
    icon: <UserOutlined />,
    component: Profile,
    permission_name: 'view_landingpage'
  }
];

export const getAllowedRoutes = () => {
  return appRoutes;
};
