import React, { useState } from 'react';
import { Button, Divider, Row, Tabs } from 'antd';

import styles from './TrafficSources.module.css';
import { CategoriesTab } from './categories';
import { SubIDTab } from './subids';
const { TabPane } = Tabs;

export const TrafficSourcesTab = ({ type, setMainActiveTab, setMaxTabPage, maxTabPage }) => {
  const [activeTab, setActiveTab] = useState(type === 'Contextual Ad' ? 'categories' : 'subid');

  const handleNextClick = () => {
    if (maxTabPage === 3) {
      setMaxTabPage(4);
    }
    setMainActiveTab('4');
  };
  return (
    <div>
      <Tabs activeKey={activeTab} onChange={setActiveTab} animated={false}>
        {type === 'Contextual Ad' && (
          <TabPane tab="Traffic Categories" key="categories">
            <CategoriesTab changeActiveTab={setActiveTab} />
          </TabPane>
        )}
        <TabPane tab="SubID & Placements" key="subid">
          <SubIDTab changeActiveTab={handleNextClick} />
        </TabPane>
      </Tabs>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button type="primary" className={styles.proceedButton} onClick={handleNextClick}>
          Proceed to Budget & Bids
        </Button>
      </Row>
    </div>
  );
};
