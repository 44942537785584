import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Spinner } from '../../components/Spinner';
import './Profile.css';
import { PersonalDetails } from './components/PersonalDetails';
import { userProfileService } from '../../services/userProfile';
import { openErrorNotification } from '../../utils/notifications';
import { CompanyDetails } from './components/CompanyDetails';
import { ChangePassword } from './components/ChangePassword';
import styles from './Profile.module.css';

const { TabPane } = Tabs;
export const Profile = (props) => {
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState();
  useEffect(() => {
    const getAccountDetails = async () => {
      setLoading(true);
      try {
        const userDataRes = await userProfileService.getUserDetails();
        setUserDetails(userDataRes[0]);
      } catch (e) {
        openErrorNotification({ message: 'Something went wrong!' });
      } finally {
        setLoading(false);
      }
    };
    getAccountDetails();
  }, []);
  return (
    <div>
      {loading ? (
        <Spinner></Spinner>
      ) : (
        <>
          <h2 className={styles.titleProfile}>My Profile</h2>
          <Tabs defaultActiveKey={props.location.fromHeader ? '2' : '1'} className={styles.tabsSection}>
            <TabPane tab="Personal Details" key="1">
              <PersonalDetails initialData={userDetails}></PersonalDetails>
            </TabPane>
            <TabPane tab="Company Details" key="2">
              <CompanyDetails></CompanyDetails>
            </TabPane>
            <TabPane tab="Security" key="6">
              <ChangePassword userDetails={userDetails}></ChangePassword>
            </TabPane>
          </Tabs>
        </>
      )}
    </div>

    /* <TabPane tab="Personal Details" key="1">
        <Row>
          <Col span={2}>
            <UserOutlined style={{ color: 'black' }} />
          </Col>
          <Col span={22}>
            <p className="formTitles">Edit Personal Details</p>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <Avatar size={200} src="https://i.pinimg.com/originals/45/d9/8a/45d98aa922bef6b5213b488dc36a8764.png" />
            <br />
            <UpdateImage />
          </Col>
          <Col span={12}>
            <p>
              <b>User Information</b>
            </p>
            <p className="formLabels">First Name</p>
            <Input />
            <p className="formLabels">Last Name</p>
            <Input />
            <p className="formLabels">Email</p>
            <Input />
            <br />
            <br />
            <Button type="primary">Confirm User Details</Button>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col span={4}>
            <p>
              <b>Personal Details</b>
            </p>
            <p>Your personal information is never shown to other users.</p>
          </Col>
          <Col span={10}>
            <p className="formLabels">Phone</p>
            <Input />
            <Space>
              <Select defaultValue="messenger" style={{ width: 120 }}>
                <Option value="messenger">Messenger</Option>
                <Option value="skype">Skype</Option>
                <Option value="whatsapp">Whatsapp</Option>
              </Select>
              <p className="formLabels">Nick Name</p>
            </Space>
            <Input />
            <p className="formLabels">Date of Birth</p>
            <Input />
            <p className="formLabels">Street Address line 1</p>
            <Input />
            <p className="formLabels">Street Address line 2</p>
            <Input />
            <p className="formLabels">Postal Code</p>
            <Input />
            <p className="formLabels">City</p>
            <Input />
            <p className="formLabels">Country</p>
            <Input />
            <br />
            <br />
            <br />
            <Button type="primary"> Confirm Personal Details </Button>
          </Col>
        </Row>
      </TabPane> 
    <TabPane tab="Company Details" key="2">
        <Row>
          <Col span={2}>
            <UserOutlined style={{ color: 'black' }} />
          </Col>
          <Col span={22}>
            <p className="formTitles">Edit Company Details</p>
          </Col>
        </Row>
        <Row>
          <Col span={4}>
            <p>
              <b>Company Details</b>
            </p>
            <p>Your personal information is never shown to other users.</p>
          </Col>
          <Col span={10}>
            <p className="formLabels">Company Name</p>
            <Input />
            <p className="formLabels">Company Registration No.</p>
            <Input />
            <p className="formLabels">Company VAT No.</p>
            <Input />
            <p className="formLabels">Street address line 1</p>
            <Input />
            <p className="formLabels">Street address line 2</p>
            <Input />
            <p className="formLabels">Postal Code</p>
            <Input />
            <p className="formLabels">City</p>
            <Input />
            <p className="formLabels">Country</p>
            <Input />
            <Button type="primary"> Confirm Company Details </Button>
          </Col>
        </Row>
      </TabPane>
      <TabPane tab="Security" key="3">
        <Row>
          <Col span={4}>
            <p>Change password</p>
          </Col>
          <Col span={13}>
            <p className="formLabels">New Password</p>
            <Input.Password size="middle" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            <p className="formLabels">Confirm Password</p>
            <Input.Password size="middle" iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
            <br />
            <br />
            <br />
            <Button type="primary">Update Password</Button>
          </Col>
        </Row>
      </TabPane> */
  );
};
