import React, { useEffect, useState, useContext } from 'react';
import { Skeleton, List, Spin } from 'antd';
import VirtualList from 'rc-virtual-list';
import { NotificationBarListFooter } from './NotificationBar/NotificationBarListFooter';
import { NotificationBarListHeader } from './NotificationBar/NotificationBarListHeader';
import { BarNotificationItem } from './BarNotificationItem';
import NotificationContext from '../../contexts/notificationContext';

export const BarNotification = ({
  dropdownVisible,
  userNotifications,
  setUserNotifications,
  userNotificationsCount,
  loadingNotifications,
  userNotificationPage,
  userNotificationPageSize,
  setUserNotificationPageSize,
  currentModule
}) => {
  const { showUnread, setShowUnread } = useContext(NotificationContext);
  const [notifications, setNotifications] = useState([]);
  const [showAllNotificationsForDropDown, setShowAllNotificationsForDropDown] = useState(false);

  const containerHeight = 300;
  const allContainerHeight = 400;

  useEffect(() => {
    setShowAllNotificationsForDropDown(false);
  }, [currentModule]);

  useEffect(() => {
    if (showUnread === 'True') {
      let readNotifications = userNotifications.filter(notification => {
        if (!notification.read) {
          return notification;
        }
        return false;
      });
      setNotifications(readNotifications);
    }
    else {
      setNotifications(userNotifications);
    }
  }, [
    showUnread,
    setNotifications,
    userNotifications
  ]);

  const onScroll = e => {
    if (showAllNotificationsForDropDown) {
      if (e.currentTarget.scrollHeight - Math.floor(e.currentTarget.scrollTop) >= allContainerHeight) {
        if (userNotificationsCount > userNotificationPageSize) {
          setUserNotificationPageSize((userNotificationPage + 1) * userNotificationPageSize);
        }
      }
    }
  };

  return (
    <List
      className='barNotificationList'
      header={
        <NotificationBarListHeader
          showUnread={showUnread}
          setShowUnread={setShowUnread}
        />
      }
      footer={notifications.length > 0 &&
        <NotificationBarListFooter
          showAllNotificationsForDropDown={showAllNotificationsForDropDown}
          setShowAllNotificationsForDropDown={
            setShowAllNotificationsForDropDown
          }
        />
      }
      bordered
      renderItem={notifications.length === 0 &&
        <Skeleton loading={false} paragraph={{ rows: 14 }}>
          <List.Item>
            <div style={{ width: '500px' }}>
              <div style={{ marginTop: '10px' }}>
                You currently don't have notifications.
              </div>
            </div>
          </List.Item>
        </Skeleton>
      }
    >
      <Spin spinning={loadingNotifications}>
        <VirtualList
          className={!showAllNotificationsForDropDown ? null : 'virtualListWrapper'}
          data={notifications}
          height={!showAllNotificationsForDropDown ? containerHeight : allContainerHeight}
          itemKey="id"
          onScroll={onScroll}
        >
          {notification => (
            <BarNotificationItem
              item={notification}
              userNotifications={userNotifications}
              setUserNotifications={setUserNotifications}
              dropdownVisible={dropdownVisible}
            />
          )}
        </VirtualList>
      </Spin>
    </List >
  );
};
