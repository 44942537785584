import React, { useMemo, useState } from 'react';
import { Form, Input, Select, Spin, DatePicker, Button } from 'antd';
import { userProfileService } from '../../../services/userProfile';
import { openSuccessNotification } from '../../../utils/notifications';
import ImageUploader from 'react-images-upload';
import { UserOutlined } from '@ant-design/icons';
import styles from '../Profile.module.css';

import moment from 'moment';
import { useContext } from 'react';
import UserContext from '../../../contexts/userContext';
const { Option } = Select;
const dateFormat = 'DD-MM-YYYY';
export const PersonalDetails = ({ initialData }) => {
  const { refresh } = useContext(UserContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageRemoved, setImageRemoved] = useState(false);
  const [imageData, setImageData] = useState({
    image: null,
    imageChanged: false
  });

  const initialValues = useMemo(() => {
    if (!initialData) {
      return undefined;
    }
    const {
      email,
      image,
      first_name,
      last_name,
      city,
      country,
      postal_code,
      street_address_line_1,
      street_address_line_2,
      phone_number,
      communication_channel,
      nick_name
    } = initialData;
    const birth_date = initialData['birth_date'] ? moment(new Date(initialData['birth_date'])) : null;

    return {
      email,
      image,
      first_name,
      last_name,
      city,
      country,
      postal_code,
      street_address_line_1,
      street_address_line_2,
      phone_number,
      communication_channel,
      nick_name,
      birth_date
    };
  }, [initialData]);
  const onSubmit = async (values) => {
    values.image = imageData.image;
    setLoading(true);
    try {
      const res = await userProfileService.editUserDetails(values, initialData.id);
      openSuccessNotification({ message: 'The information was updated successfully' });
      refresh();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleImageDrop = async (image, isRemove) => {
    if (isRemove) {
      setImageRemoved(true);
      setImageData({
        image: null,
        imageChanged: false
      });
    } else {
      setImageData({
        image: image[0],
        imageChanged: true
      });
    }

    try {
      const res = await userProfileService.editUserDetails({ image: !isRemove ? image[0] : null }, initialData.id);
      openSuccessNotification({ message: `The image was ${isRemove ? 'removed' : 'uploaded'} successfully` });
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.personalDetailsContent}>
      <h2>
        <UserOutlined /> Edit Personal Details
      </h2>
      <Spin spinning={loading} wrapperClassName={styles.personalDetailsContainer}>
        <Form
          form={form}
          name="userForm"
          initialValues={initialValues}
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.personalDetailsTop}>
            <div className={styles.imageUpload}>
              {/* {imageData.image || (initialValues && initialValues.image && !imageRemoved) ? (
                <div className="removeImageButton" onClick={() => handleImageDrop(null, true)}>
                  <img src="/images/delete_icon.png" alt="Remove" width={30} style={{ cursor: 'pointer' }} />
                </div>
              ) : null} */}
              {imageData.image ? (
                <div className="imagePreviewModal">
                  <img src={URL.createObjectURL(imageData.image)} alt="Preview" />
                </div>
              ) : initialValues && initialValues.image && !imageRemoved ? (
                <div className="imagePreviewModal">
                  <img src={initialData.image} alt="Preview" />
                </div>
              ) : (
                <div className="imagePreviewModal">
                  <img src="/images/no-profile.jpg" alt="Preview" />
                </div>
              )}
              {/* <ImageUploader
                className="imageUploaderModal"
                buttonText="Upload"
                imgExtension={['.jpg', '.jpeg', '.png']}
                singleImage
                withLabel={false}
                onChange={(image) => handleImageDrop(image, false)}
              /> */}
            </div>

            <div className={styles.userInfo}>
              {/* <p className="formLabels">First Name</p> */}
              <p>User Information</p>
              <Form.Item name="first_name">
                <Input disabled placeholder="Name" />
              </Form.Item>
              {/* <p className="formLabels">Last Name</p> */}
              {/* <Form.Item name="last_name">
                <Input placeholder="Last name" />
              </Form.Item> */}
              {/* <p className="formLabels">Email</p> */}
              <Form.Item name="email">
                <Input disabled placeholder="Email" />
              </Form.Item>
              {/* <Form.Item>
                <Button className={styles.confirmInfoChangesBtn} type="primary" htmlType="submit">
                  Confirm User Info Changes
                </Button>
              </Form.Item> */}
            </div>
          </div>
          <div className={styles.personalDetailsBottom}>
            <div className={styles.personalBottomLeft}>
              <h3>Personal details</h3>
              <p>Your personal information is never shown to other users.</p>
            </div>
            <div className={styles.personalBottomRight}>
              {/* <p className="formLabels">Phone</p> */}
              <Form.Item name="phone_number">
                <Input disabled placeholder="Phone number" />
              </Form.Item>
              <div className={styles.contactBlock}>
                <Form.Item name="communication_channel">
                  <Select disabled style={{ width: 120 }} className={styles.selectComChannel}>
                    <Option value="Messenger">Messenger</Option>
                    <Option value="Skype">Skype</Option>
                    <Option value="Telegram">Telegram</Option>
                    <Option value="WeChat">WeChat</Option>
                  </Select>
                </Form.Item>
                {/* <p className="formLabels">Nick name</p> */}
                <Form.Item name="nick_name">
                  <Input disabled placeholder="Nick name" />
                </Form.Item>
              </div>
              {/* <p className="formLabels">Date of birth</p> */}
              <Form.Item name="birth_date">
                <DatePicker disabled className={styles.datePickerItem} format={dateFormat}></DatePicker>
              </Form.Item>
              {/* <p className="formLabels">Street Address line 1</p> */}
              <Form.Item name="street_address_line_1">
                <Input disabled placeholder="Street Address line 1" />
              </Form.Item>
              {/* <p className="formLabels">Street Address line 2</p> */}
              <Form.Item name="street_address_line_2">
                <Input disabled placeholder="Street Address line 2" />
              </Form.Item>
              {/* <p className="formLabels">Postal Code</p> */}
              <Form.Item name="postal_code">
                <Input disabled placeholder="Postal Code" />
              </Form.Item>
              {/* <p className="formLabels">City</p> */}
              <Form.Item name="city">
                <Input disabled placeholder="City" />
              </Form.Item>
              {/* <p className="formLabels">Country</p> */}
              <Form.Item name="country">
                <Input disabled placeholder="Country" />
              </Form.Item>
              {/* <Form.Item>
                <Button className={styles.confirmPersonalChangesBtn} type="primary" htmlType="submit">
                  Confirm Personal Details
                </Button>
              </Form.Item> */}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
