import React, { useContext } from 'react';
import { Row } from 'antd';
import styles from '../CampaignReview.module.css';
import TargetingContext from '../../../../../contexts/targetingContext';
import {
  renderCountries,
  renderOS,
  renderBrowsers,
  renderAges,
  renderDays,
  renderHours,
  renderConnections,
  renderDeviceTypes,
  renderDeviceManufacturers,
  renderIpIsp,
  renderCountryList,
  renderIpList
} from '../utils/targetingItemRender';
import CampaignContext from '../../../../../contexts/campaignContext';

export const TargetingInfo = ({ type }) => {
  const { countriesAll, countryListAll, agesAll, osAll, browserAll, manufacturerAll, connectionAll, typeDeviceAll, ipListAll } =
    useContext(TargetingContext);
  const { targeting } = useContext(CampaignContext);

  // const formatedFromValue = ('0' + targeting.subscribed_age.from).slice(-2) + ':00';
  // const formatedToValue = ('0' + targeting.subscribed_age.to).slice(-2) + ':00';

  return (
    <Row style={{ width: '100%' }}>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Geo-Targeting</h3>
        {targeting.country && targeting.country.item && targeting.country.item.length > 0 ? (
          renderCountries(targeting.country, countriesAll)
        ) : (
          <span className={styles.targetingInfoType}>No Country Targeting</span>
        )}
        <div style={{ display: 'block', width: '100%' }}>
          {targeting.country &&
            targeting.country.list &&
            targeting.country.list.length > 0 &&
            renderCountryList(targeting.country, countryListAll)}
        </div>
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Operating Systems</h3>
        <div>
          {targeting.os && targeting.os.length > 0 ? (
            renderOS(targeting.os, osAll)
          ) : (
            <span className={styles.targetingInfoType}>All Operating Systems</span>
          )}
        </div>
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Browsers</h3>
        <div>
          {targeting.browser && targeting.browser.length > 0 ? (
            renderBrowsers(targeting.browser, browserAll)
          ) : (
            <span className={styles.targetingInfoType}>All Browsers</span>
          )}
        </div>
      </Row>
      {type === 'Push' ? (
        <Row className={styles.targetingInfoRow}>
          <h3 className={styles.targetingInfoHeader}>User Freshness</h3>
          {targeting.freshness_type === 'hour' ? (
            <>
              <span className={styles.targetingInfoType} style={{ color: '#95B79A' }}>
                Included
              </span>
              <div className={styles.selectionTag}>
                {targeting.subscribed_age.from} - {targeting.subscribed_age.to} hour
              </div>
            </>
          ) : targeting.age && targeting.age.item.length > 0 ? (
            renderAges(targeting.age, agesAll)
          ) : (
            <span className={styles.targetingInfoType}>All Users</span>
          )}
        </Row>
      ) : null}
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Days</h3>
        {targeting.day && targeting.day.item.length > 0 ? (
          renderDays(targeting.day)
        ) : (
          <span className={styles.targetingInfoType}>Every Day</span>
        )}
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Hours</h3>
        {targeting.hour && targeting.hour.item.length > 0 ? (
          renderHours(targeting.hour)
        ) : (
          <span className={styles.targetingInfoType}>24 hours a Day</span>
        )}
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>IP & ISP</h3>
        {targeting.ip && targeting.ip.item.length > 0 ? (
          renderIpIsp(targeting.ip)
        ) : (
          <span className={styles.targetingInfoType}>No Targeting</span>
        )}
        <div style={{ display: 'block', width: '100%' }}>
          {targeting.ip && targeting.ip.list && targeting.ip.list.length > 0 && renderIpList(targeting.ip, ipListAll)}
        </div>
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Device Types</h3>
        {targeting.device_type && targeting.device_type.item.length > 0 ? (
          renderDeviceTypes(targeting.device_type, typeDeviceAll)
        ) : (
          <span className={styles.targetingInfoType}>All Types</span>
        )}
      </Row>
      <Row className={styles.targetingInfoRow}>
        <h3 className={styles.targetingInfoHeader}>Device Manufacturers</h3>
        {targeting.device_manufacturer && targeting.device_manufacturer.item.length > 0 ? (
          renderDeviceManufacturers(targeting.device_manufacturer, manufacturerAll)
        ) : (
          <span className={styles.targetingInfoType}>All Manufacturers</span>
        )}
      </Row>
    </Row>
  );
};
