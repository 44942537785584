export const reducerGenCreatives = (state, action) => {
  switch (action.type) {
    case 'delete':
      return state.filter((item) => item.gen_id !== action.payload);
    case 'create':
      return [...state, action.payload];
    case 'edit':
      const arrayId = state.findIndex((item) => item.gen_id === action.id);
      const parsed = [...state];
      parsed[arrayId] = { ...action.payload, gen_id: action.id };
      return parsed;
    case 'generate':
      return action.payload;
    case 'generator_update':
      return state.map((item) => {
        return {
          ...item,
          description: { en: action.payload.description },
          landing_url: action.payload.landingUrl,
          icon: action.payload.icon
        };
      });
    case 'reset':
      return [];
    default:
      return state;
  }
};
