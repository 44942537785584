import { Button } from 'antd';
import { FileTextOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { getCancelToken, isCancelled } from '../../../services/api';
import { financeService } from '../../../services/finance';
import { openErrorNotification } from '../../../utils/notifications';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import styles from './AddFunds.module.css';
import { FundsForm } from './components/FundsForm';
import { FundSubtotal } from './components/FundSubtotal';
import { TabsSelection } from './components/TabsSelection';
import CheckoutForm from './components/CheckoutForm';
import { useDebounce } from '../../../utils/hooks/useDebounce';
// import './components/Stripe.css';

const stripePromise = loadStripe(
  'pk_live_51Jv0nQA5Gfcusj7ltu2DZt77iiNR3WgYo7mqCRlaC08M6SICsLT7bhsqSlNcbYiY2NFbwIYV6Wb4g5QMRpjDpOzw00iay9Er1G'
);

export const AddFunds = () => {
  const [currentPayment, setCurrentPayment] = useState('card');

  const [loading, setLoading] = useState(false);
  const [calculatedSubtotal, setCalculatedSubtotal] = useState({});

  const [currentPrice, setCurrentPrice] = useState('500');
  const [customPrice, setCustomPrice] = useState();
  const debouncePrice = useDebounce(customPrice, 300);
  const [currency, setCurrency] = useState('USD');

  const [promoCode, setPromoCode] = useState('');
  const debouncePromoCode = useDebounce(promoCode, 500);

  const [clientSecret, setClientSecret] = useState('');

  const [submitLoading, setSubmitLoading] = useState(false);
  const [showStripe, setShowStripe] = useState(false);

  const [wireInvoice, setWireInvoice] = useState(null);

  const parseButtonTitle = (payment) => {
    switch (payment) {
      case 'card':
        return 'Proceed with Payment';
      case 'paypal':
        return 'Pay via PayPal';
      case 'wire':
        return 'Get Proforma Invoice';
      default:
        return '';
    }
  };

  const handleSubmit = async (payment) => {
    if (loading) {
      return;
    }
    switch (payment) {
      case 'paypal':
        setSubmitLoading(true);
        try {
          const res = await financeService.submitPayPalAmount(
            calculatedSubtotal.value_usd,
            calculatedSubtotal.value_usd_original,
            promoCode
          );

          if (res.success) {
            window.location.href = res.data.approve_url;
          } else {
            openErrorNotification({ message: res.message });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setSubmitLoading(false);
        }
        break;
      case 'card':
        setSubmitLoading(true);
        try {
          const res = await financeService.initStripe(calculatedSubtotal.value_usd, calculatedSubtotal.value_usd_original, promoCode);

          if (res.success) {
            setClientSecret(res.data.client_secret);
            setShowStripe(true);
          } else {
            openErrorNotification({ message: res.message });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setSubmitLoading(false);
        }
        break;
      case 'wire':
        setSubmitLoading(true);
        try {
          const res = await financeService.generateProInvoice(
            calculatedSubtotal.value_usd,
            calculatedSubtotal.value_usd_original,
            currency,
            promoCode
          );

          if (res.success) {
            setWireInvoice(res.data);
            window.open(res.data, '_blank').focus();
          } else {
            openErrorNotification({ message: res.message });
          }
        } catch (e) {
          console.log(e);
        } finally {
          setSubmitLoading(false);
        }
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (currentPayment !== 'wire') {
      setCurrency('USD');
    }
  }, [currentPayment]);

  useEffect(() => {
    let cancelToken = getCancelToken();
    const calcualteSubtotal = async () => {
      setLoading(true);
      let value = currentPayment === 'wire' ? 1000 : 100;
      if (currentPrice === 'custom' && !isNaN(debouncePrice) && debouncePrice >= value) {
        value = debouncePrice;
      } else {
        value = currentPrice;
      }
      if (value === 'custom') {
        return;
      }
      try {
        // if (currentPayment === 'wire') {
        //   const paymentData = {
        //     currency,
        //     fee: 0,
        //     promo_code_message: '',
        //     promo_code_valid: false,
        //     total: Number(value),
        //     value: Number(value),
        //     value_usd: Number(value),
        //     value_usd_original: Number(value)
        //   };
        //   setCalculatedSubtotal(paymentData);
        // } else {
        const res = await financeService.calculateSubtotal({
          value,
          currency,
          method: currentPayment,
          promo_code: debouncePromoCode,
          cancelToken: cancelToken.token
        });
        if (res.success) {
          setCalculatedSubtotal(res.data);
        } else {
          openErrorNotification({ message: res.message });
        }
        // }
      } catch (e) {
        if (isCancelled(e)) return;
        openErrorNotification(e.message);
      } finally {
        setLoading(false);
      }
    };
    calcualteSubtotal();
    return () => {
      cancelToken.cancel();
      setShowStripe(false);
      setWireInvoice(null);
    };
  }, [currentPrice, debouncePrice, currency, currentPayment, debouncePromoCode]);

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <div className={styles.financeContainer}>
      <h4>Add Funds</h4>
      <TabsSelection
        currentPayment={currentPayment}
        setCurrentPayment={setCurrentPayment}
        currentPrice={currentPrice}
        setCurrentPrice={setCurrentPrice}
        customPrice={customPrice}
      />
      <FundsForm
        currentPayment={currentPayment}
        currency={currency}
        setCurrency={setCurrency}
        currentPrice={currentPrice}
        setCurrentPrice={setCurrentPrice}
        customPrice={customPrice}
        setCustomPrice={setCustomPrice}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        calculatedSubtotal={calculatedSubtotal}
      />
      <FundSubtotal loading={loading} calculatedSubtotal={calculatedSubtotal} currency={currency} currentPayment={currentPayment} />
      {showStripe && clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
      <div className={styles.bottomBtnActions}>
        {!showStripe && (
          <Button
            type="primary"
            disabled={loading}
            loading={submitLoading}
            className={styles.paymentBtn}
            onClick={() => handleSubmit(currentPayment)}
          >
            {parseButtonTitle(currentPayment)}
          </Button>
        )}
        {wireInvoice && (
          <a className={styles.viewProformaInvoice} target="_blank" rel="noreferrer" href={wireInvoice}>
            <FileTextOutlined /> View Proforma Invoice
          </a>
        )}
      </div>
    </div>
  );
};
