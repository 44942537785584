import React from 'react';
import styles from './Spinner.module.css';

export const Logo = ({ collapsed }) => {
  return (
    <div className={styles.logo}>
      <img src={collapsed ? '/images/logo-small.png' : '/images/logo.svg'} alt="Logo" />
      <span>Advertiser</span>
    </div>
  );
};
