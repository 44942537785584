import React, { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { userProfileService } from '../../../services/userProfile';
import { EyeInvisibleOutlined, EyeTwoTone, LockFilled } from '@ant-design/icons';
import { openSuccessNotification } from '../../../utils/notifications';
import styles from '../Profile.module.css';
export const ChangePassword = ({ userDetails }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { id } = userDetails;
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await userProfileService.changePassword(values, id);
      openSuccessNotification({ message: 'Password changed successfully!' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const validatePasswordConfirm = () => {
    let newPassword = form.getFieldValue('new_password');
    let confirmPassword = form.getFieldValue('confirm_password');
    if (newPassword !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };
  const validateNewPassword = (value) => {
    return /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/.test(value);
  };
  return (
    <div className={styles.securityTabContainer}>
      <h2>
        <LockFilled /> Edit Security Information
      </h2>
      <div className={styles.securityTab}>
        <p>Change password</p>
        <Spin spinning={loading}>
          <Form
            form={form}
            name="userForm"
            onFinish={onSubmit}
            onFinishFailed={({ errorFields }) => {
              form.scrollToField(errorFields[0].name);
            }}
          >
            {/* <p className="formLabels">New Password</p> */}
            <Form.Item
              name="new_password"
              rules={[
                {
                  validator: async (rule, value) => {
                    if (!validateNewPassword(value))
                      throw new Error('Password must contain uppercase letter, lowercase letter and a number!');
                  }
                },
                {
                  required: true,
                  message: 'Please type new password!'
                }
              ]}
            >
              <Input.Password
                placeholder="New Password"
                size="middle"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            {/* <p className="formLabels">Confirm Password</p> */}
            <Form.Item
              name="confirm_password"
              rules={[
                {
                  required: true,
                  message: 'Please type password confirmation!'
                },
                {
                  validator: async (rule, value) => {
                    if (validatePasswordConfirm()) throw new Error("Passwords don't match!");
                  }
                }
              ]}
            >
              <Input.Password
                size="middle"
                placeholder="Confirm Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Password
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};
