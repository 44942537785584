import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppLayout } from './layout';
import { PageNotFound } from './pages/page-not-found';
import { ActivationPage } from './pages/activation';
import { ResetPassword } from './pages/reset-password';
import { LockPage } from './pages/LockPage';
import styles from './App.module.css';
import { useState, useEffect } from 'react';
import { auth } from './services/auth';
import { Spin } from 'antd';
import { CoinisLoader } from './components/CoinisLoader';

const unauthenticatedRoutes = ['/reset-password', '/activation', '/lock'];

export function App() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      try {
        await auth.getUserProfile();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    if (!unauthenticatedRoutes.includes(window.location.pathname)) {
      load();
    } else {
      setLoading(false);
    }
  }, []);

  return isLoading ? (
    <div className={styles.spinnerContainer}>
      <Spin size="large" indicator={<CoinisLoader />} />
    </div>
  ) : (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/page-not-found"
          render={(props) => {
            const { state } = props.location;
            const from = state && state.from;
            return <PageNotFound from={from} />;
          }}
        />
        <Route path="/reset-password" component={ResetPassword} />
        <Route exact path="/activation" component={ActivationPage}></Route>
        <Route path="/lock" component={LockPage} />
        <Route path="/" component={AppLayout} />
      </Switch>
    </BrowserRouter>
  );
}
