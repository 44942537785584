import classNames from 'classnames';
import styles from './TimesTable.module.css';
import React from 'react';
import { Checkbox } from 'antd';
import { dayHours } from './utils';

export const TimeRow = ({ title, handleAddDay, handleRemoveDay, selected }) => {
  const classes = classNames(styles.timeRow, {
    [styles.timeRowSelected]: selected.includes(title)
  });

  return (
    <tr className={classes}>
      <td colSpan="3" align="right" className={styles.rowTitle}>
        {title}
      </td>
      <td align="center">
        <Checkbox checked={selected.includes(title)} onChange={(e) => (e.target.checked ? handleAddDay(title) : handleRemoveDay(title))} />
      </td>
      {dayHours.map((item) => (
        <td key={item}></td>
      ))}
    </tr>
  );
};
