import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { userProfileService } from '../../../services/userProfile';
import { openSuccessNotification } from '../../../utils/notifications';
import { UserOutlined } from '@ant-design/icons';
import styles from '../Profile.module.css';
import { auth } from '../../../services/auth';

export const CompanyDetails = () => {
  // userProfileService
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [company, setCompanyId] = useState();
  const setCompanyFields = (fields) => {
    const { name, reg_number, vat_number, street_address_line_1, street_address_line_2, postal_code, city, country } = fields;
    form.setFieldsValue({
      name,
      reg_number,
      vat_number,
      street_address_line_1,
      street_address_line_2,
      postal_code,
      city,
      country
    });
  };
  useEffect(() => {
    const getCompanyDetails = async () => {
      let userObj = await auth.getUserProfile();
      setCompanyId(userObj[0].id);
      setLoading(true);
      try {
        const res = await userProfileService.getCompanyDetails(userObj[0].id);
        setCompanyFields(res);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    getCompanyDetails();
  }, []);
  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await userProfileService.editCompanyDetails(values, company);
      openSuccessNotification({ message: 'The information was updated successfully' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.companyDetailsContainer}>
      <h2>
        <UserOutlined /> Edit Company Details
      </h2>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="userForm"
          onFinish={onSubmit}
          onFinishFailed={({ errorFields }) => {
            form.scrollToField(errorFields[0].name);
          }}
        >
          <div className={styles.companyDetailsBottom}>
            <div className={styles.companyBottomLeft}>
              <h3>Company details</h3>
              <p>Your company information that will be used for billing.</p>
            </div>
            <div className={styles.companyBottomRight}>
              {/* <p className="formLabels">Company Name</p> */}
              <Form.Item name="name">
                <Input disabled placeholder="Company Name" />
              </Form.Item>
              {/* <p className="formLabels">Company Registration No.</p> */}
              <Form.Item name="reg_number">
                <Input disabled placeholder="Company Registration No." />
              </Form.Item>
              {/* <p className="formLabels">Company VAT No.</p> */}
              <Form.Item name="vat_number">
                <Input disabled placeholder="Company VAT No." />
              </Form.Item>
              {/* <p className="formLabels">Street address line 1</p> */}
              <Form.Item name="street_address_line_1">
                <Input disabled placeholder="Street address line 1" />
              </Form.Item>
              {/* <p className="formLabels">Street address line 2</p> */}
              <Form.Item name="street_address_line_2">
                <Input disabled placeholder="Street address line 2" />
              </Form.Item>
              {/* <p className="formLabels">Postal Code</p> */}
              <Form.Item name="postal_code">
                <Input disabled placeholder="Postal Code" />
              </Form.Item>
              {/* <p className="formLabels">City</p> */}
              <Form.Item name="city">
                <Input disabled placeholder="City" />
              </Form.Item>
              {/* <p className="formLabels">Country</p> */}
              <Form.Item name="country">
                <Input disabled placeholder="Country" />
              </Form.Item>

              {/* <Form.Item>
                <Button type="primary" htmlType="submit">
                  Confirm Company Details
                </Button>
              </Form.Item> */}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
};
