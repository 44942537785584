import { callApi } from './api';

class DashboardService {
  async getStatisticData(dateRange, stats) {
    return await callApi({
      url: `/api/campaigns/stats/?resType=chart&columns=date,hour,${stats.join(',')}&date_from=${dateRange.from.format(
        'YYYY-MM-DD'
      )}&date_to=${dateRange.to.format('YYYY-MM-DD')}`,
      method: 'GET'
    });
  }
  async getTopChannelsData(dateRange) {
    return await callApi({
      url: `/api/campaigns/stats/?resType=table&columns=campaign_name,impressions,leads,clicks,spent&ordering=spent&limit=10&date_from=${dateRange.from.format(
        'YYYY-MM-DD'
      )}&date_to=${dateRange.to.format('YYYY-MM-DD')}`,
      method: 'GET'
    });
  }
  async getEarningsMapData(dateRange) {
    return await callApi({
      url: `/api/campaigns/stats/?resType=table&columns=country,spent&date_from=${dateRange.from.format(
        'YYYY-MM-DD'
      )}&date_to=${dateRange.to.format('YYYY-MM-DD')}`,
      method: 'GET'
    });
  }
  async getActivityLog(page) {
    return await callApi({
      url: `/api/dashboard/activity/${page}`,
      method: 'GET'
    });
  }
  async getBlogs() {
    return await callApi({
      url: '/api/dashboard/blogs/4',
      method: 'GET'
    });
  }
  async getXML() {
    return await callApi({
      url: '/api/advertisers/request_xml/',
      method: 'POST'
    });
  }
  async getContactInfo() {
    return await callApi({
      url: '/api/account_manager/get_info/',
      method: 'GET'
    });
  }
  async getTopCardInfo() {
    return await callApi({
      url: '/api/dashboard/cards',
      method: 'GET'
    });
  }
}

export const dashboardService = new DashboardService();
