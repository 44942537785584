import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Table } from './components/Table';

import { mediaCampaignsService } from '../../services/campaigns';
import moment from 'moment';
import { useDebounce, useFetch, useMount } from '../../utils/hooks';
import { BudgetModal } from './components/BudgetModal';

const initialColumns = {
  budget: true,
  base_price: true,
  spent: true,
  impressions: true,
  name: true,
  leads: true,
  created: true,
  clicks: true,
  ctr: true,
  type: true
};

export const MediaCampaigns = () => {
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState({ from: moment(), to: moment() });
  const [checkedColumns, setCheckedColumns] = useState(initialColumns);
  const debouncedSearchValue = useDebounce(searchValue);
  // const urlId = props.match.params.id;

  const [campaignId, setCampaignId] = useState();
  const [budgetInfo, setBudgetInfo] = useState('');
  const [budgetModalVisible, setBudgetModalVisible] = useState(false);

  const location = useLocation();

  useMount(() => {
    if (location.state) {
      const { status } = location.state;
      setSelectedFilters((prevState) => ({ ...prevState, filters: { ...prevState.filters, status } }));
    }
  });

  const loadMediaCampaigns = (cancelToken) =>
    mediaCampaignsService.getCampaignsPaged({
      selectedFilters,
      checkedColumns,
      currentPage,
      pageSize,
      searchValue: debouncedSearchValue,
      cancelToken
    });

  const [
    {
      data: { results: campaigns, summary: sumOfData, count: totalRows },
      isFetching
    },
    getMediaCampaigns
  ] = useFetch(loadMediaCampaigns);

  useEffect(() => setCurrentPage(1), [debouncedSearchValue]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [debouncedSearchValue, currentPage, pageSize, getMediaCampaigns, selectedFilters]);

  const exportCampaings = (filters, dateRange) => {
    mediaCampaignsService.exportMediaCampaigns({ filters, dateRange, checkedColumns });
  };

  return (
    <div>
      <Table
        exportCampaings={exportCampaings}
        submit={(filters) => {
          setSelectedFilters({ filters, dateRange });
        }}
        dateRange={dateRange}
        setDateRange={setDateRange}
        campaigns={campaigns}
        sumOfData={sumOfData}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        searchValue={searchValue}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        onSearch={(search) => setSearchValue(search)}
        onActivate={getMediaCampaigns}
        onPause={getMediaCampaigns}
        onDelete={getMediaCampaigns}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
        onActivateAllCampaigns={getMediaCampaigns}
        setCampaignId={setCampaignId}
        setBudgetInfo={setBudgetInfo}
        setBudgetModalVisible={setBudgetModalVisible}
      />
      <BudgetModal
        budgetModalVisible={budgetModalVisible}
        setBudgetModalVisible={setBudgetModalVisible}
        campaignId={campaignId}
        budgetInfo={budgetInfo}
        onUpdate={getMediaCampaigns}
      />
    </div>
  );
};
