import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { GlobalOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons';

import styles from './NewCreatives.module.css';
import { TableCreatives } from './components/TableCreatives';
import CampaignContext from '../../../../contexts/campaignContext';
import { Generator } from './components/Generator';
import { SmartlinkModal } from './components/SmartlinkModal';
import { calculateId } from './utils';
import { ContextualGen } from './components/ContextualGen';

const MAX_CREATIVES = 15;

export const CreativesTab = ({ type, setIsCreativeValid, onNext, maxTabPage, setMaxTabPage, setShowCampaignOverview, isCampaignEdit }) => {
  const { creatives, dispatchCreatives } = useContext(CampaignContext);
  const [generatorVisible, setGeneratorVisible] = useState(false);
  const [contextualVisible, setContextualVisible] = useState(false);
  const [smartlinkVisible, setSmartlinkVisible] = useState(false);

  useEffect(() => {
    if (getRealCreativesLength(creatives) === 0) {
      setIsCreativeValid(false);
    } else {
      setIsCreativeValid(true);
    }
  }, [creatives]);

  const handleNextTab = () => {
    if (maxTabPage === 6) {
      setShowCampaignOverview(true);
      setMaxTabPage(7);
    }
    onNext();
  };

  /* When we delete previously created creative we are not removing it from the array, we are just marking it for deletion.
     With that in mind we need to filter marked creatives and get real length of creatives.
  */
  const getRealCreativesLength = (creatives) => creatives.filter((item) => item.action !== 'delete').length;

  const handleCreativeDelete = (payload, type) => {
    dispatchCreatives({ type, payload });
  };
  const handleCreativeClone = (id, type) => {
    const stamp = calculateId();
    dispatchCreatives({ type, payload: { id, stamp } });
    return stamp;
  };

  return (
    <div>
      <div className={styles.creativesContentWrapper}>
        <p>
          <GlobalOutlined />
          <span style={{ fontSize: '1.25em', marginLeft: '5px', fontWeight: '600' }}>Creatives</span>
        </p>
        <p style={{ width: '100%', marginLeft: '20px' }}>Build Creatives for your {type.toLowerCase()} campaign</p>
        <TableCreatives
          isCampaignEdit={isCampaignEdit}
          creatives={creatives}
          type={type}
          creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
          handleCreativeDelete={handleCreativeDelete}
          handleCreativeClone={handleCreativeClone}
          setGeneratorVisible={setGeneratorVisible}
          setContextualVisible={setContextualVisible}
          setSmartlinkVisible={setSmartlinkVisible}
        />
        <p style={{ marginTop: '20px', marginBottom: '0px' }}>
          Columns <i>Impressions</i>, <i>Clicks</i> and <i>Conversion</i> show all time stats, starting from the moment the campaign had
          been created and ending with the last update. You can find more detailed stats on{' '}
          <a target="_blank" rel="noopener noreferrer" href="/reports">
            Reports
          </a>{' '}
          page.
        </p>
        <Button
          disabled={MAX_CREATIVES - getRealCreativesLength(creatives) < 1}
          type="primary"
          size="default"
          className={styles.newCreativeButton}
          onClick={() => {
            if (type === 'Smartlink') {
              setSmartlinkVisible(true);
            } else if (type === 'Contextual Ad') {
              setContextualVisible('NEW');
            } else {
              setGeneratorVisible('NEW');
            }
          }}
        >
          <PlusOutlined /> Add New Creative
        </Button>
      </div>

      <div className={styles.actionButtonsCreative}>
        <Button disabled={creatives.length < 1} size="default" className={styles.finishCreativeButton} onClick={handleNextTab}>
          <CheckOutlined /> Review Campaign
        </Button>
      </div>
      <Generator
        type={type}
        creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
        MAX_CREATIVES={MAX_CREATIVES}
        generatorVisible={generatorVisible}
        setGeneratorVisible={setGeneratorVisible}
      />
      <ContextualGen
        contextualVisible={contextualVisible}
        creativesLeft={MAX_CREATIVES - getRealCreativesLength(creatives)}
        setContextualVisible={setContextualVisible}
      />
      <SmartlinkModal smartlinkVisible={smartlinkVisible} setSmartlinkVisible={setSmartlinkVisible} />
    </div>
  );
};
