import { Row, Col, Switch } from 'antd';
import React from 'react';

import styles from './NotificationBar.module.css';

export const NotificationBarListHeader = ({ showUnread, setShowUnread }) => {
  return (
    <Row className={styles.containerHeader}>
      <Col span={8}>
        <h2>Notifications</h2>
      </Col>
      <Col span={12} className={styles.showUnread}>
        <span>Show unread</span>
        <Switch
          checked={showUnread}
          onChange={value => setShowUnread(value === true ? 'True' : null)}
          size="small"
          style={{ marginLeft: '5px' }}
        />
      </Col>
    </Row>
  );
};
