import { Button, Space, Tooltip } from 'antd';
import { FileOutlined } from '@ant-design/icons';
import React from 'react';
import styles from '../BillingPaymentHistory.module.css';

export const ActionsTableCol = ({ id, status, dispatchSelectedFilters, filters, invoice }) => {
  return (
    <Space>
      <Tooltip title="Show invoice">
        <a href={invoice} target="_blank" rel="noreferrer" className={styles.receiptButton}>
          <FileOutlined />
        </a>
      </Tooltip>
    </Space>
  );
};
