import React, { useState, useContext } from 'react';
import { Form, Input, Button, Radio, Select, Tag } from 'antd';
import styles from './GeneralConfig.module.css';
import { useEffect } from 'react';

import { verticalCampaigns } from '../../utils';
import CampaignContext from '../../../../contexts/campaignContext';

export const GeneralConfigTab = ({ isEdit, onNext, setMaxTabPage, maxTabPage, form }) => {
  const { campaign, dispatchCampaign } = useContext(CampaignContext);
  const [status, setStatus] = useState('not available');

  useEffect(() => {
    if (campaign.general) {
      const { has_prelander, status_after_approval, name, status, vertical } = campaign.general;
      status && setStatus(status);
      const initialValues = {
        has_prelander: has_prelander ? has_prelander : false,
        status_after_approval: status_after_approval ? status_after_approval : 'active',
        name,
        status,
        vertical: vertical ? vertical : 'Desktop - Software'
      };
      form.setFieldsValue(initialValues);
    }
  }, [campaign]);

  const onButtonNextClick = (values) => {
    const formatedValues = {
      ...campaign.general,
      ...values
    };
    if (maxTabPage === 1) {
      setMaxTabPage(2);
    }
    dispatchCampaign({ type: 'general', payload: formatedValues });
  };

  let selectVertical = Object.keys(verticalCampaigns).map((key) => (
    <Select.OptGroup key={key} value={key}>
      {verticalCampaigns[key].map((value) => (
        <Select.Option key={key + value} value={value}>
          {value}
        </Select.Option>
      ))}
    </Select.OptGroup>
  ));

  return (
    <div className={styles.generalWrapper}>
      <div className={styles.generalContentWrapper}>
        <Form layout="vertical" form={form} name="GeneralConfig" onFinish={onButtonNextClick}>
          <Form.Item
            label="Campaign Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name'
              }
            ]}
          >
            <Input />
          </Form.Item>

          {isEdit && (
            <div className={styles.statusInfo}>
              <p>Status:</p>
              <Tag color={status === 'pending' ? 'orange' : 'blue'}>{status.toUpperCase()}</Tag>
            </div>
          )}

          <Form.Item label="Campaign Vertical" name="vertical">
            <Select showSearch placeholder="Choose your campaign vertical" dropdownStyle={{ fontWeight: 'bold' }}>
              {selectVertical}
            </Select>
          </Form.Item>
          <Form.Item label="Prelander:" name="has_prelander" className={styles.statusRadioButton}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button className={styles.radioButtonLabels} style={{ marginLeft: '15px' }} value={true}>
                Yes
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value={false}>
                No
              </Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Status after approval:" name="status_after_approval" className={styles.statusRadioButton}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button className={styles.radioButtonLabels} style={{ marginLeft: '15px' }} value="active">
                Active
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value="paused">
                Paused
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
      <Button
        type="primary"
        onClick={async () => {
          try {
            await form.validateFields();
            form.submit();
            onNext();
          } catch (e) {
            console.log(e);
          }
        }}
        className={styles.proceedButton}
      >
        Proceed to Targeting
      </Button>
    </div>
  );
};
