import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';

import styles from '../TrafficSources.module.css';
import TargetingContext from '../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../contexts/campaignContext';
import { TargetingSelectV2 } from '../../../components/TargetingSelectV2';
import { TargetingPreviewV2 } from '../../../components/TargetingPreviewV2';

export const CategoriesTab = ({ changeActiveTab }) => {
  const { categoriesAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  const [shownCategories, setShownCategories] = useState([]);

  const setSelectedCategories = (item, category) => {
    dispatchTargeting({ type: 'category', payload: { item }, category });
  };
  useEffect(() => {
    setShownCategories(categoriesAll);
    dispatchTargeting({ type: 'category_op', payload: { op: 'include' } });
  }, [categoriesAll]);

  const categoriesCount = [].concat.apply([], Object.values(targeting.category.item)).length;
  const categoriesMatchAll = [].concat.apply([], Object.values(categoriesAll.map((item) => item.subcategories)));

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Traffic Categories</p>
          </div>
          <p>Target users by specific traffic categories</p>
        </div>
      </Row>

      <Row className={styles.targetingSelectContainer}>
        <Col className={styles.targetingSelectBox}>
          <Row className={`${styles.targetingSelectDivV2} ${categoriesCount > 100 && styles.validationIssue}`}>
            <p>You can select up to 100 IAB categories.</p>
          </Row>
          <TargetingSelectV2
            targeting={targeting.category.item}
            setSelectedCategories={setSelectedCategories}
            categoriesAll={categoriesAll}
            shownCategories={shownCategories}
          />
        </Col>
        <Col className={styles.targetingPreviewBox}>
          <Row className={styles.targetingPreviewDiv} style={{ padding: '10px' }}>
            <h3 style={{ width: '100%', marginBottom: '0.5rem', color: '#58a54f' }}>Included</h3>
            {categoriesCount === 0 ? (
              <Row className={styles.countBox}>All Categories</Row>
            ) : (
              <Row className={styles.countBox}>
                <span>
                  Categories <span className={styles.countDisplay}>{categoriesCount}</span>{' '}
                </span>
              </Row>
            )}
          </Row>
          <TargetingPreviewV2
            categoriesCount={categoriesCount}
            targeting={targeting.category.item}
            categoriesMatchAll={categoriesMatchAll}
          />
        </Col>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('subid')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
