import React, { useContext, useEffect, useState } from 'react';
import { Row, Input, Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from '../CampaignReview.module.css';
import { openErrorNotification, openSuccessNotification } from '../../../../../utils/notifications';
import { trackingService } from '../../../../../services/tracking';
import CampaignContext from '../../../../../contexts/campaignContext';
import TargetingContext from '../../../../../contexts/targetingContext';

export const TrackingInfo = () => {
  const { campaign } = useContext(CampaignContext);
  const { trackingUrls } = useContext(TargetingContext);

  const [tracker, setTracker] = useState('');
  const placeholder = (allTrackers) => {
    if (campaign.pricing && campaign.pricing.price) {
      switch (campaign.general.conversion_tracking_type) {
        case 'Default':
          return allTrackers.manual.general;
        case 'Voluum':
          return allTrackers.manual.voluum;
        case 'Affise':
          return allTrackers.manual.affise;
        case 'Binom':
          return allTrackers.manual.binom;
        default:
          return null;
      }
    } else {
      switch (campaign.general.conversion_tracking_type) {
        case 'Default':
          return allTrackers.auto.general;
        case 'Voluum':
          return allTrackers.auto.voluum;
        case 'Affise':
          return allTrackers.auto.affise;
        case 'Binom':
          return allTrackers.auto.binom;
        default:
          return null;
      }
    }
  };

  useEffect(() => {
    const getS2sUrl = () => {
      let selectedTracker = placeholder(trackingUrls);
      if (campaign.general && campaign.general.conversion_tracking_value) {
        selectedTracker = selectedTracker.replace('{payout}', campaign.general.conversion_tracking_value);
        selectedTracker = selectedTracker.replace('{sum}', campaign.general.conversion_tracking_value);
      }
      setTracker(selectedTracker);
    };
    getS2sUrl();
  }, [campaign, placeholder]);
  const suffix = (
    <CopyToClipboard text={tracker} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
      <Button type="primary">Copy</Button>
    </CopyToClipboard>
  );
  return (
    <div>
      <h3 className={styles.generalInfoHeader}>
        <SwapOutlined /> Bid goal tracking
      </h3>
      <p className={styles.trackingParagraph}>
        Copy this S2S Postback URL and paste to the relevant field in your tracker or CPA Network account
      </p>
      <Input className={styles.trackingUrl} suffix={suffix} value={tracker} readOnly />
      <p className={styles.trackingParagraph}>
        Do not forget to change <span className={styles.trackingParamSpan}>params in curly brackets</span> to proper parameter names.
      </p>
    </div>
  );
};
