import React from 'react';
import { useState } from 'react';
import styles from './Targeting.module.css';
import { TargetingGroup } from './TargetingGroup';

export const TargetingSelectV2 = ({ targeting, shownCategories, setSelectedCategories }) => {
  return (
    <div className={styles.targetingCheckboxParentDiv}>
      {shownCategories.map((item) => (
        <TargetingGroup
          key={item.category_name}
          selectedValues={targeting}
          setSelectedCategories={setSelectedCategories}
          category_name={item.category_name}
          subcategories={item.subcategories}
        />
      ))}
    </div>
  );
};
