import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, Form, Input, Popover, Badge } from 'antd';
import styles from '../CreativeBuilder.module.css';
import { ImageSingleUploader } from './ImageSingleUploader';
import CampaignContext from '../../../../../../../../../contexts/campaignContext';
import { IconUploader } from './IconUploader';
import { isNotValid, MACROS } from '../../../../../utils';
import { openWarningNotification } from '../../../../../../../../../utils/notifications';
import { MacroInfoModal } from './MacroInfoModal';

export const SingleCreativeForm = ({
  type,
  singleImage,
  setSingleImage,
  singleIcon,
  setSingleIcon,
  setImageModalVisible,
  editCreative,
  currentCreative,
  genCreatives,
  generatorVisible,
  handleClearAll,
  setGeneratorVisible
}) => {
  const [formSingle] = Form.useForm();
  const { dispatchCreatives } = useContext(CampaignContext);

  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);

  const [macroInfoVisible, setMacroInfoVisible] = useState(false);

  const [landingUrl, setLandingUrl] = useState('');

  const onFinishSingle = (values) => {
    editCreative(currentCreative, {
      title: { en: values.title },
      description: { en: values.description },
      image: {
        en: singleImage[0] ? singleImage[0] : ''
      },
      landing_url: values.landing_url,
      icon: singleIcon[0] ? singleIcon[0] : ''
    });
  };

  const handleEditSave = () => {
    const values = formSingle.getFieldsValue();
    const editObject = {
      ...generatorVisible,
      title: { en: values.title },
      description: { en: values.description },
      image: {
        en: singleImage[0] ? singleImage[0] : ''
      },
      landing_url: values.landing_url,
      icon: singleIcon[0]
    };
    const validation = isNotValid(editObject, type);
    if (validation.notValid) {
      openWarningNotification({ message: validation.message });
    } else {
      if (generatorVisible.gen_type === 'clone' || generatorVisible.gen_type === 'clone_new') {
        delete editObject.gen_type;
        dispatchCreatives({ type: 'create', payload: editObject });
      } else if (generatorVisible.id) {
        dispatchCreatives({ type: 'update', payload: editObject });
      } else {
        dispatchCreatives({ type: 'update_new', payload: editObject });
      }
      setGeneratorVisible(false);
      handleClearAll();
    }
  };

  useEffect(() => {
    const currentCreativeObj = currentCreative ? genCreatives.filter((item) => item.gen_id === currentCreative)[0] : null;
    if (currentCreativeObj) {
      formSingle.setFieldsValue({
        title: currentCreativeObj.title.en,
        description: currentCreativeObj.description && currentCreativeObj.description.en,
        landing_url: currentCreativeObj.landing_url
      });
      setLandingUrl(currentCreativeObj.landing_url);
      if (currentCreativeObj.image) {
        setSingleImage([currentCreativeObj.image.en]);
      }
      if (currentCreativeObj.icon) {
        setSingleIcon([currentCreativeObj.icon]);
      }
    }
  }, [currentCreative]);

  useEffect(() => {
    formSingle.submit();
  }, [singleImage, singleIcon]);

  const handleMacrosClick = (macroContent) => {
    const landingUrl = formSingle.getFieldValue('landing_url');
    const newValue = whereTheCursorWas
      ? landingUrl.substring(0, whereTheCursorWas) + macroContent + landingUrl.substring(whereTheCursorWas, landingUrl.length)
      : landingUrl + macroContent;

    if (whereTheCursorWas !== undefined) {
      setWhereTheCursorWas((prevState) => prevState + macroContent.length);
    }
    formSingle.setFieldsValue({ landing_url: newValue });
    setLandingUrl(newValue);
  };

  const countMacrosInLandingUrl = (macro) => {
    const landingUrl = formSingle.getFieldValue('landing_url') ? formSingle.getFieldValue('landing_url') : '';
    return landingUrl.split(macro).length - 1;
  };

  const titleCount = formSingle.getFieldValue('title') ? formSingle.getFieldValue('title').length : 0;
  const descriptionCount = formSingle.getFieldValue('description') ? formSingle.getFieldValue('description').length : 0;

  return (
    <div>
      <Form form={formSingle} name="dynamic_form_item" onChange={() => formSingle.submit()} onFinish={onFinishSingle}>
        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}>* </span> Ad Headline{' '}
          <span className={titleCount > 100 ? styles.dangerTextCounter : undefined}>{titleCount}/100</span>
        </p>
        <div style={{ margin: '0 0.8rem' }}>
          <Form.Item name="title">
            <Input placeholder="Ad Title" className={titleCount > 100 ? styles.dangerText : undefined} />
          </Form.Item>
        </div>

        <ImageSingleUploader singleImage={singleImage} setSingleImage={setSingleImage} setImageModalVisible={setImageModalVisible} />
        {type === 'Push' && (
          <IconUploader uploadType="single_icon" icon={singleIcon} setIcon={setSingleIcon} setImageModalVisible={setImageModalVisible} />
        )}
        <p className={styles.builderLabel}>
          {type === 'Push' && <span className={styles.requiredMark}>* </span>}Ad Description{' '}
          <span className={descriptionCount > 200 ? styles.dangerTextCounter : undefined}>{descriptionCount}/200</span>
        </p>
        <div style={{ margin: '0 0.8rem' }}>
          <Form.Item name="description">
            <Input placeholder="Ad Brand Text" className={descriptionCount > 200 ? styles.dangerText : undefined} />
          </Form.Item>
        </div>
        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}>* </span>Landing URL <span>- full click url including https://</span>
        </p>
        <div style={{ margin: '0 0.8rem' }}>
          <Form.Item name="landing_url">
            <Input placeholder="https://examplelandingpage.com" style={{ borderRadius: '5px' }} />
          </Form.Item>
        </div>
        <p className={styles.builderLabel}>
          <span className={styles.requiredMark}></span>Add Macros <span>- add supported macros to your URL</span>
        </p>
        <div style={{ margin: '0 0.8rem 0 0.8rem' }}>
          {MACROS.map((macro) => (
            <div
              key={macro.value}
              className={countMacrosInLandingUrl(macro.value) ? styles.macroItemSelected : styles.macroItem}
              onClick={() => handleMacrosClick(macro.value)}
            >
              {macro.value}
            </div>
          ))}
        </div>
        <Button className={styles.macroInfoBtn} size="small" type="link" onClick={() => setMacroInfoVisible(true)}>
          Learn what macros do.
        </Button>
        <MacroInfoModal setVisible={setMacroInfoVisible} visible={macroInfoVisible} />
      </Form>
      {generatorVisible !== 'NEW' && (
        <Button className={styles.editSaveBtn} type="primary" onClick={handleEditSave}>
          Save Creative
        </Button>
      )}
    </div>
  );
};
