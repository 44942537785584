import React, { useContext, useEffect, useState } from 'react';
import { Row, Form, Col, Input, Button, Checkbox } from 'antd';
import { GlobalOutlined, SearchOutlined } from '@ant-design/icons';

import styles from './Geos.module.css';
import TargetingContext from '../../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../../contexts/campaignContext';
import { TargetingSelectGeos } from '../../../../components/TargetingSelectGeos';
import { TargetingPreviewGeos } from '../../../../components/TargetingPreviewGeos';
import { openWarningNotification } from '../../../../../../utils/notifications';

export const GeosTab = ({ changeActiveTab }) => {
  const [shownCountries, setShownCountries] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [showRegions, setShowRegions] = useState(false);
  const [form] = Form.useForm();

  const { countriesAll, regionsAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  useEffect(() => {
    const obj = {}
    targeting.region.item.forEach(regionCode => {
      Object.entries(regionsAll).forEach(([countryCode, arrOfCodes]) => {
        arrOfCodes.forEach(el => {
          if (el.id === regionCode) {
            if (obj.hasOwnProperty(countryCode)) {
              obj[countryCode].push(regionCode)
            }
            else {
              obj[countryCode] = [regionCode]
            }
          }
        })
      })
    })
    dispatchTargeting({
      type: 'region', payload: {
        item: { ...obj }
      }
    });
  }, [])

  const setSelectedCountries = (item) => {
    if (item.length > 1) {
      openWarningNotification({ message: "You can't target more than one country!" });
    } else {
      dispatchTargeting({ type: 'country', payload: { item, op: 'include' } });
    };
  }

  const setSelectedRegions = (countryCode, item) => {
    if (item.length === 100) {
      openWarningNotification({ message: "You can't target more than 100 regions!" });
    }
    else {
      dispatchTargeting({
        type: 'region', payload: {
          item: { ...targeting.region.item, [countryCode]: [...item] }
        }
      });
    }
  };

  const onCountriesUnselect = (countryCodes) => {
    const allRegions = { ...targeting.region.item }
    const filteredRegions = {}

    countryCodes.forEach(countryCode => {
      if (allRegions.hasOwnProperty(countryCode)) {
        filteredRegions[countryCode] = [...allRegions[countryCode]]
      }
    })
    dispatchTargeting({
      type: 'region', payload: {
        item: filteredRegions
      }
    });
  }

  const clearAllRegions = () => {
    dispatchTargeting({
      type: 'region', payload: {
        item: {}
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({ status: targeting.country.op });
    if (!targeting.country.item.length && !targeting.country.list.length) {
      setSelectedAll(true);
    }
    setShownCountries(countriesAll);
  }, []);

  const onSearch = (e) => {
    let countries = countriesAll.filter((country) => {
      return country.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    countries =
      countries.length > 0
        ? countries.concat(countriesAll.filter((item) => !countries.some((country) => country.label === item.label)))
        : [];
    setShownCountries(countries);
  };

  const handleClearSelection = () => {
    setSelectedCountries([]);
    clearAllRegions()
  }

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Target Geo Locations</p>
          </div>
          <p>Target country for your campaign.</p>
        </div>
      </Row>
      <Row>
        <Form form={form} style={{ width: '100%' }}>
          {/* <Form.Item label="Status" name="status" style={{ flexDirection: 'row !important' }}>
            <Radio.Group buttonStyle="solid" onChange={onStatusChange}>
              <Radio.Button style={{ marginLeft: '30px' }} value="include">
                Target Selected
              </Radio.Button>
              <Radio.Button value="exclude">Exclude Selected</Radio.Button>
            </Radio.Group>
          </Form.Item> */}
          <Row className={styles.targetingSelectContainer}>
            <Col className={styles.targetingSelectBox}>
              <Row className={styles.targetingSelectDiv}>
                <div className={styles.targetingSelectDivLeft}>
                  <Form.Item className={styles.targetingSearchItem} name="search">
                    <Input onChange={onSearch} prefix={<SearchOutlined />} placeholder="Search countries" />
                  </Form.Item>
                  <Checkbox
                    className={styles.checkboxRegions}
                    checked={showRegions}
                    onChange={(e) => setShowRegions(e.target.checked)}
                    disabled={selectedAll}
                  >
                    Show Regions
                  </Checkbox>
                </div>
                <Button
                  type="primary"
                  size="middle"
                  onClick={handleClearSelection}
                  ghost
                  disabled={targeting.country.item.length === 0}
                >
                  Clear Selection
                </Button>
              </Row>
              <TargetingSelectGeos
                shownCountries={shownCountries}
                selectedItems={targeting.country.item}
                handleCountriesSelect={setSelectedCountries}
                showRegions={showRegions}
                allRegions={regionsAll}
                selectedSubItems={Object.values(targeting.region.item).flat()}
                handleRegionsSelect={setSelectedRegions}
                clearRegions={clearAllRegions}
                setSelectedAll={setSelectedAll}
                selectedAll={selectedAll}
                onCountriesUnselect={onCountriesUnselect}
              />
            </Col>
            <Col className={styles.targetingPreviewBox}>
              <Row className={styles.targetingPreviewDiv} style={{ padding: '12px' }}>
                <h3 style={{ width: '100%', marginBottom: '4px', fontWeight: '700', color: targeting.country.op === 'include' ? '#58a54f' : '#D95C5C' }}>
                  {targeting.country.op === 'include' ? 'Included' : 'Excluded'}
                </h3>
                <Row className={styles.countBox}>Geos included in targeting</Row>
              </Row>
              <TargetingPreviewGeos
                allCountries={countriesAll}
                selectedItems={targeting.country.item}
                allRegions={regionsAll}
                selectedSubItems={Object.values(targeting.region.item).flat()}
                selectedAll={selectedAll}
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('oss')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
