import React from "react";
import { Table as AntTable, Pagination } from "antd";
import styles from "../BillingPaymentHistory.module.css";

export const Table = ({
  campaigns,
  totalRows,
  currentPage,
  pageSize,
  loadingCampaigns,
  onPaginationChange,
  selectedColumns,
}) => {
  return (
    <AntTable
      className={styles.antTableContent}
      columns={selectedColumns}
      // dataSource={campaigns}
      dataSource={campaigns}
      loading={loadingCampaigns}
      pagination={false}
      rowKey={({ id }) => id}
      footer={() => {
        return (
          <Pagination
            className="ant-table-pagination ant-table-pagination-right"
            total={totalRows}
            current={currentPage}
            pageSize={pageSize}
            showSizeChanger
            pageSizeOptions={["10", "20", "30", "50", "100"]}
            onChange={onPaginationChange}
            onShowSizeChange={onPaginationChange}
          />
        );
      }}
      scroll={{ x: "max-content", scrollToFirstRowOnChange: true }}
    />
  );
};
