import React from 'react';
import styles from '../AddFunds.module.css';
import { Spin, Table } from 'antd';

export const FundSubtotal = ({ loading, calculatedSubtotal, currency, currentPayment }) => {
  const displayCurrency = (currency) => {
    switch (currency) {
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return '';
    }
  };
  return (
    <Spin spinning={loading} wrapperClassName={styles.spinWrapper}>
      <div className={styles.subtotalContainer}>
        <div>
          <span>Selected value:</span>
          {currentPayment !== 'wire' && <span>Service fee:</span>}
          <span>Total amount to pay:</span>
        </div>
        <div>
          <span>{calculatedSubtotal && calculatedSubtotal.value && displayCurrency(currency) + calculatedSubtotal.value.toFixed(2)}</span>
          {currentPayment !== 'wire' && (
            <span>{calculatedSubtotal && calculatedSubtotal.fee && displayCurrency(currency) + calculatedSubtotal.fee.toFixed(2)}</span>
          )}
          <span className={styles.totalPriceValue}>
            {calculatedSubtotal && calculatedSubtotal.total && displayCurrency(currency) + calculatedSubtotal.total.toFixed(2)}
          </span>
        </div>
      </div>
      {currentPayment === 'wire' && (
        <span className={styles.optionHighlightPrice}>
          Take into consideration that the service fee depends on your bank fees that may apply, and thus the end value may vary.
        </span>
      )}
      {/* {currentPayment === 'wire' && (
        <Table
          columns={[
            { title: 'Wire transfer information', dataIndex: 'label' },
            { title: 'Click on text to copy values below', dataIndex: 'info' }
          ]}
          dataSource={[
            {
              label: 'Total amount',
              info: calculatedSubtotal && calculatedSubtotal.total && displayCurrency(currency) + calculatedSubtotal.total.toFixed(2)
            },
            {
              label: 'Account holder',
              info: 'COINIS DOO'
            },
            {
              label: 'IBAN',
              info: 'ME2551000000000969991'
            },
            {
              label: 'SWIFT',
              info: 'CKBCMEPG'
            },
            {
              label: 'Bank name',
              info: 'Crnogorska Komercijalna Banka'
            },
            {
              label: 'Bank address',
              info: 'Bulevar Svetog Petra Cetinjskog, Podgorica, Montenegro'
            },
            {
              label: 'Payment description',
              info: 'Prepayment reference RF17A0115128SM (static)'
            }
          ]}
          rowKey="label"
          pagination={false}
        />
      )} */}
    </Spin>
  );
};
