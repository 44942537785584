export const initialSelectedColumns = {
  date: true,
  debit: true,
  credit: true,
  balance: true,
  note: true
};

export const staticColumns = [
  {
    title: 'Date',
    dataIndex: 'date'
  },
  {
    title: 'Debit',
    dataIndex: 'debit'
  },
  {
    title: 'Credit',
    dataIndex: 'credit'
  },
  {
    title: 'Balance',
    dataIndex: 'balance'
  },
  {
    title: 'Note',
    dataIndex: 'note'
  }
];

export const allColumns = [];
