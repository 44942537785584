import React, { useEffect } from 'react';
import { Button, Radio, DatePicker, Select, Row, Col } from 'antd';
import moment from 'moment';
import styles from '../BillingAccountStatements.module.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

export const CampaignFilters = ({ filters, setFilters, submit, dateRange, setDateRange }) => {
  return (
    <div className={styles.filtersContainer}>
      <Radio.Group className={styles.dateRadioSelect} defaultValue="today" size="large">
        <Radio.Button value="today" onClick={() => setDateRange({ from: moment(), to: moment() })}>
          Today
        </Radio.Button>
        <Radio.Button
          value="yesterday"
          onClick={() => setDateRange({ from: moment().subtract(1, 'days'), to: moment().subtract(1, 'days') })}
        >
          Yesterday
        </Radio.Button>
        <Radio.Button value="7days" onClick={() => setDateRange({ from: moment().subtract(6, 'days'), to: moment() })}>
          Last 7 Days
        </Radio.Button>
        <Radio.Button value="30days" onClick={() => setDateRange({ from: moment().subtract(30, 'days'), to: moment() })}>
          Last 30 Days
        </Radio.Button>
        <Radio.Button value="thisMonth" onClick={() => setDateRange({ from: moment().startOf('month'), to: moment().endOf('month') })}>
          This Month
        </Radio.Button>
        <Radio.Button
          value="lastMonth"
          onClick={() =>
            setDateRange({ from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month') })
          }
        >
          Last Month
        </Radio.Button>
      </Radio.Group>
      <div className={styles.filtersContainerBottom}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <RangePicker
              style={{ width: '100%', marginTop: '0.7rem' }}
              allowClear={false}
              onChange={(momentDates, dates) => {
                setDateRange({ from: momentDates[0], to: momentDates[1] });
              }}
              value={[moment(dateRange.from), moment(dateRange.to)]}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Select
              size="medium"
              className={styles.selectFormats}
              placeholder="All Types"
              style={{ width: '100%', marginTop: '0.7rem' }}
              allowClear
              onChange={(value) => {
                setFilters({ ...filters, type: value });
              }}
            >
              <Option value="campaigns_spending">Campaigns spending</Option>
              <Option value="stats_correction">Stats correction</Option>
              <Option value="other_statements">Other statements</Option>
            </Select>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
            <Button
              className={styles.applyFiltersBtn}
              onClick={() => submit(filters)}
              type="primary"
              size="medium"
            >
              Apply Filters
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
