import React, { useState } from 'react';
import { useEffect } from 'react';
import { auth } from '../services/auth';
const UserContext = React.createContext({});
export default UserContext;

export const UserContextProvider = (props) => {
  const [userInfo, setUserInfo] = useState({});
  const getUser = async () => {
    const userObj = await auth.getUserProfile();
    setUserInfo(userObj[0]);
  };

  const refresh = () => {
    getUser();
  };
  useEffect(() => {
    getUser();
  }, []);

  return <UserContext.Provider value={{ userInfo, setUserInfo, refresh }}>{props.children}</UserContext.Provider>;
};
