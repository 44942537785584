import { callApi } from './api';
import moment from 'moment';
class NewCampaignService {
  async createCampaign(data) {
    let dataForPost = this.formatData(data);
    return await callApi({
      url: '/api/campaigns/new/',
      method: 'POST',
      data: dataForPost
    });
  }
  formatData(data) {
    const { general } = data;
    if (general.start_time) {
      general.start_time = moment(general.start_time).format('YYYY-MM-DD');
    }
    if (general.end_time) {
      general.end_time = moment(general.end_time).format('YYYY-MM-DD');
    }
    Object.keys(general).forEach((key) => {
      if (key !== 'start_time' && key !== 'end_time') {
        general[key] == null && delete general[key];
      }
    });
    data.general = general;
    return data;
  }
  async getSuggestedBid(data) {
    return await callApi({
      url: `/api/campaigns/bids/`,
      method: 'POST',
      data
    });
  }
  async editCampaign(data, campId) {
    let dataForPost = this.formatData(data);
    return await callApi({
      url: `/api/campaigns/${campId}/edit/`,
      method: 'POST',
      data: dataForPost
    });
  }
  async updateCampaign(data, campId) {
    return await callApi({
      url: `/api/campaigns/${campId}/price_budget/update/`,
      method: 'POST',
      data
    });
  }
}

export const newCampaignService = new NewCampaignService();
