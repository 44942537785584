import { MinusCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Pagination, Popconfirm, Radio, Table, Tooltip, Modal, notification, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { reportsService } from '../../../services/reports';
import { useFetch } from '../../../utils/hooks/useFetch';
import styles from './NestedTable.module.css';
import { openSuccessNotification, openWarningNotification } from '../../../utils/notifications';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LoadingOutlined } from '@ant-design/icons';
import { TableTitle } from './TableTitle';
import {
  subIdColumnPreset,
  // countryColumnPreset,
  osColumnPreset,
  // osVersionColumnPreset,
  browserColumnPreset,
  iabCategoriesColumnPreset,
  creativeColumnPresetSmartlink,
  creativeColumnPresetContextual,
  creativeColumnPreset
} from '../utils/nestedTableColumnPresets';
import { PricingColumn } from './PricingColumn';

const columnTemplate = [
  {
    value: 'status',
    text: 'Status',
    render: (status) => {
      return <div className={`${styles.status} ${styles['status' + status]}`}>{status}</div>;
    }
  },
  { value: 'creative_id', text: 'ID' },
  { value: 'title', text: 'Ad Headline' },
  { value: 'description', text: 'Ad Description' },
  {
    value: 'image',
    text: 'Image',
    render: (image) => {
      return <div className={styles.creativeImageContainer} style={{ backgroundImage: `url(${image})` }} />;
    }
  },
  {
    value: 'icon',
    text: 'Icon',
    render: (icon) => {
      return <div className={styles.creativeImageIcon} style={{ backgroundImage: `url(${icon})` }} />;
    }
  },
  {
    value: 'landing_url',
    text: 'Landing URL',
    render: (landing_url) => {
      return <CopyToClipboard text={landing_url} onCopy={() => openSuccessNotification({ message: 'Successfully copied to clipboard!' })}>
        <Tooltip placement="top" title={landing_url}>
          <p className={styles.landingUrl}>{landing_url}</p>
        </Tooltip>
      </CopyToClipboard>;
    }
  },
  { value: 'source', text: 'SubID' },
  { value: 'iab_id', text: 'IAB ID' },
  { value: 'iab_name', text: 'IAB name' },
  { value: 'date', text: 'Date' },
  { value: 'country', text: 'Country' },
  { value: 'browser', text: 'Browser' },
  { value: 'os', text: 'OS' },
  { value: 'os_version', text: 'OS Version' },
  { value: 'browser_version', text: 'Browser Version' },
  { value: 'impressions', text: 'Impressions' },
  { value: 'clicks', text: 'Clicks' },
  { value: 'leads', text: 'Conversions' },
  { value: 'spam_clicks', text: 'Spam Clicks' },
  { value: 'spent', text: 'Spent' },
  { value: 'cr', text: 'CR' },
  { value: 'ctr', text: 'CTR' },
  { value: 'cpm', text: 'CPM' },
  { value: 'ecpa', text: 'eCPA' },
];

export const NestedTable = ({ cpcValue, item, dateRange, selectedDateRange }) => {
  const [statsBy, setStatsBy] = useState('source');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [includeExcludeLoading, setIncludeExcludeLoading] = useState(false);

  const actionTableColumn = {
    title: 'Actions',
    fixed: 'right',
    align: 'center',
    className: styles.actionColumn,
    render: (targeting) => {
      let element_id = targeting.source || targeting.country || targeting.os || targeting.browser || targeting.iab_id;
      return (
        statsBy === 'creative' ?
          <span
            onClick={() => updateCreatives(targeting.status === 'active' ? 'paused' : 'active', [targeting.creative_id])}
            className={`${styles.actionBtn} ${styles[targeting.status]}`}
          >
            {targeting.status === 'active' ? (
              <PauseCircleOutlined style={{ paddingRight: 4 }} />
            ) : (
              <PlayCircleOutlined style={{ paddingRight: 4 }} />
            )}
            {targeting.status === 'active' ? 'Pause' : 'Activate'}
          </span>
          :
          <span
            onClick={() => onIncludeExcludeTargeting(targeting.status === 'included' ? 'exclude' : 'include', statsBy === 'source' ? 'subid' : statsBy, [element_id])}
            className={`${styles.actionBtn} ${styles[targeting.status]}`}
          >
            {targeting.status === 'included' ? (
              <MinusCircleOutlined style={{ paddingRight: 4 }} />
            ) : (
              <PlusCircleOutlined style={{ paddingRight: 4 }} />
            )}
            {targeting.status === 'included' ? 'Exclude' : 'Include'}
          </span>

      );
    }
  };

  const [nestedColumnsData] = useState(columnTemplate);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [nestedTableColumns, setNestedTableColumns] = useState([actionTableColumn]);
  const [nestedCurrentPage, setNestedCurrentPage] = useState(1);
  const [nestedCheckedColumns, setNestedCheckedColumns] = useState(subIdColumnPreset);
  const [nestedPageSize, setNestedPageSize] = useState(30);
  const [showPricing, setShowPricing] = useState('source');
  const pageSizeOptions = [10, 20, 30, 50, 100];
  const [nestedSorting, setNestedSorting] = useState({
    field: null,
    order: null
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const apiConfig = {
    filters: { campaigns: [item.campaign_id] },
    dateRange: dateRange,
    checkedColumns: nestedCheckedColumns
  };

  useEffect(() => {
    updateColumns(apiConfig);
    setSelectedFilters(apiConfig);
  }, [nestedCheckedColumns]);

  const pricingTableColumn = {
    title: <div className={styles.cpcTitle}>CPC</div>,
    fixed: 'right',
    align: 'center',
    render: (innerItem) => <PricingColumn item={innerItem} campaign_id={item.campaign_id} showPricing={showPricing} />
  };

  const refactorTableData = () => {
    const tableDataRefactored = tableData
      ? tableData.map((el) => {
        const refactor = { ...el };
        // console.log('ref ', refactor, 'index: ', index);
        refactor.revenue = refactor.revenue && '$ ' + refactor.revenue.toFixed(2);
        refactor.cpm = refactor.cpm && '$ ' + refactor.cpm.toFixed(4);
        refactor.spent = refactor.spent && '$ ' + refactor.spent.toFixed(4);
        refactor.revenue = refactor.revenue && '$ ' + refactor.revenue.toFixed(4);
        refactor.cr = refactor.cr && refactor.cr.toFixed(2) + ' %';
        refactor.ctr = refactor.ctr && refactor.ctr.toFixed(2) + ' %';
        return {
          key:
            '' +
            el.source +
            el.iab_id +
            el.country +
            el.os +
            el.browser +
            selectedDateRange.from.toString() +
            selectedDateRange.to.toString(),
          ...refactor
        };
      })
      : '';
    return tableDataRefactored;
  };

  const handleSortingClick = useCallback(
    (value, order, multi = false) => {
      setNestedSorting(() => {
        if (!multi) {
          return { [value]: order };
        }
        if (nestedSorting[value] === order) {
          const newOrdering = { ...nestedSorting };
          delete newOrdering[value];
          return newOrdering;
        }
        return { ...nestedSorting, [value]: order };
      });
    },
    [nestedSorting]
  );

  const updateColumns = useCallback(
    (value) => {
      setNestedTableColumns(() => {
        let newTableColumn = [];
        nestedColumnsData
          .filter((column) => value.checkedColumns[column['value']])
          .forEach(function ({ text, value, render }) {
            newTableColumn.push({
              title: (
                <TableTitle
                  text={text}
                  order={nestedSorting[value] ? nestedSorting[value] : false}
                  value={value}
                  onSortingClick={handleSortingClick}
                />
              ),
              dataIndex: value,
              render: render
            });
          });
        if (!!showPricing) {
          newTableColumn.push(pricingTableColumn);
        }
        newTableColumn.push(actionTableColumn);
        return newTableColumn;
      });
    },
    [nestedSorting, handleSortingClick, showPricing, actionTableColumn, nestedColumnsData, pricingTableColumn]
  );

  const loadZoneData = (cancelToken) => {
    return reportsService.getStatsPaged({
      selectedFilters,
      currentPage: nestedCurrentPage,
      pageSize: nestedPageSize,
      sorting: nestedSorting,
      cancelToken,
      details: true
    });
  };

  const total = [];
  const table = [];
  const rows = 0;

  const [
    {
      data: { table: tableData, rows: totalItems },
      isFetching
    },
    getZoneData
  ] = useFetch(loadZoneData, { table, total, rows });

  const loading = isFetching;
  useEffect(() => setNestedCurrentPage(1), []);

  useEffect(() => {
    if (selectedFilters.filters) {
      getZoneData();
    }
  }, [nestedSorting, nestedPageSize, selectedFilters, getZoneData, cpcValue, nestedCurrentPage]);

  const onIncludeExcludeTargeting = async (action, element, ids) => {
    if (element === 'subid') {
      ids = ids.map((id) => id + '.*');
    }

    let data = {
      campaign_id: item.campaign_id,
      action,
      element,
      element_ids: ids
    };

    setIncludeExcludeLoading(true);

    try {
      const res = await reportsService.changeTargetingStatus(data);
      if (res.success) {
        setSelectedRowKeys([]);
        getZoneData();
        openSuccessNotification({ message: res.message });
      } else {
        openWarningNotification({ message: res.message });
      }
    } catch (e) {
      console.log(e);
      openWarningNotification({ message: 'Something went wrong!' });
    } finally {
      setIncludeExcludeLoading(false);
    }
  };

  const updateCreatives = async (action, ids) => {
    notification.open({
      message: 'Updating...',
      key: 'update',
      icon: <LoadingOutlined style={{ color: '#00cc66' }} />,
      duration: 0,
    });

    setUpdateLoading(true);

    try {
      const res = await reportsService.updateCreatives({ creative_ids: ids, status: action });
      if (res.success) {
        notification['success']({
          message: 'Update successful!',
          key: 'update',
        });
        setSelectedRowKeys([]);
        getZoneData();
      } else {
        throw new Error();
      }
    } catch (e) {
      notification['error']({
        message: 'Error, failed to update!',
        key: 'update',
      });
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <div>
      <div className={styles.statsBy}>
        <span>Stats by</span>
        <Radio.Group
          defaultValue="source"
          buttonStyle="solid"
          onChange={(e) => {
            setStatsBy(e.target.value);
            setSelectedRowKeys([]);
            if (e.target.value === 'country') {
              setShowPricing('countries');
            } else if (e.target.value === 'source') {
              setShowPricing('source');
            } else if (e.target.value === 'iab') {
              setShowPricing('categories');
            } else {
              setShowPricing(false);
            }
          }}
        >
          <Radio.Button value="source" onClick={() => setNestedCheckedColumns(subIdColumnPreset)}>
            SubID
          </Radio.Button>
          {/* <Radio.Button value="country" onClick={() => setNestedCheckedColumns(countryColumnPreset)}>
            Country
          </Radio.Button> */}
          {item.campaign_type === 'Contextual Ad' && (
            <Radio.Button value="iab" onClick={() => setNestedCheckedColumns(iabCategoriesColumnPreset)}>
              Traffic Categories
            </Radio.Button>
          )}
          <Radio.Button value="os" onClick={() => setNestedCheckedColumns(osColumnPreset)}>
            OS
          </Radio.Button>
          {/* <Radio.Button value="os_version" onClick={() => setNestedCheckedColumns(osVersionColumnPreset)}>
            OS Version
          </Radio.Button> */}
          <Radio.Button value="browser" onClick={() => setNestedCheckedColumns(browserColumnPreset)}>
            Browser
          </Radio.Button>
          {/* <Radio.Button value="browser_version" onClick={() => setNestedCheckedColumns(browserVersionColumnPreset)}>
            Browser Version
          </Radio.Button> */}
          <Radio.Button value="creative" onClick={() => item.campaign_type === 'Smartlink' ? setNestedCheckedColumns(creativeColumnPresetSmartlink) : item.campaign_type === 'Contextual' ? setNestedCheckedColumns(creativeColumnPresetContextual) : setNestedCheckedColumns(creativeColumnPreset)}>
            Creative
          </Radio.Button>
        </Radio.Group>
        {selectedRowKeys.length > 0 &&
          <div className={styles.multipleAction}>
            {statsBy === 'creative' ?
              <>
                <Button disabled={updateLoading} onClick={() => updateCreatives('active', selectedRowKeys)} type="primary" ghost className={styles.active}>Activate Selected</Button>
                <Button disabled={updateLoading} onClick={() => updateCreatives('paused', selectedRowKeys)} type="primary" ghost className={styles.paused}>Pause Selected</Button>
              </>
              :
              <>
                <Button disabled={includeExcludeLoading} onClick={() => onIncludeExcludeTargeting('include', statsBy === 'source' ? 'subid' : statsBy, selectedRowKeys)} type="primary" ghost className={styles.included}>Include Selected</Button>
                <Button disabled={includeExcludeLoading} onClick={() => onIncludeExcludeTargeting('exclude', statsBy === 'source' ? 'subid' : statsBy, selectedRowKeys)} type="primary" ghost className={styles.excluded}>Exclude Selected</Button>
              </>
            }
          </div>
        }
      </div>
      <Table
        rowSelection={rowSelection}
        className={styles.nestedTableContainer}
        loading={loading || includeExcludeLoading || updateLoading}
        columns={nestedTableColumns}
        dataSource={refactorTableData()}
        pagination={false}
        rowKey={({
          creative_id,
          source,
          browser,
          os,
        }) => creative_id || source || browser || os}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        footer={() => {
          return (
            <div>
              <Pagination
                simple
                className="ant-table-pagination ant-table-pagination-right"
                total={totalItems}
                current={nestedCurrentPage}
                onChange={(value) => setNestedCurrentPage(value)}
                showSizeChanger={true}
                pageSize={nestedPageSize}
                onShowSizeChange={(curr, value) => {
                  setNestedPageSize(value);
                }}
                pageSizeOptions={pageSizeOptions}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
