import { Form, Input, Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { resetPasswordService } from '../../services/resetPassword';
import { openErrorNotification, openSuccessNotification, openWarningNotification } from '../../utils/notifications';
import { getQueryString } from '../../utils/query';
import styles from './ResetPassForm.module.css';

export const ResetPassword = () => {
  const [currentPage, setCurrentPage] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currToken = location.search.split('?token=')[1];
    if (currToken) {
      setToken(currToken);
      setCurrentPage('reset');
    } else {
      setCurrentPage('email');
    }
  }, [location.search]);

  const handleResetPassword = async (values) => {
    setLoading(true);
    try {
      const res = await resetPasswordService.resetPassword({
        new_password: values.newPassword,
        token
      });
      if (res.success) {
        openSuccessNotification({
          message: 'Successfully changed your password!'
        });
        setTimeout(() => {
          window.location.href = '/login/';
        }, 900);
      } else {
        openWarningNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: 'Error while trying to send reset instructions to your email!'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEmailRequest = async (values) => {
    setLoading(true);
    try {
      const res = await resetPasswordService.sendEmailVerification(values);
      if (res.success) {
        openSuccessNotification({ message: res.message });
      } else {
        openErrorNotification({ message: res.message });
      }
    } catch (e) {
      openErrorNotification({
        message: 'Error while trying to send reset instructions to your email!'
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={styles.resetPassFormContainer}>
      <div className={styles.reset}>
        <div className={styles.resetLeft}>
          <div className={styles.logoLight}>
            <a href="/">
              <img src="../images/logo-light.svg" alt="logo-light" />
            </a>
            <p>Reset Password</p>
          </div>
          <img className={styles.leftBg} src="../images/new-form-bg.png" alt="login-bg" />
        </div>
        <div class={styles.resetRight}>
          <div className={styles.containerResetPass}>
            {currentPage === 'reset' ? (
              <>
                <Form className={styles.loginForm} onFinish={handleResetPassword}>
                  <div className={styles.formHeader}>
                    <img src="../images/new-form-logo.svg" alt="logo" />
                    <h1>Reset password</h1>
                  </div>
                  <Form.Item
                    name="newPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your new password'
                      }
                    ]}
                  >
                    <Input type="password" placeholder="Password" suffix={<img src="../images/pass_ch_pass_new.png" alt="pass" />} />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your new password again'
                      },
                      ({ getFieldValue }) => ({
                        validator: async (_, value) => {
                          if (value && getFieldValue('newPassword') !== value) {
                            throw new Error('The two passwords that you entered do not match!');
                          }
                        }
                      })
                    ]}
                  >
                    <Input
                      type="password"
                      placeholder="Confirm Password"
                      suffix={<img src="../images/pass_ch_pass_new.png" alt="pass" />}
                    />
                  </Form.Item>
                  <div className={styles.btnWrapper}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Submit Password
                    </Button>
                  </div>
                </Form>
              </>
            ) : currentPage === 'email' ? (
              <>
                <Form className={styles.loginForm} onFinish={handleEmailRequest}>
                  <div className={styles.formHeader}>
                    <img src="../images/new-form-logo.svg" alt="logo" />
                    <h1>Reset password</h1>
                    <h2>To reset your password, please enter your e-mail below.</h2>
                  </div>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your email address!'
                      }
                    ]}
                  >
                    <Input placeholder="Email Address" suffix={<img src="../images/user_ch_pass_new.png" alt="user" />} />
                  </Form.Item>

                  <div className={styles.activateBtn}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Submit Request
                    </Button>
                  </div>
                </Form>
              </>
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh'
                }}
              >
                <Spin size="large" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
