import React, { useContext } from 'react';
import { Button, Divider, Tooltip } from 'antd';

import { GaneralConfigInfo } from './components/GeneralConfigInfo';
import { TargetingInfo } from './components/TargetingInfo';
import { TrafficInfo } from './components/TrafficInfo';
import { CreativesInfo } from './components/CreativesInfo';
import { TrackingInfo } from './components/TrackingInfo';

import styles from './CampaignReview.module.css';
import TargetingContext from '../../../../contexts/targetingContext';

export const CampaignReview = ({ type, onPrevious, confirmLeaveCampaign, addOrEditCamp, isCreativeValid }) => {
  const { loading } = useContext(TargetingContext);

  return (
    <div>
      <GaneralConfigInfo />
      <TargetingInfo type={type} />
      <TrafficInfo />
      <CreativesInfo campaignType={type} />
      <TrackingInfo />
      <Divider />
      <div className={styles.endActionBtns}>
        <Button className={styles.reviewCampaignExtraBtn} onClick={onPrevious}>
          Back
        </Button>
        <div>
          <Button className={styles.reviewCampaignExtraBtn} onClick={confirmLeaveCampaign}>
            Cancel
          </Button>
          <Tooltip title={!isCreativeValid ? 'Creatives are required, please add new Creative!' : ''}>
            <Button className={styles.createCampaignButton} onClick={addOrEditCamp} loading={loading} disabled={!isCreativeValid}>
              Save Campaign
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
