import { Button, Form, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import styles from '../ContextualGen.module.css';
import { calculateId, isValidDomain } from '../../../utils';

export const ContextualBuilder = ({ contextualAds, setContextualAds, formMultiple, creativesLeft }) => {
  const renderContextualAds = (contextualAds) => {
    return contextualAds.map((item, id) => (
      <div key={item.gen_id} className={styles.inputVariant}>
        <Form.Item
          name={`headline_${item.gen_id}`}
          rules={[
            {
              required: true,
              message: 'Please enter ad headline!'
            },
            {
              validator: async (rule, value) => {
                if (value && value.length > 30) {
                  throw new Error('Max 30 chars allowed!');
                }
              }
            }
          ]}
        >
          <Input value={item.headline} onChange={(e) => handleHeadlineChange(id, e)} />
        </Form.Item>
        <Form.Item
          name={`landing_url_${item.gen_id}`}
          rules={[
            {
              validator: async (rule, value) => {
                if (!isValidDomain(value)) {
                  throw new Error('Invalid landing URL!');
                }
              }
            }
          ]}
        >
          <Input className={styles.inputLandingUrl} value={item.url} onChange={(e) => handleUrlChange(id, e)} />
        </Form.Item>
        <DeleteOutlined style={{ marginBottom: '24px' }} onClick={() => handleAdDelete(id)} />
      </div>
    ));
  };

  const handleHeadlineChange = (id, e) => {
    const headline = e.target.value;
    setContextualAds((exAds) => {
      const newAds = [...exAds];
      newAds[id].headline = headline;
      return newAds;
    });
  };
  const handleUrlChange = (id, e) => {
    const url = e.target.value;
    setContextualAds((exAds) => {
      const newAds = [...exAds];
      newAds[id].url = url;
      return newAds;
    });
  };
  const handleAdDelete = (id) => {
    setContextualAds((exAds) => {
      const newAds = [...exAds];
      newAds.splice(id, 1);
      return newAds;
    });
  };

  return (
    <div className={styles.creativeBuilder}>
      <div className={styles.headerTitle}>
        <p>
          Creative Variations <span>- You may have maximum of 15 creatives.</span>
        </p>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.formVariationsHeader}>
          <p>Ad Headline</p>
          <p>Landing URL</p>
        </div>
        <Form form={formMultiple}>{renderContextualAds(contextualAds)}</Form>
        {contextualAds.length < creativesLeft && (
          <Button onClick={() => setContextualAds((exAds) => [...exAds, { headline: '', url: '', gen_id: calculateId() }])}>
            Add another creative
          </Button>
        )}
      </div>
    </div>
  );
};
