import { callApi } from './api';

class UserProfileService {
  async getUserDetails() {
    return await callApi({ url: '/api/accounts/' });
  }
  async getCompanyDetails(id) {
    return await callApi({ url: `/api/companies/${id}/` });
  }
  dataForPost(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => (value ? formData.append(key, value) : null));
    return formData;
  }
  async editUserDetails(data, id) {
    data.birth_date = data.birth_date ? data.birth_date.format('YYYY-MM-DD') : null;
    const userData = this.dataForPost(data);
    return await callApi({
      url: `api/accounts/${id}/`,
      method: 'PATCH',
      data: userData
    });
  }
  async editCompanyDetails(data, id) {
    return await callApi({
      url: `api/companies/${id}/`,
      method: 'PATCH',
      data
    });
  }
  async changePassword(data, id) {
    delete data.confirm_password;
    return await callApi({
      url: `api/accounts/${id}/change_password/`,
      method: 'PUT',
      data
    });
  }
}

export const userProfileService = new UserProfileService();
