import { callApi } from './api';

class AuthService {
  user = null;

  async getUserProfile() {
    if (this.user) {
      return this.user;
    } else {
      this.user = await callApi({ url: '/api/accounts' });
      return this.user;
    }
  }

  logout() {
    window.location.href = '/logout';
  }
}

export const auth = new AuthService();
