import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

export const generateCountries = (arr) => {
  return arr.map((country) => (
    <Option key={country.code} value={country.code}>
      {country.name}
    </Option>
  ));
};
export const generateCampagins = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.id} value={campaign.id}>
      {campaign.name}
    </Option>
  ));
};
export const generateCampaignTypes = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.value} value={campaign.value}>
      {campaign.label}
    </Option>
  ));
};
export const generateOperatingSystems = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.name} value={campaign.name}>
      {campaign.name}
    </Option>
  ));
};
export const generateBrowsers = (arr) => {
  return arr.map((campaign) => (
    <Option key={campaign.name} value={campaign.name}>
      {campaign.name}
    </Option>
  ));
};
