import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Tooltip } from 'antd';

import { EditOutlined, CopyOutlined, LineChartOutlined, PauseCircleOutlined, PlayCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { mediaCampaignsService } from '../../../services/campaigns';
import { openSuccessNotification, openErrorNotification } from '../../../utils/notifications';
import styles from './Show.module.css';

const allowedActions = ['active', 'paused'];

export const ActionsTableCol = ({ id, title, status, onActivate, onPause, onDelete, toggleActionPending }) => {
  const history = useHistory();

  const openEditPage = () => {
    history.push(`/campaigns/${id}`);
  };

  const activateCampaign = async () => {
    try {
      await mediaCampaignsService.activateCampaign(id);
      openSuccessNotification({ message: 'Campaign successfully activated!' });
      await onActivate();
    } catch (e) {
      openErrorNotification({ message: 'Could not activate a campaign!' });
    }
  };

  const pauseCampaign = async () => {
    try {
      await mediaCampaignsService.pauseCampaign(id);
      openSuccessNotification({ message: 'Campaign successfully paused!' });
      await onPause();
    } catch (e) {
      openErrorNotification({ message: 'Could not pause a campaign!' });
    }
  };

  const deleteCampaign = async () => {
    toggleActionPending(true);
    try {
      await mediaCampaignsService.deleteCampaign(id);
      openSuccessNotification({ message: 'Campaign successfully deleted!' });
      await onDelete();
    } catch (e) {
      openErrorNotification({ message: 'Could not delete a campaign!' });
    } finally {
      toggleActionPending(false);
    }
  };
  const cloneCampaign = async () => {
    toggleActionPending(true);
    try {
      await mediaCampaignsService.cloneCampaign(id);
      openSuccessNotification({ message: 'Campaign successfully cloned!' });
      await onDelete();
    } catch (e) {
      openErrorNotification({ message: 'Could not clone a campaign!' });
    } finally {
      toggleActionPending(false);
    }
  };

  const confirmActivateCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to activate ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => activateCampaign(),
      onCancel() {}
    });
  };

  const confirmPauseCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to pause ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => pauseCampaign(),
      onCancel() {}
    });
  };

  const confirmDeleteCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to delete ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => deleteCampaign(id),
      onCancel() {}
    });
  };

  const confirmCloneCampaign = () => {
    Modal.confirm({
      maskClosable: true,
      title: `Are you sure you want to clone ${title}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => cloneCampaign(id),
      onCancel() {}
    });
  };
  return (
    <div className={styles.actions}>
      <Tooltip title="Edit">
        <Button onClick={openEditPage} style={{ color: '#737373' }} icon={<EditOutlined />}></Button>
      </Tooltip>
      <Tooltip title="Clone">
        <Button onClick={confirmCloneCampaign} style={{ color: '#737373' }} icon={<CopyOutlined />}></Button>
      </Tooltip>
      <Tooltip title="Statistics">
        <Button onClick={() => history.push(`/reports?id=${id}`)} style={{ color: '#737373' }} icon={<LineChartOutlined />}></Button>
      </Tooltip>
      {status.toLowerCase() === 'active' ? (
        <Tooltip title="Pause">
          <Button
            onClick={confirmPauseCampaign}
            style={{ color: '#737373' }}
            disabled={!allowedActions.includes(status.toLowerCase())}
            icon={<PauseCircleOutlined />}
          ></Button>
        </Tooltip>
      ) : (
        <Tooltip title={status.toLowerCase() !== 'active' ? 'Activate' : ''}>
          <Button
            style={{ color: '#737373' }}
            disabled={!allowedActions.includes(status.toLowerCase())}
            onClick={confirmActivateCampaign}
            icon={<PlayCircleOutlined />}
          ></Button>
        </Tooltip>
      )}
      <Tooltip title="Delete">
        <Button onClick={confirmDeleteCampaign} style={{ color: '#737373' }} icon={<DeleteOutlined />}></Button>
      </Tooltip>
    </div>
  );
};
