import React from 'react';
import { PlusOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons';
import styles from '../CreativeBuilder.module.css';
import { openWarningNotification } from '../../../../../../../../../utils/notifications';

export const IconUploader = ({ uploadType, icon, setIcon, setImageModalVisible }) => {
  const handleDeleteImage = () => {
    setIcon([]);
  };
  return (
    <div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Icon{' '}
        <span>
          Minimum size <span>80x80</span> pixels
        </span>
      </p>
      <div className={styles.previeImageBox}>
        {icon[0] && (
          <div className={styles.singleImagePreview} onClick={() => handleDeleteImage()}>
            <img src={icon[0]} alt="creatives" />
            <div className={styles.singleImageDelete}>
              <DeleteOutlined className={styles.deleteIcon} />
            </div>
          </div>
        )}
        <div
          className={styles.addNewImageBtn}
          onClick={() => {
            if (icon.length < 1) {
              setImageModalVisible(uploadType);
            } else {
              openWarningNotification({ message: "You can't add more than 1 image, please delete existing one!" });
            }
          }}
        >
          {icon.length < 1 ? <PlusOutlined style={{ fontSize: '2rem' }} /> : <StopOutlined style={{ fontSize: '2rem' }} />}
        </div>
      </div>
    </div>
  );
};
