export const validateIPaddress = (ipaddress) => {
  const validateArray = ipaddress.map((address) => {
    return (
      /^(?:(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)\.){3}(?:2[0-4]\d|25[0-5]|1\d{2}|[1-9]?\d)$/.test(address) ||
      /^(25[0-5]|2[0-4][0-9]|[01]?[1-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-4]|2[0-4][0-9]|[01]?[1-9][0-9]?)\/([1-9]|1[0-9]|2[0-9]|3[0-2]|(((128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(0|128|192|224|240|248|252|254)\.0\.0)|(255\.255\.(0|128|192|224|240|248|252|254)\.0)|(255\.255\.255\.(0|128|192|224|240|248|252|254))))$/.test(
        address
      )
    );
  });
  return !validateArray.includes(false);
};

export const verticalCampaigns = {
  Mobile: [
    'Mobile App - Smartlink',
    'Mobile App - Utility & VPN',
    'Mobile App - Dating',
    'Mobile App - Antivirus',
    'Mobile App - Casino & Gambling',
    'Mobile App - Other',
    'Mobile App - Games'
  ],
  Popular: [
    'Surveys & Sweeps',
    'Sports Betting',
    'Crypto Offers',
    'Dating',
    'Pin Submit',
    'Casino & Gambling',
    'Mainstream VOD/Streaming',
    'Lead Generation',
    'Smartlink'
  ],
  Desktop: [
    'Desktop - Games',
    'Desktop - Software',
    'Desktop - Antivirus',
    'Desktop - Utility & VPN',
    'Desktop - Smartlink',
    'Desktop - Browser Extensions'
  ],
  Insurance: ['Loans', 'Investing', 'Real Estate', 'Binary Options/Trading'],
  Other: [
    'Careers',
    'Services & Repairs',
    'Education/Personal development',
    'Hobbies & Interests',
    'Shopping and travel',
    'Travel booking sites',
    'Shopping',
    'Health and Beauty',
    'Pharmacy',
    'Recreational Drugs & Weeds',
    'Nutra & Diet'
  ]
};

export const hourMarks = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
  6: '',
  7: '',
  8: '',
  9: '',
  10: '',
  11: '',
  12: '',
  13: '',
  14: '',
  15: '',
  16: '',
  17: '',
  18: '',
  19: '',
  20: '',
  21: '',
  22: '',
  23: '',
  24: ''
};

export const trackers = [
  {
    id: 1,
    imgPath: 'images/icon-binom.png',
    selected: false,
    trackerName: 'Binom'
  },
  {
    id: 2,
    imgPath: 'images/icon-voluum.png',
    selected: true,
    trackerName: 'Voluum'
  },
  {
    id: 3,
    imgPath: 'images/icon-affise.png',
    selected: false,
    trackerName: 'Affise'
  }
];

export const handleSaveCampaign = (
  type,
  campaign,
  targeting,
  osAll,
  browserAll,
  connectionAll,
  typeDeviceAll,
  manufacturerAll,
  isEdit,
  creatives
) =>
  new Promise((resolve) => {
    let targetingFiltered = {};
    for (const [key, value] of Object.entries(targeting)) {
      if (!!value) {
        switch (key) {
          case 'os':
            if (value.length !== osAll.length) {
              const versionFix = value.map((item) => {
                if (!item.version || item.version.length === 0) {
                  return { id: item.id };
                }
                return item;
              });
              targetingFiltered = { ...targetingFiltered, [key]: versionFix };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            }
            break;
          case 'browser':
            if (value.length !== browserAll.length) {
              const versionFix = value.map((item) => {
                if (!item.version || item.version.length === 0) {
                  return { id: item.id };
                }
                return item;
              });
              targetingFiltered = { ...targetingFiltered, [key]: versionFix };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            }
            break;
          case 'hour':
            if (value.item.length === 1 && value.item[0] === '24') {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'day':
            if (value.item.length === 7) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'subscribed_age':
            targetingFiltered = { ...targetingFiltered, [key]: value };
            break;
          case 'age':
            if (value.item.length > 0) {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'device_type':
            if (value.item.length === typeDeviceAll.length) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'device_manufacturer':
            if (value.item.length === manufacturerAll.length) {
              targetingFiltered = { ...targetingFiltered, [key]: null };
            } else {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'category':
            const item = [].concat.apply([], Object.values(value.item));
            // if (item.length === 0) {
            //   item = ['IAB24'];
            // }
            targetingFiltered = { ...targetingFiltered, [key]: { ...value, item } };
            break;
          case 'subid':
            if (value.item || value.list) {
              targetingFiltered = { ...targetingFiltered, [key]: value };
            }
            break;
          case 'region':
            targetingFiltered = { ...targetingFiltered, [key]: { item: Object.values(value.item).flat() } };
            break;
          default:
            targetingFiltered = { ...targetingFiltered, [key]: value };
        }
      }
    }

    if (targeting.freshness_type === 'hour') {
      targetingFiltered.age = null;
    } else {
      targetingFiltered.subscribed_age = null;
    }

    const parsedCreatives = parseCreatives(creatives, isEdit, type);

    const campaignObj = { ...campaign, targeting: targetingFiltered, creatives: parsedCreatives };
    campaignObj.general.type = type;

    if (campaign.limit && campaign.limit['limit_value']) {
      if (isEdit) {
        campaignObj.limits = { ...campaign.limit, limit_value: Number(campaign.limit['limit_value']), action: 'update' };
      } else {
        campaignObj.limits = { ...campaign.limit, limit_value: Number(campaign.limit['limit_value']) };
      }
    }

    delete campaignObj.limit;
    resolve(campaignObj);
  });

// We want to add create action if creatives are genereated from the generator component (case: EDIT)
const parseCreatives = (originalCreatives, isEdit, type) => {
  const creatives = originalCreatives.map((item) => {
    return { ...item };
  });
  return creatives
    .map((item) => {
      if (type === 'Push' && item.description) {
        item.body = { en: item.description.en };
        delete item.description;
      } else if (type === 'Native' && item.description && !item.description.en) {
        delete item.description;
      }
      if (isEdit && item.gen_id && !item.action) {
        const temporary = item.status ? {} : { status: 'active' };
        return { ...item, action: 'create', ...temporary };
      }
      return item;
    })
    .filter((item) => !isEdit || (isEdit && item.action));
};
