import React, { useState, useEffect, useContext } from 'react';
import { Row, Form, Radio, Button, Tabs, Select } from 'antd';
import { GlobalOutlined, PushpinOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { openWarningNotification } from '../../../../../../utils/notifications';
import TargetingContext from '../../../../../../contexts/targetingContext';
import { validateIPaddress } from '../../../../utils';
import CampaignContext from '../../../../../../contexts/campaignContext';
import styles from './IP.module.css';

const { TabPane } = Tabs;
const { Option } = Select;

export const IPTab = ({ changeActiveTab }) => {
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [listsArrayOptions, setListsArrayOptions] = useState([]);
  const { ipListAll } = useContext(TargetingContext);
  const [form] = Form.useForm();

  useEffect(() => {
    parseOptions(ipListAll);
    form.setFieldsValue({ item: targeting.ip.item, op: targeting.ip.op, list: targeting.ip.list ? targeting.ip.list : [] });
  }, []);

  const parseOptions = async (list) => {
    let listsArrayOptions = list.map((el) => {
      return <Option key={el.id}>{el.name}</Option>;
    });
    setListsArrayOptions(listsArrayOptions);
  };

  const onStatusChange = ({ target }) => {
    dispatchTargeting({ type: 'ip', payload: { op: target.value } });
  };

  const handleChangeItem = (item) => {
    dispatchTargeting({ type: 'ip', payload: { item } });
  };
  const handleChangeList = (list) => {
    if (list.length > 10) {
      openWarningNotification({ message: "You can't select more than 10 lists" });
    } else {
      dispatchTargeting({ type: 'ip', payload: { list } });
    }
  };

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Target IP & ISPs</p>
          </div>
          <p>Target users by specific IP & ISP</p>
        </div>
      </Row>
      <Row>
        <Form form={form} style={{ width: '100%' }}>
          <Form.Item name="op" style={{ flexDirection: 'row !important' }}>
            <Radio.Group buttonStyle="solid" onChange={onStatusChange}>
              <Radio.Button checked={true} value="include">
                Target Selected
              </Radio.Button>
              <Radio.Button value="exclude">Exclude Selected</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Row>
            <Tabs style={{ width: '300px', height: '319px' }}>
              <TabPane
                tab={
                  <div>
                    <PushpinOutlined />
                    Add IP / Range
                  </div>
                }
                key="1"
              >
                <Form.Item
                  name="item"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        let isValid = validateIPaddress(value);
                        if (!isValid) {
                          throw new Error('You have entered an invalid IP address');
                        }
                      }
                    }
                  ]}
                >
                  <Select mode={'tags'} allowClear style={{ width: '100%' }} onChange={handleChangeItem}></Select>
                </Form.Item>
              </TabPane>
              {/* <TabPane tab="Choose IP List" key="2">
                <Form.Item name="list">
                  <Select mode="multiple" style={{ width: '100%' }} placeholder="Tags Mode" onChange={handleChangeList} allowClear>
                    {listsArrayOptions}
                  </Select>
                </Form.Item>
              </TabPane> */}
            </Tabs>
          </Row>
        </Form>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('deviceManufacturers')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
