import React from 'react';
import { Button } from 'antd';

export const NotificationBarListFooter = ({
  showAllNotificationsForDropDown,
  setShowAllNotificationsForDropDown,
}) => {

  const handleLoadMore = () => {
    setShowAllNotificationsForDropDown(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {!showAllNotificationsForDropDown &&
        <Button
          style={{ margin: '16px' }}
          onClick={handleLoadMore}
          type="link"
        >
          Load more
        </Button>
      }
    </div>
  );
};
