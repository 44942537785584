import React from 'react';
import styles from './Targeting.module.css';

export const TargetingPreviewGroup = ({ subcategoriesAll, title, subcategories }) => {
  return (
    <div className={styles.categoryGroupContainer}>
      <p className={styles.previewGroupTitle}>{title}</p>
      <div className={styles.subcategoryPreviewGroup}>
        {subcategories.map((subcategory) => {
          const title = subcategoriesAll.filter((x) => x.value === subcategory)[0].label;
          return (
            <span key={subcategory} className={styles.subcatTag}>
              {title}
            </span>
          );
        })}
      </div>
    </div>
  );
};
