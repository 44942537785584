import { Badge, Button, Input } from 'antd';
import React from 'react';
import styles from '../CreativeBuilder.module.css';
import { ImageUploader } from './ImageUploader';
import { IconUploader } from './IconUploader';
import { useState } from 'react';
import { Headlines } from './Headlines';
import { MACROS } from '../../../../../utils';
import { MacroInfoModal } from './MacroInfoModal';

export const GeneratorForm = ({
  type,
  images,
  setImages,
  icon,
  setIcon,
  headlines,
  setHeadlines,
  landingUrl,
  setLandingUrl,
  description,
  setDescription,
  setImageModalVisible,
  formBulk
}) => {
  const [whereTheCursorWas, setWhereTheCursorWas] = useState(undefined);
  const [macroInfoVisible, setMacroInfoVisible] = useState(false);

  const handleMacrosClick = (macroContent) => {
    const newValue = whereTheCursorWas
      ? landingUrl.substring(0, whereTheCursorWas) + macroContent + landingUrl.substring(whereTheCursorWas, landingUrl.length)
      : landingUrl + macroContent;

    if (whereTheCursorWas !== undefined) {
      setWhereTheCursorWas((prevState) => prevState + macroContent.length);
    }
    setLandingUrl(newValue);
  };

  return (
    <div className={styles.builderForm}>
      <Headlines headlines={headlines} setHeadlines={setHeadlines} />
      <ImageUploader images={images} setImages={setImages} setImageModalVisible={setImageModalVisible} />
      {type === 'Push' && <IconUploader uploadType="bulk_icon" icon={icon} setIcon={setIcon} setImageModalVisible={setImageModalVisible} />}

      <p className={styles.builderLabel}>
        {type === 'Push' && <span className={styles.requiredMark}>* </span>}Ad Description{' '}
        <span className={description && description.length > 200 ? styles.dangerTextCounter : {}}>
          {description ? description.length : 0}/200
        </span>
      </p>
      <div style={{ margin: '0 0.8rem' }}>
        <Input
          placeholder="Ad Brand Text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={description && description.length > 200 && styles.dangerText}
        />
      </div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}>* </span>Landing URL <span>- full click url including https://</span>
      </p>
      <div style={{ margin: '0 0.8rem 0.8rem 0.8rem' }}>
        <Input
          placeholder="https://examplelandingpage.com"
          value={landingUrl}
          style={{ borderRadius: '5px' }}
          onChange={(e) => setLandingUrl(e.target.value)}
        />
      </div>
      <p className={styles.builderLabel}>
        <span className={styles.requiredMark}></span>Add Macros <span>- add supported macros to your URL</span>
      </p>
      <div style={{ margin: '0 0.8rem 0rem 0.8rem' }}>
        {MACROS.map((macro) => (
          <div
            key={macro.value}
            className={landingUrl.split(macro.value).length - 1 ? styles.macroItemSelected : styles.macroItem}
            onClick={() => handleMacrosClick(macro.value)}
          >
            {macro.value}
          </div>
        ))}
      </div>
      <Button className={styles.macroInfoBtn} size="small" type="link" onClick={() => setMacroInfoVisible(true)}>
        Learn what macros do.
      </Button>
      <MacroInfoModal setVisible={setMacroInfoVisible} visible={macroInfoVisible} />
    </div>
  );
};
