import React, { useEffect, useContext, useState } from 'react';
import { Row, Radio, Form, Tabs, Select, Button, Typography } from 'antd';
import { GlobalOutlined, PushpinOutlined } from '@ant-design/icons';
import CampaignContext from '../../../../../contexts/campaignContext';
import styles from '../TrafficSources.module.css';
import { openWarningNotification } from '../../../../../utils/notifications';

const { TabPane } = Tabs;
const { Text } = Typography;

export const SubIDTab = () => {
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [form] = Form.useForm();
  const [subIds, setsubIds] = useState([]);

  useEffect(() => {
    form.setFieldsValue({ op: targeting.subid.op });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ subIds: targeting.subid.item.map((item) => item.replace(/\.\*$/, '')) });
    setsubIds(targeting.subid.item.map((item) => item.replace(/\.\*$/, '')));
  }, [targeting.subid.item]);

  const onStatusChange = (e) => {
    dispatchTargeting({ type: 'subid', payload: { op: e.target.value } });
  };

  const handleChangeItem = (ogItem) => {
    const item = ogItem.map((subid) => subid + '.*');
    dispatchTargeting({ type: 'subid', payload: { item } });
  };

  const handleClearAllItems = () => {
    dispatchTargeting({ type: 'subid', payload: { item: [] } });
  };

  const onPasteHandle = (pastedValue) => {
    if (pastedValue !== '') {
      let delimiters = ['\n', ' ', ',', ';'];
      let delimiterCheck = 0;
      let delimiter = '';
      let pasted = [];
      delimiters.forEach((delimiterTemp) => {
        if (pastedValue.includes(delimiterTemp)) {
          delimiter = delimiterTemp;
          delimiterCheck = delimiterCheck + 1;
        }
      });

      if (delimiterCheck > 1) {
        openWarningNotification({
          message:
            'It seems that your subID list contains some irregular characters. Please make sure that your subIDs are delimited either with a comma, a semicolon, a space or a new line.',
          duration: 16
        });
      } else {
        if (delimiterCheck) {
          pasted = pastedValue.split(delimiter).map((item) => item.trim());
        } else {
          pasted = [pastedValue];
        }
      }
      const existing = form.getFieldValue('subIds') ? form.getFieldValue('subIds') : [];
      const uniqueSet = new Set([...pasted, ...existing]);
      form.setFieldsValue({
        subIds: [...uniqueSet]
      });
    }
  };

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>SubID & Placements</p>
          </div>
          <p>Target users by specific SubIDs & Placements</p>
        </div>
      </Row>
      <Row>
        <Form form={form} style={{ width: '100%' }}>
          <Form.Item name="op" style={{ flexDirection: 'row !important' }}>
            <Radio.Group buttonStyle="solid" onChange={onStatusChange}>
              <Radio.Button checked={true} value="include">
                Target Selected
              </Radio.Button>
              <Radio.Button value="exclude">Exclude Selected</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <p>
            You can bulk insert your SubIDs by pasting them below. Delimiters are <Text keyboard>Space</Text> , <Text keyboard>,</Text> ,{' '}
            <Text keyboard>;</Text> , <Text keyboard>↵</Text>.
          </p>
          <Row>
            <Tabs style={{ width: '400px', height: '371px' }}>
              <TabPane
                tab={
                  <div>
                    <PushpinOutlined />
                    Add SubID
                  </div>
                }
                key="1"
                className={styles.subIdInput}
              >
                <Form.Item
                  name="subIds"
                  style={{
                    flexDirection: 'row !important',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '0px',
                    padding: '10px'
                  }}
                >
                  <Select
                    mode={'tags'}
                    // value={parsedValues}
                    // allowClear
                    clearIcon={<GlobalOutlined />}
                    style={{ width: '100%' }}
                    onChange={handleChangeItem}
                    onPaste={(e) => {
                      e.preventDefault();
                      onPasteHandle(e.clipboardData.getData('Text').trim());
                    }}
                  />
                </Form.Item>
                <Button type="primary" ghost onClick={handleClearAllItems}>
                  Clear All
                </Button>
              </TabPane>
            </Tabs>
          </Row>
        </Form>
      </Row>
    </div>
  );
};
