import React, { useContext, useEffect, useState } from 'react';
import { Row, Form, Radio, Col, Input } from 'antd';
import { GlobalOutlined, SearchOutlined } from '@ant-design/icons';

import styles from './Devices.module.css';
import TargetingContext from '../../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../../contexts/campaignContext';
import { TargetingPreview } from '../../../../components/TargetingPreview';
import { TargetingSelect } from '../../../../components/TargetingSelect';

export const DevicesTab = () => {
  const { typeDeviceAll, manufacturerAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [shownManufacturers, setShownManufacturers] = useState([]);
  const [shownTypes, setShownTypes] = useState([]);
  const [typeOrManufacturer, setTypeOrManufacturer] = useState(1);
  const [selectedTypesAll, setSelectedTypesAll] = useState(false);
  const [selectedManufacturersAll, setSelectedManufacturersAll] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setShownTypes(typeDeviceAll);
    setShownManufacturers(manufacturerAll);
    // if (targeting.device_manufacturer.item.length === 0) {
    //   setSelectedManufacturersAll(true);
    //   dispatchTargeting({
    //     type: 'device_manufacturer',
    //     payload: {
    //       item: manufacturerAll.map((x) => x.value)
    //     }
    //   });
    // }
    // if (targeting.device_type.item.length === 0) {
    //   setSelectedTypesAll(true);
    //   dispatchTargeting({ type: 'device_type', payload: { item: typeDeviceAll.map((x) => x.value) } });
    // }
  }, []);

  const onSearch = (e) => {
    let manufacturers = manufacturerAll.filter((item) => {
      return item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    manufacturers =
      manufacturers.length > 0
        ? manufacturers.concat(manufacturerAll.filter((item) => !manufacturers.some((manufacturer) => manufacturer.label === item.label)))
        : [];
    setShownManufacturers(manufacturers);
  };

  const onSearchType = (e) => {
    let types = typeDeviceAll.filter((item) => {
      return item.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    types = types.length > 0 ? types.concat(typeDeviceAll.filter((item) => !types.some((type) => type.label === item.label))) : [];
    setShownTypes(types);
  };

  const onStatusChange = (e) => {
    setShownTypes(typeDeviceAll);
    setShownManufacturers(manufacturerAll);
    form.setFieldsValue({ search: '' });
    setTypeOrManufacturer(e.target.value);
  };

  const setSelectedItems = (item) => {
    if (Number(typeOrManufacturer) === 1) {
      dispatchTargeting({ type: 'device_type', payload: { item } });
      setSelectedTypesAll(item.length === typeDeviceAll.length);
    } else {
      dispatchTargeting({ type: 'device_manufacturer', payload: { item } });
      setSelectedManufacturersAll(item.length === manufacturerAll.length);
    }
  };

  const onCheckAllChange = (e) => {
    if (Number(typeOrManufacturer) === 1) {
      dispatchTargeting({ type: 'device_type', payload: { item: e.target.checked ? typeDeviceAll.map((x) => x.value) : [] } });
      setSelectedTypesAll(e.target.checked);
    } else {
      dispatchTargeting({
        type: 'device_manufacturer',
        payload: { item: e.target.checked ? manufacturerAll.map((x) => x.value) : [] }
      });
      setSelectedManufacturersAll(e.target.checked);
    }
  };

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Target Devices</p>
          </div>
          <p>Target users by specific device type</p>
        </div>
      </Row>
      <Row>
        <Form form={form} style={{ width: '100%', margin: '0px' }}>
          <Form.Item initialValue="1" className={styles.devicesTabSelect} label="" name="status">
            <Radio.Group className={styles.deviceSelect} buttonStyle="solid" onChange={onStatusChange}>
              <Radio.Button className={styles.deviceSelectButton} style={{ width: '150px' }} value="1">
                Device Types
              </Radio.Button>
              <Radio.Button value="0" className={styles.deviceSelectButton} style={{ width: '200px' }}>
                Device Manufacturers
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Row className={styles.targetingSelectContainer}>
            <Col className={styles.targetingSelectBox}>
              <Row className={styles.targetingSelectDiv}>
                <Form.Item className={styles.targetingSearchItem} name="search">
                  {Number(typeOrManufacturer) !== 1 ? (
                    <Input onChange={onSearch} prefix={<SearchOutlined />} placeholder="Search Device Manufacturers" />
                  ) : (
                    <Input onChange={onSearchType} prefix={<SearchOutlined />} placeholder="Search Device Types" />
                  )}
                </Form.Item>
              </Row>
              <TargetingSelect
                simpleItems={Number(typeOrManufacturer) === 1 ? shownTypes : shownManufacturers}
                showVersions={false}
                checkAll={Number(typeOrManufacturer) === 1 ? selectedTypesAll : selectedManufacturersAll}
                selectedItems={Number(typeOrManufacturer) === 1 ? targeting.device_type.item : targeting.device_manufacturer.item}
                handleItemSelect={setSelectedItems}
                handleCheckAll={onCheckAllChange}
                itemName="Devices"
                isLoading={false}
              />
            </Col>
            <Col className={styles.targetingPreviewBox}>
              <Row className={styles.targetingPreviewDiv} style={{ padding: '10px' }}>
                <h3 style={{ width: '100%', color: '#58a54f' }}>Included</h3>
                {selectedManufacturersAll && selectedTypesAll ? (
                  <Row className={styles.countBox}>All Devices</Row>
                ) : (
                  <Row className={styles.countBox}>
                    <span>
                      Device {Number(typeOrManufacturer) === 1 ? 'types' : 'manufacturers'}{' '}
                      <span className={styles.countDisplay}>
                        {Number(typeOrManufacturer) === 1 ? targeting.device_type.item.length : targeting.device_manufacturer.item.length}
                      </span>
                    </span>
                  </Row>
                )}
              </Row>
              <TargetingPreview
                allItems={Number(typeOrManufacturer) === 1 ? shownTypes : shownManufacturers}
                allItemsSelected={Number(typeOrManufacturer) === 1 ? selectedTypesAll : selectedManufacturersAll}
                selectedItems={Number(typeOrManufacturer) === 1 ? targeting.device_type.item : targeting.device_manufacturer.item}
                handleItemSelect={setSelectedItems}
                itemName={Number(typeOrManufacturer) === 1 ? 'Device Types' : 'Device Manufacturers'}
              />
            </Col>
          </Row>
        </Form>
      </Row>
    </div>
  );
};
