import React from 'react';
import styles from './TargetingGeos.module.css';
import { WarningFilled } from '@ant-design/icons';

export const TargetingPreviewGeos = (props) => {
  const selectedCountries = props.selectedItems;
  const selectedRegions = props.selectedSubItems;

  const ItemRow = (item) => {
    let itemName = props.allCountries.filter((x) => x.value === item)[0];
    itemName = typeof itemName !== 'undefined' ? itemName.label : '';
    return (
      <div key={item} className={styles.selectionTag}>
        {itemName}
      </div>
    );
  };

  const SubItemRow = (items) => {
    let namesByCountry = []
    items.forEach(item => {
      selectedCountries.forEach((countryCode) => {
        const foundEl = props.allRegions[countryCode]?.find((x) => x.id === item)
        if (props.allRegions[countryCode] && foundEl) {
          const div = (<div key={item} className={styles.selectionTag}>
            {foundEl.name}
          </div>)
          namesByCountry.push(div)
        }
      })
    })
    return namesByCountry;
  };

  return (
    <div className={styles.targetingPreviewParentDiv}>
      {props.selectedAll || props.selectedItems.length === 0 ? (
        <div className={styles.allCountriesSelectedDiv}>
          <div>
            <WarningFilled />
            <p>You haven’t selected a country yet.</p>
          </div>
          <p>Please select a country on the left to determine your Geo targeting. You can only select one country.</p>
          <p>If you don’t make a selection, your traffic will come from any country.</p>
        </div>
      ) : (
        <div className={styles.shownResultContainer}>
          <div className={styles.selectedCountries}>
            {selectedCountries.map((item) => ItemRow(item))}
          </div>
          <p>You can only select one geo location. All other geos have been excluded from targeting.</p>
          <div className={styles.selectedRegions}>
            {selectedRegions && selectedRegions.length > 0 && SubItemRow(selectedRegions)}
          </div>
        </div>
      )}
    </div>
  );
};
