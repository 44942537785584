// import isValidDomain from 'is-valid-domain';
import { v4 as uuidv4 } from 'uuid';

export const MACROS = [
  {
    value: '${CLICK_ID}',
    text: 'CLICK_ID',
    desc: 'Unique ID which is used to track down a specific click. Must be used in conversation tracking.'
  },
  {
    value: '${SUB_ID}',
    text: 'SUB_ID',
    desc: 'ID extracted from incoming http request. It indicates traffic source your ad was placed onto.'
  },
  {
    value: '${PRICE_PAID}',
    text: 'PRICE_PAID',
    desc: 'The price paid for click. This is your Spent.'
  },
  {
    value: '${OFFER_ID}',
    text: 'OFFER_ID',
    desc: 'Unique ID  for each creative.'
  }
];

export const calculateId = () => uuidv4();

export const isNotValid = (item, type) => {
  let invalidFields = [];
  if (type === 'Native') {
    if (!item.title.en || item.title.en.length > 100) {
      invalidFields.push('Ad Headline');
    }
    if (!isValidCreativesDomain(item.landing_url)) {
      invalidFields.push('Landing URL');
    }
    if (item.description.en && item.description.en.length > 200) {
      invalidFields.push('Ad Description');
    }
    if (!item.image.en) {
      invalidFields.push('Image');
    }
    if (invalidFields.length === 0) {
      return { notValid: false };
    }
  } else if (type === 'Push') {
    if (!item.title.en || item.title.en.length > 100) {
      invalidFields.push('Ad Headline');
    }
    if (!item.description.en || item.description.en.length > 200) {
      invalidFields.push('Ad Description');
    }
    if (!isValidCreativesDomain(item.landing_url)) {
      invalidFields.push('Landing URL');
    }
    if (!item.image.en) {
      invalidFields.push('Image');
    }
    if (!item.icon) {
      invalidFields.push('Icon');
    }
    if (invalidFields.length === 0) {
      return { notValid: false };
    }
  } else if (type === 'Contextual Ad') {
    if (!item.title.en || item.title.en.length > 30) {
      invalidFields.push('Ad Headline');
    }
    if (!isValidCreativesDomain(item.landing_url)) {
      invalidFields.push('Landing URL');
    }
    if (invalidFields.length === 0) {
      return { notValid: false };
    }
  }
  return { notValid: true, message: 'Invalid inputs on: ' + invalidFields.join(', ') };
};

export const isValidDomain = (value) => {
  let url;
  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }
  return url.protocol === 'https:';
};

export const isValidCreativesDomain = (value) => {
  const macroReg = /[^=]\${/g;
  return isValidDomain(value) && !macroReg.test(value);
};
