import React from 'react';
import { Table, Button, Space, Popconfirm } from 'antd';
import { DeleteOutlined, BarChartOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';

import styles from './Creatives.module.css';
import { Link } from 'react-router-dom';
import { parseCreativeToClone } from '../../../../../contexts/campaignContext';
import { calculateId } from '../utils';

export const TableCreatives = ({
  isCampaignEdit,
  creatives,
  creativesLeft,
  type,
  handleCreativeDelete,
  setGeneratorVisible,
  handleCreativeClone,
  setSmartlinkVisible,
  setContextualVisible
}) => {
  const editColumn = isCampaignEdit
    ? [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          render: (id) => (id ? id : '-')
        }
      ]
    : [];
  const parsedStatus = (status) => {
    return status === 'inactive' ? 'pending' : status;
  };

  const urlColumn = {
    title: 'Landing URL',
    dataIndex: 'landing_url',
    render: (data) => (data ? data : '-')
  };

  const columns = [
    {
      title: '#',
      dataIndex: '',
      align: 'left',
      render: (_, __, id) => {
        return <span>{id + 1}</span>;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'left',
      render: (text = 'inactive') => {
        return <span className={`${styles.status} ${styles[`status${parsedStatus(text)}`]}`}>{parsedStatus(text)}</span>;
      }
    },
    ...editColumn,
    urlColumn,
    {
      title: 'Impressions',
      dataIndex: 'statistics',
      align: 'left',
      render: (stats) => (stats && stats.impressions ? stats.impressions : '-')
    },
    {
      title: 'Clicks',
      dataIndex: 'statistics',
      align: 'left',
      render: (stats) => (stats && stats.clicks ? stats.clicks : '-')
    },
    {
      title: 'Conversions',
      dataIndex: 'statistics',
      align: 'left',
      render: (stats) => (stats && stats.leads ? stats.leads : '-')
    },
    {
      title: 'Actions',
      key: 'id',
      dataIndex: 'id',
      fixed: 'right',
      align: 'left',
      render: (id, creative) => {
        return (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                if (type === 'Smartlink') {
                  setSmartlinkVisible(creative);
                } else if (type === 'Contextual Ad') {
                  setContextualVisible(creative);
                } else {
                  setGeneratorVisible(creative);
                }
              }}
            />
            <Link to={`/reports?creative_id=${id}`} target="_blank">
              <Button icon={<BarChartOutlined />} disabled={creative.gen_id} />
            </Link>
            <Button
              disabled={creativesLeft < 1}
              icon={<CopyOutlined />}
              onClick={() => {
                const cpCreative = { ...creative };
                const gen_id = calculateId();

                if (type === 'Smartlink') {
                  setSmartlinkVisible({ ...parseCreativeToClone(cpCreative), gen_id, gen_type: 'clone' });
                } else if (type === 'Contextual Ad') {
                  setContextualVisible({ ...parseCreativeToClone(cpCreative), gen_id, gen_type: 'clone' });
                } else {
                  setGeneratorVisible({ ...parseCreativeToClone(cpCreative), gen_id, gen_type: 'clone' });
                }
              }}
            />
            <Popconfirm
              placement="topRight"
              title="Creative will be deleted, are you sure you want to proceed?"
              onConfirm={() => {
                if (id) {
                  handleCreativeDelete(id, 'delete');
                } else {
                  handleCreativeDelete(creative.gen_id, 'delete_new');
                }
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        );
      }
    }
  ];

  const titleColumn = {
    title: 'Ad Headline',
    dataIndex: 'title',
    align: 'left',
    render: (data) => (data && data.en ? data.en : '-')
  };

  const descriptionColumn = {
    title: type.toLowerCase() === 'push' ? 'Ad Description' : 'Description',
    dataIndex: 'description',
    align: 'left',
    render: (description) => (description && description.en ? description.en : '-')
  };

  const imageColumn = {
    title: 'Image',
    dataIndex: 'image',
    render: (text) => {
      return <img style={{ objectFit: 'cover' }} width="89px" height="50px" src={text && text.en} alt="image1" />;
    }
  };
  const iconColumn = {
    title: 'Icon',
    dataIndex: 'icon',
    render: (text) => {
      return <img width="50px" height="50px" src={text} alt="icon" />;
    }
  };

  if (type.toLowerCase() !== 'smartlink') {
    columns.splice(3, 0, titleColumn);
  }

  if (type.toLowerCase() === 'push') {
    columns.splice(4, 0, descriptionColumn);
    columns.splice(5, 0, imageColumn);
    columns.splice(6, 0, iconColumn);
  }

  if (type.toLowerCase() === 'native') {
    columns.splice(4, 0, imageColumn);
  }

  const filterDeletedRows = (creatives) => creatives.filter((item) => item.action !== 'delete');

  return (
    <Table
      rowKey={({ gen_id, id }) => id || gen_id}
      className={styles.creativesTable}
      dataSource={filterDeletedRows(creatives)}
      columns={columns}
      scroll={{ x: 'max-content' }}
      pagination={false}
    />
  );
};
