import React, { useEffect, useRef, useState, useContext } from 'react';
import { List, Radio, Button, Modal } from 'antd';

import NotificationContext from '../../contexts/notificationContext';

import moment from 'moment';
import ReactMarkdown from 'react-markdown';

import { userNotificationsService } from '../../services/userNotifications';

export const BarNotificationItem = ({ item, userNotifications, setUserNotifications, dropdownVisible }) => {
  const { unreadCount, setUnreadCount } = useContext(NotificationContext);

  const descriptionRef = useRef();
  const [expandable, setExpandable] = useState(false);
  const [wasModalOpen, setWasModalOpen] = useState(false);

  useEffect(() => {
    if (descriptionRef.current.clientHeight > 67) {
      setExpandable(true);
    }
  }, [dropdownVisible]);

  const LinkRenderer = (props) => {
    return (
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
      </a>
    );
  };

  const readNotification = async (notification_id) => {
    setUnreadCount(unreadCount - 1);
    try {
      let readedNotifications = [...userNotifications];
      readedNotifications = readedNotifications.map((notification) =>
        notification.id === notification_id ? { ...notification, read: true } : notification
      );
      setUserNotifications(readedNotifications);
      await userNotificationsService.readUserNotification(notification_id);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const unreadNotification = async (notification_id) => {
    setUnreadCount(unreadCount + 1);
    try {
      let readedNotifications = [...userNotifications];
      readedNotifications = readedNotifications.map((notification) =>
        notification.id === notification_id ? { ...notification, read: false } : notification
      );
      setUserNotifications(readedNotifications);
      await userNotificationsService.unreadUserNotification(notification_id);
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleSeeMore = (e) => {
    e.stopPropagation();
    setWasModalOpen((wasModalOpen) => !wasModalOpen);
  };

  const handleReadRadio = (e, id) => {
    e.stopPropagation();
    readNotification(id);
  };

  return (
    <>
      <List.Item
        className={!item.read ? 'unReadlistItem' : ''}
        onClick={() => {
          !item.read && readNotification(item.id);
        }}
      >
        <div style={{ width: '500px' }}>
          <div className="notificationTitle">
            <div>
              {item.icon && <img className="notificationIcon" src={'https://ssp-beta.webbcrew.com' + item.icon} alt="" />}
              <h3>{item.title}</h3>
            </div>
            <Radio
              checked={!item.read}
              onClick={(e) => {
                !item.read ? handleReadRadio(e, item.id) : unreadNotification(item.id);
              }}
            />
          </div>
          <div ref={descriptionRef} style={!expandable ? { maxHeight: 'none' } : { maxHeight: 67 }} className="notificationDescription">
            <ReactMarkdown className="barNotification" components={{ a: LinkRenderer }}>
              {item.description}
            </ReactMarkdown>
            {expandable ? (
              <div style={{ bottom: '1px', right: '0px', position: 'absolute', background: 'white' }}>
                <p className={item.read ? 'notificationExpandLinkWrapperRead' : 'notificationExpandLinkWrapperUnread'}>
                  ...{' '}
                  <span className="notificationExpandLink" onClick={handleSeeMore}>
                    See more
                  </span>
                </p>
              </div>
            ) : null}
          </div>
          <div className="notificationDate">{moment(item.date_created).format('D MMM YYYY, HH:MMA')}</div>
        </div>
      </List.Item>
      <Modal
        className="barNotificationItemModal"
        title={
          <div className="barNotificationItemModalTitle">
            {item.icon !== '' ? (
              <img className="barNotificationItemModalIcon" src={'https://ssp-beta.webbcrew.com' + item.icon} alt="" />
            ) : (
              ''
            )}
            <h3>{item.title}</h3>
          </div>
        }
        closable={false}
        visible={wasModalOpen}
        onOk={false}
        onCancel={() => setWasModalOpen((wasModalOpen) => !wasModalOpen)}
        zIndex={1200}
        footer={
          <Button style={{ width: '5.875rem' }} key={item.id} onClick={() => setWasModalOpen((wasModalOpen) => !wasModalOpen)}>
            OK
          </Button>
        }
      >
        <ReactMarkdown className="barNotification" components={{ a: LinkRenderer }}>
          {item.description}
        </ReactMarkdown>
      </Modal>
    </>
  );
};
