import './layout.css';

import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import Media from 'react-media';

import { Header } from './components/Header';
import { Sidebar } from './components/Sidebar';
import { BroadcastNotification } from './components/BroadcastNotification';
import { SwitchRoutes } from '../routes/SwitchRoutes';
import { getAllowedRoutes } from '../routes';
import { useLocation, useHistory } from 'react-router-dom';
import { isHeaderAndSidebarHidden } from './utils/hiddenSidebar';
import { UserContextProvider } from '../contexts/userContext';
import { CampaignContextProvider } from '../contexts/campaignContext';
import { TargetingContextProvider } from '../contexts/targetingContext';
import { NotificationContextProvider } from '../contexts/notificationContext';
import { AgreementModal } from './components/AgreementModal';

export const AppLayout = () => {
  const location = useLocation();
  const history = useHistory();
  const isSidebarVisible = !isHeaderAndSidebarHidden(location);
  useEffect(() => {
    if (location.pathname === '/') {
      history.push('/campaigns');
    }
  }, []);

  const wasCollapsed = localStorage.getItem('collapse');
  const [collapsed, setCollapsed] = useState(wasCollapsed ? wasCollapsed === 'true' : window.innerWidth < 1400);
  const [agreementModalVisible, setAgreementModalVisible] = useState(false);

  return (
    <Layout style={{ minHeight: '100vh', maxHeight: '100vh', zIndex: '2' }}>
      {isSidebarVisible && (
        <Media queries={{ small: { minWidth: 599 } }}>
          {(matches) => (
            <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} isSmallScreen={!matches.small} selectedKey={location.pathname} />
          )}
        </Media>
      )}
      <Layout>
        <TargetingContextProvider>
          <CampaignContextProvider>
            <UserContextProvider>
              <NotificationContextProvider>
                {isSidebarVisible && (
                  <Media queries={{ small: { minWidth: 599 } }}>
                    {(matches) => <Header collapsed={collapsed} setCollapsed={setCollapsed} isSmallScreen={!matches.small}
                      setAgreementModalVisible={setAgreementModalVisible}
                    />}
                  </Media>
                )}
                <BroadcastNotification />
                <Layout.Content style={{ margin: isSidebarVisible ? '20px' : 0 }}>
                  <div style={{ padding: isSidebarVisible ? 24 : 0, background: '#fff', minHeight: '87vh' }}>
                    <SwitchRoutes routes={getAllowedRoutes()} />
                  </div>
                </Layout.Content>
              </NotificationContextProvider>
              <AgreementModal
                agreementModalVisible={agreementModalVisible}
                setAgreementModalVisible={setAgreementModalVisible}
              />
            </UserContextProvider>
          </CampaignContextProvider>
        </TargetingContextProvider>
      </Layout>
    </Layout>
  );
};
