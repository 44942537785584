import { Checkbox } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import React from 'react';
import { useState } from 'react';
import styles from './Targeting.module.css';
import { useEffect } from 'react';

export const TargetingGroup = ({ category_name, subcategories, selectedValues, setSelectedCategories }) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const onChange = (list) => {
    // setSelectedValues((oldValues) => {
    //   return { ...oldValues, [category_name]: list };
    // });
    setSelectedCategories(list, category_name);
    setIndeterminate(!!list.length && list.length < subcategories.length);
    setCheckAll(list.length === subcategories.length);
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setSelectedCategories(
        subcategories.map((item) => item.value),
        category_name
      );
    } else {
      setSelectedCategories([], category_name);
    }
    // setSelectedValues((oldValues) => {
    //   return { ...oldValues, [category_name]: e.target.checked ? subcategories.map((item) => item.value) : [] };
    // });
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    if (selectedValues[category_name]) {
      if (subcategories.length === selectedValues[category_name].length) {
        setCheckAll(true);
      } else if (selectedValues[category_name].length > 0) {
        setIndeterminate(true);
      }
    }
  }, []);

  return (
    <div className={styles.categoryContainer}>
      <div className={styles.categoryHead} onClick={() => setExpanded(!expanded)}>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          {category_name}
        </Checkbox>
        {expanded ? <UpOutlined style={{ fontSize: '0.7rem' }} /> : <DownOutlined style={{ fontSize: '0.7rem' }} />}
      </div>
      <div className={styles.subcategoriesBox} style={!expanded ? { height: 0, opacity: 0, pointerEvents: 'none' } : {}}>
        <Checkbox.Group options={subcategories} value={selectedValues[category_name]} onChange={onChange} />
      </div>
    </div>
  );
};
