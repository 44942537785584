import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../Reports.module.css';
import { CampaignTypeCol } from '../components/CampaignTypeCol';

export const startColumns = [
  {
    value: 'campaign_id',
    text: 'Campaign ID',
    tooltip: 'Unique ID of your campaign',
    render: (campaign_id) => {
      return (
        <Link to={`/campaigns/${campaign_id}`} target="_blank">
          <p className={styles.campaignId}>{campaign_id}</p>
        </Link>
      );
    }
  },
  {
    value: 'campaign_name',
    text: 'Campaign',
    tooltip: 'Your campaign name',
    render: (campaign_name, campaign) => {
      if (campaign.campaign_id) {
        return (
          <Link to={{ pathname: `/campaigns/${campaign.campaign_id}`, state: { campaign_id: true } }} target="_blank">
            <p className={styles.campaignName}>{campaign_name}</p>
          </Link>
        );
      } else {
        return <p className={styles.campaignName}>{campaign_name}</p>;
      }
    }
  },
  {
    value: 'campaign_type',
    text: 'Type',
    tooltip: 'Ad format (Push, Contextual ad)',
    className: styles.campaignTypeColumn,
    render: (campaignType) => {
      return <CampaignTypeCol campaignType={campaignType} />;
    }
  }
];

export const endColumns = [
  {
    value: 'ctr',
    text: 'CTR',
    tooltip: 'Clickthrough rate - the number of clicks your ad receives divided by the number of times your ad is shown'
  },
  { value: 'impressions', text: 'Impressions', tooltip: 'Number that indicates how many times your ad is shown to user' },
  { value: 'clicks', text: 'Clicks', tooltip: 'Number that shows how many times a user clicked on your ad' },
  {
    value: 'spam_clicks',
    text: 'Spam Clicks',
    tooltip: "Number of clicks that happen when a fraudster executes clicks for users who haven't made them"
  },
  { value: 'cpa', text: 'CPA', tooltip: 'CPA represent your Cost Per Action, which is a ratio between your expenses and conversions.' },
  { value: 'leads', text: 'Conversions', tooltip: 'Number of actions you define as valuable to your business' },
  { value: 'spent', text: 'Spent', tooltip: 'Cost Per Click multiplied with number of clicks' },
  { value: 'source', text: 'SubID', tooltip: 'Indicator of your traffic source' },
  { value: 'country', text: 'Country', tooltip: 'Country where your ad is shown, from your GEO targeting' },
  { value: 'creative_id', text: 'Creative ID', tooltip: 'ID of your creative a user interacted with' },
  { value: 'creative_name', text: 'Creative', tooltip: 'Name of your creative a user interacted with' },
  { value: 'cr', text: 'CR', tooltip: 'Conversion Rate - Ratio between your conversions and your clicks (in CPC model)' },
  { value: 'expected_revenue', text: 'Expected Revenue', tooltip: 'Amount you set when conversion happens' },
  { value: 'browser', text: 'Browser', tooltip: 'Browser that displayed your ad, from your browser targeting' },
  { value: 'hour', text: 'Hour', tooltip: 'Hour segment in date frame period of observing your activity' },
  { value: 'date', text: 'Date', tooltip: 'Date frame period of observing your activity' },
  { value: 'os_version', text: 'OS Version', tooltip: 'Specific version of operating system your ad was displayed onto' },
  { value: 'browser_version', text: 'Browser Version', tooltip: 'Specific version of browser that displayed your ad' },
  { value: 'os', text: 'OS', tooltip: 'Operating System your ad was displayed onto' },
  { value: 'region_name', text: 'Region', tooltip: 'Region where your ad is shown, from your GEO targeting' }
  // { value: 'revenue', text: 'Revenue' },
];

export const campaignColumnPreset = {
  // revenue: true,
  campaign_id: true,
  campaign_name: true,
  ctr: true,
  base_price: true,
  clicks: true,
  campaign_type: true,
  impressions: true,
  leads: true,
  spent: true
};

export const dateColumnPreset = {
  // revenue: true,
  date: true,
  // campaign_name: true,
  ctr: true,
  clicks: true,
  impressions: true,
  leads: true,
  spent: true
};

export const countryColumnPreset = {
  // revenue: true,
  country: true,
  // campaign_name: true,
  ctr: true,
  clicks: true,
  impressions: true,
  leads: true,
  spent: true
};
