import React, { useEffect, useReducer, useState } from 'react';
import { mediaCampaignsService } from '../services/campaigns';
const CampaignContext = React.createContext({});
export default CampaignContext;

const initialTargetingValues = {
  country: { item: [], list: [], op: 'include' },
  region: { item: [] },
  os: [],
  browser: [],
  age: { item: ['1'], op: 'include' },
  subscribed_age: { time_unit: 'hour', from: 0, to: 3 },
  freshness_type: 'day',
  day: { item: [], op: 'include' },
  hour: { item: [], op: 'include' },
  ip: { item: [], list: [], op: 'include' },
  connection: { item: [], op: 'include' },
  device_type: { item: [], op: 'include' },
  device_manufacturer: { item: [], op: 'include' },
  category: { item: [], op: 'include' },
  subid: { item: [], list: [], op: 'include' }
};
const initialCampaignValues = {
  general: {},
  limit: {},
  pricing: {}
};

const targetingReducer = (state, action) => {
  switch (action.type) {
    case 'os':
    case 'browser':
    case 'region':
    case 'freshness_type':
      return { ...state, [action.type]: action.payload };
    case 'category':
      return {
        ...state,
        [action.type]: { ...state[action.type], item: { ...state[action.type].item, [action.category]: [...action.payload.item] } }
      };
    case 'category_op':
      return { ...state, category: { ...state.category, ...action.payload } };
    case 'country':
    case 'day':
    case 'hour':
    case 'ip':
    case 'age':
    case 'connection':
    case 'device_type':
    case 'device_manufacturer':
    case 'subid':
    case 'subscribed_age':
      return { ...state, [action.type]: { ...state[action.type], ...action.payload } };
    case 'edit_targeting':
      return { ...state, ...action.payload };
    case 'reset':
      return initialTargetingValues;
    default:
      return state;
  }
};
const campaignReducer = (state, action) => {
  switch (action.type) {
    case 'general':
    case 'creatives':
    case 'limit':
    case 'pricing':
      return { ...state, [action.type]: action.payload };
    case 'edit_campaign':
      return { ...state, ...action.payload };
    case 'reset':
      return initialCampaignValues;
    default:
      return state;
  }
};

const creativesReducer = (state, action) => {
  switch (action.type) {
    case 'create':
      return [...state, { ...action.payload }];
    case 'update':
      const typeOfEdit = action.payload.id ? { action: 'update' } : {};
      const editId = state.findIndex((item) => item.id === action.payload.id);
      const parsedEdit = [...state];
      parsedEdit[editId] = { ...action.payload, ...typeOfEdit };
      return parsedEdit;
    case 'update_new':
      const editNewId = state.findIndex((item) => item.gen_id === action.payload.gen_id);
      const parsedNewEdit = [...state];
      parsedNewEdit[editNewId] = action.payload;
      return parsedNewEdit;
    case 'delete':
      const deleteId = state.findIndex((item) => item.id === action.payload);
      const parsed = [...state];
      parsed[deleteId] = { ...parsed[deleteId], action: 'delete' };
      return parsed;
    case 'delete_new':
      return state.filter((item) => item.gen_id !== action.payload);
    case 'clone':
      const cloneId = state.findIndex((item) => item.id === action.payload.id);
      const clone = parseCreativeToClone({ ...state[cloneId], stamp: action.payload.stamp });
      const parsedClone = [...state, clone];
      return parsedClone;
    case 'clone_new':
      const cloneNewId = state.findIndex((item) => item.gen_id === action.payload.id);
      const cloneNew = parseCreativeToClone({ ...state[cloneNewId], stamp: action.payload.stamp });
      const parsedNewClone = [...state, cloneNew];
      return parsedNewClone;
    case 'undo_delete':
      const undoId = state.findIndex((item) => item.id === action.payload);
      const undoParsed = [...state];
      delete undoParsed[undoId].action;
      return undoParsed;
    case 'bulk_create':
      return [...state, ...action.payload];
    case 'load':
      return action.payload;
    case 'load_push':
      return action.payload.map((item) => {
        if (item.body) {
          item.description = { en: item.body.en };
          delete item.body;
        }
        return item;
      });
    case 'reset':
      return [];
    default:
      return state;
  }
};

const editSetTargeting = (targeting, dispatch) => {
  const payload = Object.keys(targeting.targeting)
    .filter((key) => Object.keys(initialTargetingValues).includes(key) && targeting.targeting[key] !== null)
    .reduce((obj, key) => {
      switch (key) {
        case 'country':
        case 'id':
        case 'subid':
          // convert null values into array
          for (const innerKey of Object.keys(targeting.targeting[key])) {
            if (targeting.targeting[key][innerKey] === null) {
              targeting.targeting[key][innerKey] = [];
            }
          }
          obj[key] = targeting.targeting[key];
          break;
        default:
          obj[key] = targeting.targeting[key];
      }

      return obj;
    }, {});
  dispatch({ type: 'edit_targeting', payload });
};
const editSetGeneral = (campaign, dispatch) => {
  const payload = Object.keys(campaign)
    .filter((key) => Object.keys(initialCampaignValues).includes(key) && campaign[key] !== null)
    .reduce((obj, key) => {
      obj[key] = campaign[key];
      return obj;
    }, {});
  dispatch({ type: 'edit_campaign', payload });
};

export const parseCreativeToClone = ({ description, image, title, landing_url, icon, stamp }) => {
  const descObj = description ? { description } : {};
  const clone = {
    ...descObj,
    image,
    title: title ? { en: title.en + '(clone)' } : null,
    landing_url,
    gen_id: stamp,
    icon
  };
  return clone;
};

export const CampaignContextProvider = ({ children }) => {
  const [editId, setEditId] = useState();
  const [editLoading, setEditLoading] = useState(false);
  const [existingPricing, setExistingPricing] = useState(false);
  const [campaign, dispatchCampaign] = useReducer(campaignReducer, initialCampaignValues);
  const [targeting, dispatchTargeting] = useReducer(targetingReducer, initialTargetingValues);
  const [creatives, dispatchCreatives] = useReducer(creativesReducer, []);

  const fetchEditCampagin = async (id) => {
    setEditLoading(true);
    try {
      const res = await mediaCampaignsService.getCampaignsInfo(id);
      if (res.data.pricing) {
        setExistingPricing(res.data.pricing.price);
      }
      if (res.data.targeting && res.data.targeting.targeting && res.data.targeting.targeting.subscribed_age) {
        dispatchTargeting({ type: 'freshness_type', payload: 'hour' });
      } else {
        dispatchTargeting({ type: 'freshness_type', payload: 'day' });
      }
      editSetTargeting(res.data.targeting, dispatchTargeting);
      editSetGeneral(res.data, dispatchCampaign);
      if (res.data.creatives) {
        if (res.data.general.type === 'Push') {
          dispatchCreatives({ type: 'load_push', payload: res.data.creatives });
        } else {
          dispatchCreatives({ type: 'load', payload: res.data.creatives });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setEditLoading(false);
    }
  };

  useEffect(() => {
    if (editId) {
      fetchEditCampagin(editId);
    }
  }, [editId]);

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        existingPricing,
        dispatchCampaign,
        targeting,
        dispatchTargeting,
        editLoading,
        setEditId,
        creatives,
        dispatchCreatives
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
