import React, { useContext } from 'react';
import styles from './CreativePreview.module.css';
import { EyeInvisibleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import CampaignContext from '../../../../../../../../contexts/campaignContext';
import { isNotValid } from '../../../../utils';
import { openWarningNotification } from '../../../../../../../../utils/notifications';
import { VariationPreview } from './VariationPreview';

export const CreativePreview = ({
  type,
  genCreatives,
  dispatchGenCreatives,
  currentCreative,
  limitExceeded,
  setGeneratorVisible,
  isEdit
}) => {
  const { dispatchCreatives } = useContext(CampaignContext);
  const previewCreative = currentCreative ? genCreatives.filter((item) => item.gen_id === currentCreative)[0] : null;
  const handleCreativesCreate = (payload) => {
    let incompletedCreativesCount = 0;
    payload.forEach((item) => {
      if (isNotValid(item, type).notValid) {
        incompletedCreativesCount += 1;
      }
    });
    if (incompletedCreativesCount === 0) {
      dispatchCreatives({ type: 'bulk_create', payload });
      setGeneratorVisible(false);
      dispatchGenCreatives({ type: 'reset' });
    } else {
      if (payload.length === incompletedCreativesCount) {
        openWarningNotification({ message: `You have ${incompletedCreativesCount} invalid creatives, please fix issues!` });
      } else {
        Modal.confirm({
          title: 'Invalid Creatives',
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            const validCreatives = payload.filter((item) => !isNotValid(item, type).notValid);
            dispatchCreatives({ type: 'bulk_create', payload: validCreatives });
            setGeneratorVisible(false);
            dispatchGenCreatives({ type: 'reset' });
          },
          content: `You have ${
            payload.length - incompletedCreativesCount
          } valid creatives, do you want to save valid creatives and remove rest (${incompletedCreativesCount}) of them?`,
          okText: 'Yes',
          cancelText: 'No'
        });
      }
    }
  };

  return (
    <div className={styles.creativePreview}>
      <div className={styles.creativePreviewMain}>
        <div className={styles.headerTitle}>
          <p>Preview</p>
        </div>
        {previewCreative ? (
          <div className={styles.previewContainer}>
            <VariationPreview
              type={type}
              title={previewCreative.title ? previewCreative.title.en : ''}
              message={previewCreative.description ? previewCreative.description.en : ''}
              icon={previewCreative.icon ? previewCreative.icon : ''}
              image={previewCreative.image ? previewCreative.image.en : ''}
            />
          </div>
        ) : (
          <div className={styles.noSelection}>
            <EyeInvisibleOutlined style={{ fontSize: '3rem', color: '#CCCCCC' }} />
            <p>Please select specific creative from Creative Variations</p>
          </div>
        )}
      </div>
      {!isEdit && (
        <Button
          onClick={() => handleCreativesCreate(genCreatives)}
          disabled={limitExceeded}
          type="primary"
          size="large"
          className={styles.creativesSaveBtn}
        >
          Save
        </Button>
      )}
    </div>
  );
};
