import { callApi } from './api';
// import { getQueryString } from '../utils/query';

class TargetingService {
  async getAllCountries() {
    return await callApi({ url: '/api/countries' });
  }

  async getAllRegions() {
    return await callApi({ url: '/api/regions' });
  }

  async getAllBrowsers() {
    return await callApi({ url: '/api/browsers' });
  }

  async getAllOs() {
    return await callApi({ url: '/api/os' });
  }

  async getAllConnections() {
    return await callApi({ url: '/api/connections' });
  }

  async getAllDeviceManufacturers() {
    return await callApi({ url: '/api/device_manufacturers' });
  }

  async getAllDeviceTypes() {
    return await callApi({ url: '/api/device_types' });
  }

  async getAllCampaignVerticals() {
    return await callApi({ url: '/api/campaign-vertical' });
  }

  async getAllTrafficCategories() {
    return await callApi({ url: '/api/traffic_categories' });
  }
}

export const targetingService = new TargetingService();
