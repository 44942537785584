import React, { useContext, useEffect, useState } from 'react';
import { Row, Form, Radio, Col, Input, Button, Tag, Space, Slider } from 'antd';
import { GlobalOutlined, SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';

import { openWarningNotification } from '../../../../../../utils/notifications';

import styles from './Ages.module.css';
import TargetingContext from '../../../../../../contexts/targetingContext';
import CampaignContext from '../../../../../../contexts/campaignContext';
import { TargetingSelect } from '../../../../components/TargetingSelect';
import { TargetingPreview } from '../../../../components/TargetingPreview';
import { hourMarks } from '../../../../utils';

export const AgesTab = ({ changeActiveTab }) => {
  // const [shownCountries, setShownCountries] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [form] = Form.useForm();
  const { agesAll } = useContext(TargetingContext);

  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  // useEffect(() => {
  //   dispatchTargeting({ type: 'age', payload: { item } });
  // }, [])

  const setSelectedCountries = (item) => {
    dispatchTargeting({ type: 'age', payload: { item } });
  };
  // const onStatusChange = ({ target }) => {
  //   dispatchTargeting({ type: 'age', payload: { op: target.value } });
  // };

  // useEffect(() => {
  //   if (!targeting.age.item.length) {
  //     setSelectedAll(true);
  //   }
  //   setShownCountries(agesAll);
  // }, []);

  const onCheckAllChange = (e) => {
    setSelectedAll(e.target.checked);
    dispatchTargeting({ type: 'age', payload: { item: [], op: 'include' } });
  };

  const handleHourUpdate = (values) => {
    if (values[0] - values[1] === 0) return;
    dispatchTargeting({ type: 'subscribed_age', payload: { from: values[0], to: values[1] } });
  };

  const handleFreshnessTypeChange = (e) => {
    if (e.target.value === 'day') {
      dispatchTargeting({ type: 'subscribed_age', payload: null });
      dispatchTargeting({ type: 'age', payload: [1] });
    } else {
      dispatchTargeting({ type: 'subscribed_age', payload: { from: 0, to: 3 } });
      dispatchTargeting({ type: 'age', payload: null });
    }
    dispatchTargeting({ type: 'freshness_type', payload: e.target.value });
  };

  // const formatedFromValue = ('0' + targeting.subscribed_age.from).slice(-2) + ':00';
  // const formatedToValue = ('0' + targeting.subscribed_age.to).slice(-2) + ':00';

  return (
    <div>
      <div className={styles.headTabInfo}>
        <div>
          <GlobalOutlined />
          <p>Target User Freshness</p>
        </div>
        <p>Targets users by freshness from the moment of subscription</p>
        <Radio.Group onChange={handleFreshnessTypeChange} value={targeting.freshness_type}>
          <Space direction="vertical">
            <Radio value="hour">
              Target users by hours <span className={styles.radioSuperFresh}>Super Fresh</span>
            </Radio>
            <Radio value="day">Target users by days</Radio>
          </Space>
        </Radio.Group>
      </div>
      <Row>
        <Form form={form} style={{ width: '100%' }}>
          <Row className={styles.targetingSelectContainer}>
            <Col className={styles.targetingSelectBox}>
              <Row className={styles.targetingSelectDiv}>
                <p>
                  User Freshness targeting is highly recommended to advertisers to engage in users’ activity. You can choose some of the
                  following options. We measure User Freshness in days or hours from the moment of their subscription.
                </p>
                <p>The segmentation helps advertisers know what kind of audience engages more with their offers/services.</p>
              </Row>
              {targeting.freshness_type === 'day' ? (
                <TargetingSelect
                  simpleItems={agesAll}
                  checkAll={selectedAll}
                  selectedItems={targeting.age.item}
                  handleItemSelect={setSelectedCountries}
                  handleCheckAll={onCheckAllChange}
                  setSelectedAll={setSelectedAll}
                  selectedAll={selectedAll}
                  itemName="Ages"
                  isLoading={false}
                  hasVersions={false}
                  showVersions={false}
                  height={243}
                />
              ) : (
                <div className={styles.targetingBoxHour}>
                  <Slider
                    min={0}
                    max={24}
                    value={[targeting.subscribed_age.from, targeting.subscribed_age.to]}
                    onChange={handleHourUpdate}
                    range
                    step={null}
                    marks={hourMarks}
                  />
                  <div className={styles.targetingBoxHourText}>
                    <span>{targeting.subscribed_age.from}</span>
                    <span>{targeting.subscribed_age.to}</span>
                  </div>
                </div>
              )}
            </Col>
            <Col className={styles.targetingPreviewBox}>
              <Row className={styles.targetingPreviewDiv} style={{ padding: '10px' }}>
                <h3 style={{ width: '100%', color: '#58a54f' }}>Included</h3>
                {targeting.age.item && targeting.age.item.filter((item) => item !== '238').length === 0 ? (
                  <Row className={styles.countBox}>All users</Row>
                ) : (
                  <Row className={styles.countBox}>
                    <span>
                      User Freshness{' '}
                      {targeting.freshness_type === 'day' && (
                        <span className={styles.countDisplay}>
                          {targeting.age.item ? targeting.age.item.filter((item) => item !== '238').length : 0}
                        </span>
                      )}
                    </span>
                  </Row>
                )}
              </Row>
              {targeting.freshness_type === 'day' ? (
                <TargetingPreview
                  allItems={agesAll}
                  allItemsSelected={selectedAll}
                  selectedItems={targeting.age.item.filter((item) => item !== '238')}
                  handleItemSelect={setSelectedCountries}
                  hasVersions={false}
                  itemName="Users"
                  height={243}
                />
              ) : (
                <div className={styles.targetingBoxHourPreview}>
                  <div className={styles.selectionTag}>
                    {targeting.subscribed_age.from} - {targeting.subscribed_age.to} hour
                  </div>

                  <p>All other user ages have been excluded from targeting.</p>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('days')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
