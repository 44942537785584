import { Button, Radio, DatePicker, Row, Col, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './Filters.module.css';
import {
  FilterFilled,
  ExportOutlined,
  ReloadOutlined,
  BorderInnerOutlined,
  BarChartOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import { ColumnsGroup } from './ColumnsGroup';
import { VirtualizedSelect } from '../../../components/VirtualizedSelect';
import { reportsService } from '../../../services/reports';
import {
  generateBrowsers,
  generateCampagins,
  generateCampaignTypes,
  generateCountries,
  generateOperatingSystems
} from '../utils/filterOptions';
import moment from 'moment';
import { mediaCampaignsService } from '../../../services/campaigns';
import { isNumber } from 'highcharts';
import { campaignColumnPreset, dateColumnPreset, countryColumnPreset } from '../utils/columnsList';
import { ColumnInfo } from './ColumnInfo';
import { useContext } from 'react';
import UserContext from '../../../contexts/userContext';
const { RangePicker } = DatePicker;
const { Option } = Select;

export const Filters = ({
  onSubmit,
  allColumns,
  dateRange,
  setDateRange,
  setChartStats,
  chartStats,
  countries,
  setCountries,
  handleExport,
  campaignId,
  creativeId
}) => {
  const { userInfo } = useContext(UserContext);
  const campaignTypes = [
    { label: 'Push', value: 'Push' },
    { label: 'Contextual Ad', value: 'Contextual Ad' }
  ];
  const [displayAdvanced, setDisplayAdvanced] = useState(true);
  const [checkedColumns, setCheckedColumns] = useState(campaignColumnPreset);
  const [os, setOS] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [browsers, setBrowsers] = useState([]);
  const [filters, setFilters] = useState({});

  const [columnInfoVisible, setColumnInfoVisible] = useState(false);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await reportsService.getCountries();
        if (data) {
          setCountries(data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getOS = async () => {
      try {
        const { data } = await reportsService.getOperatingSystems();
        if (data) {
          setOS(data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getCampaigns = async () => {
      try {
        const { data } = await mediaCampaignsService.getAllCampaignsAutocomplete();
        if (data) {
          setCampaigns(data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    const getBrowsers = async () => {
      try {
        const { data } = await reportsService.getBrowsers();
        if (data) {
          setBrowsers(data);
        }
      } catch {
        // TODO: handle error?
      } finally {
      }
    };
    getCountries();
    getOS();
    getBrowsers();
    getCampaigns();
  }, []);

  useEffect(() => {
    if (campaigns.length > 0) {
      if (campaignId && isNumber(campaignId)) {
        let id = campaignId.toString();
        let selectedCampaign = campaigns.filter((el) => el.id === id).map((el) => el.id);
        setFilters({ ...filters, campaigns: selectedCampaign });
        // handleUpdate();
      } else if (creativeId && isNumber(creativeId)) {
        let id = creativeId.toString();
        setFilters({ ...filters, creative_id: id });
        handleUpdate({ ...filters, creative_id: id });
      }
    }
  }, [campaigns]);

  const handleUpdate = (urlFilters, urlCheckedColumns) => {
    onSubmit({
      filters,
      dateRange,
      checkedColumns
    });
  };

  return (
    <>
      <div className={styles.topActionBtns}>
        <Button className={styles.topActionButton} onClick={() => setDisplayAdvanced(!displayAdvanced)}>
          <FilterFilled /> Filters
        </Button>
        <ColumnsGroup
          columnsData={allColumns}
          onChange={(value) => {
            setCheckedColumns(value);
          }}
          showUpdateButton={true}
          onUpdate={handleUpdate}
          checkedColumns={checkedColumns}
        />
        <Button
          onClick={() => {
            setColumnInfoVisible(true);
          }}
        >
          Columns info
        </Button>
      </div>
      <div className={styles.filtersContainer}>
        <Radio.Group className={styles.dateRadioSelect} defaultValue="today" size="large">
          <Radio.Button value="today" onClick={() => setDateRange({ from: moment(), to: moment() })}>
            Today
          </Radio.Button>
          <Radio.Button
            value="yesterday"
            onClick={() => setDateRange({ from: moment().subtract(1, 'days'), to: moment().subtract(1, 'days') })}
          >
            Yesterday
          </Radio.Button>
          <Radio.Button value="7days" onClick={() => setDateRange({ from: moment().subtract(6, 'days'), to: moment() })}>
            Last 7 Days
          </Radio.Button>
          <Radio.Button value="30days" onClick={() => setDateRange({ from: moment().subtract(30, 'days'), to: moment() })}>
            Last 30 Days
          </Radio.Button>
          <Radio.Button value="thisMonth" onClick={() => setDateRange({ from: moment().startOf('month'), to: moment().endOf('month') })}>
            This Month
          </Radio.Button>
          <Radio.Button
            value="lastMonth"
            onClick={() =>
              setDateRange({ from: moment().subtract(1, 'months').startOf('month'), to: moment().subtract(1, 'months').endOf('month') })
            }
          >
            Last Month
          </Radio.Button>
        </Radio.Group>
        <div className={styles.filtersContainerBottom}>
          <RangePicker
            allowClear={false}
            className={styles.rangeDatePicker}
            onChange={(momentDates, dates) => {
              setDateRange({ from: momentDates[0], to: momentDates[1] });
            }}
            value={[moment(dateRange.from), moment(dateRange.to)]}
          />
          {!displayAdvanced && (
            <Button className={styles.applyFiltersBtn} type="primary" size="medium" onClick={handleUpdate}>
              {chartStats ? 'Make Graph' : 'Apply Filters'}
            </Button>
          )}
        </div>
        {displayAdvanced && (
          <div style={{ marginTop: '1%' }} className={styles.advancedFilters}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Campaign</span>
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    setFilters({ ...filters, campaigns: value });
                  }}
                  filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  style={{ width: '100%' }}
                  placeholder="Select campaigns"
                  value={filters.campaigns}
                  allowClear
                >
                  {generateCampagins(campaigns || [])}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Country</span>
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    setFilters({ ...filters, countries: value });
                  }}
                  filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  style={{ width: '100%' }}
                  placeholder="Select country"
                  value={filters.countries}
                  allowClear
                >
                  {generateCountries(countries || [])}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Operating System</span>
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    setFilters({ ...filters, os: value });
                  }}
                  filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  style={{ width: '100%' }}
                  placeholder="Select OS"
                  value={filters.os}
                  allowClear
                >
                  {generateOperatingSystems(os || [])}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Campaign Type</span>
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    setFilters({ ...filters, campaign_types: value });
                  }}
                  filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  style={{ width: '100%' }}
                  placeholder="Select type"
                  value={filters.campaign_type}
                  allowClear
                >
                  {generateCampaignTypes(campaignTypes || [])}
                  {userInfo.permissions?.smartlink && <Option value="Smartlink">Smartlink</Option>}
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                <span className={styles.spanMarginBottom}>Browser</span>
                <Select
                  mode="multiple"
                  onChange={(value) => {
                    setFilters({ ...filters, browsers: value });
                  }}
                  filterOption={(inputValue, option) => option.children.toLowerCase().includes(inputValue.toLowerCase())}
                  style={{ width: '100%' }}
                  placeholder="Select browsers"
                  value={filters.browsers}
                  allowClear
                >
                  {generateBrowsers(browsers || [])}
                </Select>
              </Col>
              {!!creativeId && (
                <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                  <span className={styles.spanMarginBottom}>Creatives</span>
                  <VirtualizedSelect
                    options={[{ label: creativeId, value: creativeId }]}
                    onChange={(value) => {
                      setFilters({ ...filters, creative_id: value });
                    }}
                    multi={true}
                    selectValue={filters.creative_id}
                    placeholder={'Select creatives'}
                  />
                </Col>
              )}

              {displayAdvanced && (
                <Col xs={24} sm={12} md={8} lg={6} xxl={4}>
                  <Button
                    style={{ marginTop: '23px' }}
                    className={styles.applyFiltersBtn}
                    type="primary"
                    size="medium"
                    onClick={handleUpdate}
                  >
                    {chartStats ? 'Make Graph' : 'Apply Filters'}
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        )}
        {displayAdvanced ? (
          <Button type="link" className={styles.hideShowLinkBtn} onClick={() => setDisplayAdvanced(!displayAdvanced)}>
            <UpOutlined />
            Hide advanced filters
          </Button>
        ) : (
          <Button type="link" className={styles.hideShowLinkBtn} onClick={() => setDisplayAdvanced(!displayAdvanced)}>
            <DownOutlined />
            Show advanced filters
          </Button>
        )}
      </div>
      <div className={styles.bottomActions}>
        <Radio.Group defaultValue="Campaign" buttonStyle="solid">
          <Radio.Button onClick={() => setCheckedColumns(campaignColumnPreset)} value="Campaign">
            Campaign
          </Radio.Button>
          <Radio.Button onClick={() => setCheckedColumns(dateColumnPreset)} value="Date">
            Date
          </Radio.Button>
          <Radio.Button onClick={() => setCheckedColumns(countryColumnPreset)} value="Country">
            Country
          </Radio.Button>
        </Radio.Group>
        <div className={styles.rightActionBtns}>
          <Button
            type="link"
            onClick={() =>
              handleExport({
                filters,
                dateRange,
                checkedColumns: checkedColumns
              })
            }
          >
            <ExportOutlined /> Export to CSV
          </Button>
          <Button onClick={handleUpdate}>
            Reload <ReloadOutlined />
          </Button>
          <Radio.Group defaultValue="table">
            <Radio.Button value="table" onClick={() => setChartStats(false)}>
              <BorderInnerOutlined />
            </Radio.Button>
            <Radio.Button value="graph" onClick={() => setChartStats(true)}>
              <BarChartOutlined />
            </Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <ColumnInfo visible={columnInfoVisible} setVisible={setColumnInfoVisible} allColumns={allColumns} />
    </>
  );
};
