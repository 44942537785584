import { callApi } from './api';
import { getQueryString, getOffsetLimitFromPaginationLists } from '../utils/query';

export const ListTypes = {
  country_lists: 'country',
  domain_lists: 'domain',
  subid_lists: 'subid',
  ip_lists: 'ip'
};

class ListsService {
  async getListsPaged({ currentPage, pageSize, cancelToken, type }) {
    const query = getQueryString({ ...getOffsetLimitFromPaginationLists({ currentPage, pageSize }), type });
    return await callApi({ url: `/api/lists/${query}`, cancelToken });
  }
  async getAllLists({ type }) {
    const query = getQueryString({ type });
    return await callApi({ url: `/api/lists/${query}` });
  }

  async addList(data) {
    return await callApi({
      url: 'api/lists/new/',
      method: 'POST',
      data
    });
  }

  async editList(id, data) {
    return await callApi({
      url: `api/lists/${id}/edit/`,
      method: 'PATCH',
      data
    });
  }

  async deleteList(id) {
    return await callApi({
      url: `api/lists/${id}/delete/`,
      method: 'DELETE'
    });
  }
}

export const listsService = new ListsService();
