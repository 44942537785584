import React, { useContext, useEffect, useState } from 'react';
import { Row, Form, Col, Input, Checkbox, Button } from 'antd';
import { GlobalOutlined, SearchOutlined, DoubleRightOutlined } from '@ant-design/icons';

import styles from './Browsers.module.css';
import CampaignContext from '../../../../../../contexts/campaignContext';
import TargetingContext from '../../../../../../contexts/targetingContext';
import { TargetingSelect } from '../../../../components/TargetingSelect';
import { TargetingPreview } from '../../../../components/TargetingPreview';

export const BrowsersTab = ({ changeActiveTab, type }) => {
  const { browserAll } = useContext(TargetingContext);
  const { targeting, dispatchTargeting } = useContext(CampaignContext);

  const [shownBrowsers, setShownBrowsers] = useState([]);
  const [simpleBrowsersAll, setSimpleBrowsersAll] = useState([]);
  const [showVersions, setShowVersions] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAllBrowsers = async () => {
    const browsersArray = browserAll.map((el) => {
      return { label: el.name, value: el.id };
    });

    // if (targeting.browser.length < 1) {
    //   const selectedBrowsers = browserAll.map((os) => {
    //     return { id: os.id };
    //   });
    //   setSelectedAll(true);
    //   dispatchTargeting({ type: 'browser', payload: selectedBrowsers });
    // } else {
    //   setShowVersions(true);
    // }
    setSimpleBrowsersAll(browsersArray);
    setShownBrowsers(browsersArray);
    setIsLoading(false);
  };
  const onSearch = (e) => {
    let browsers = simpleBrowsersAll.filter((browser) => {
      return browser.label.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1;
    });
    browsers =
      browsers.length > 0
        ? browsers.concat(simpleBrowsersAll.filter((item) => !browsers.some((browser) => browser.label === item.label)))
        : [];
    setShownBrowsers(browsers);
  };

  useEffect(() => {
    getAllBrowsers();
  }, []);

  const setSelectedItems = (checked) => {
    dispatchTargeting({ type: 'browser', payload: checked });
    setSelectedAll(checked.length === browserAll.length);
  };

  const onCheckAllChange = (e) => {
    const selectedC = browserAll;
    dispatchTargeting({ type: 'browser', payload: e.target.checked ? selectedC : [] });
    setSelectedAll(e.target.checked);
    setShowVersions(false);
  };
  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <GlobalOutlined />
            <p>Target Browsers</p>
          </div>
          <p>Target Browsers or Browsers by versions</p>
        </div>
      </Row>
      <Row>
        <Form style={{ width: '100%' }}>
          <Row className={styles.targetingSelectContainer}>
            <Col className={styles.targetingSelectBox}>
              <Row className={styles.targetingSelectDivLeft}>
                <Form.Item className={styles.targetingSearchItem} name="search">
                  <Input onChange={onSearch} prefix={<SearchOutlined />} placeholder="Search Browsers" />
                </Form.Item>
                <Checkbox
                  className={styles.checkboxVersion}
                  checked={showVersions}
                  onChange={(e) => setShowVersions(e.target.checked)}
                  disabled={selectedAll}
                >
                  Show Browser Versions
                </Checkbox>
              </Row>
              <TargetingSelect
                allItems={browserAll}
                simpleItems={shownBrowsers}
                showVersions={showVersions}
                checkAll={selectedAll}
                selectedItems={targeting.browser.map((item) => {
                  return { ...item, id: String(item.id) };
                })}
                handleItemSelect={setSelectedItems}
                handleCheckAll={onCheckAllChange}
                itemName="Browsers"
                isLoading={isLoading}
                hasVersions={true}
              />
            </Col>
            <Col className={styles.targetingPreviewBox}>
              <Row className={styles.targetingSelectDiv} style={{ padding: '10px' }}>
                <h3 style={{ width: '100%', color: '#58a54f' }}>Included</h3>
                {targeting.browser.length === 0 ? (
                  <Row className={styles.countBox}>All Browsers</Row>
                ) : (
                  <Row className={styles.countBox}>
                    <span>
                      Browsers <span className={styles.countDisplay}>{targeting.browser.length}</span>{' '}
                    </span>
                  </Row>
                )}
              </Row>
              <TargetingPreview
                allItems={simpleBrowsersAll}
                allItemsSelected={selectedAll}
                selectedItems={targeting.browser.map((item) => {
                  return { ...item, id: String(item.id) };
                })}
                handleItemSelect={setSelectedItems}
                showVersions={showVersions}
                hasVersions={true}
                itemName="Browsers"
              />
            </Col>
          </Row>
        </Form>
      </Row>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab(type === 'Push' ? 'ages' : 'days')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
