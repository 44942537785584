import React, { useEffect, useState, useContext } from 'react';
import { Divider, Dropdown, Badge } from 'antd';
import { BarNotification } from './BarNotification';
import Lottie from 'react-lottie';
import NotificationContext from '../../contexts/notificationContext';
import NotificationBell from '../components/images/notifBell.json';
import { LoadingOutlined } from '@ant-design/icons';

export const BarNotificationIcon = ({ currentModule, dropdownVisible, setDropdownVisible }) => {
  const {
    userNotifications,
    setUserNotifications,
    userNotificationsCount,
    unreadCount,
    loadingNotifications,
    userNotificationPage,
    setUserNotificationPage,
    userNotificationPageSize,
    setUserNotificationPageSize,
  } = useContext(NotificationContext);

  const count = userNotifications.filter(notification => !notification.read).length;

  const [animationStatus, setAnimationStopped] = useState({
    loaded: false,
    stopped: true,
  });

  const bell = {
    loop: false,
    autoplay: false,
    animationData: NotificationBell,
  };

  const INTERVAL_MS = 150000;

  useEffect(() => {
    if (count > 0) {
      const interval = setInterval(() => {
        if (!animationStatus.loaded) {
          setAnimationStopped(prevState => {
            return { ...prevState, loaded: !prevState.loaded };
          });
        } else if (animationStatus.loaded) {
          setAnimationStopped(prevState => {
            return { ...prevState, stopped: !prevState.stopped };
          });
        }
      }, INTERVAL_MS);
      return () => clearInterval(interval);
    }
  }, [userNotifications, animationStatus, setAnimationStopped, count]);

  return (
    <>
      <div className="balanceField">
        <Dropdown
          overlayClassName="headerDropdown"
          placement="bottomRight"
          trigger={['click']}
          visible={dropdownVisible}
          onVisibleChange={value => {
            !loadingNotifications && setDropdownVisible(value);
          }}
          overlay={
            <BarNotification
              dropdownVisible={dropdownVisible}
              setDropdownVisible={setDropdownVisible}
              userNotifications={userNotifications}
              setUserNotifications={setUserNotifications}
              userNotificationsCount={userNotificationsCount}
              loadingNotifications={loadingNotifications}
              userNotificationPage={userNotificationPage}
              setUserNotificationPage={setUserNotificationPage}
              userNotificationPageSize={userNotificationPageSize}
              setUserNotificationPageSize={setUserNotificationPageSize}
              currentModule={currentModule}
            />
          }
        >
          <Badge count={loadingNotifications ? <span className="notificationLoadingIcon"><LoadingOutlined spin style={{ fontSize: '5px', color: '#ccc' }} /></span> : unreadCount === 0 ? '' : unreadCount} size="small">
            <Lottie
              isStopped={animationStatus.stopped}
              options={bell}
              height={30}
              width={30}
              style={{ align: 'center' }}
              isClickToPauseDisabled={true}
            />
          </Badge>
        </Dropdown>
      </div>
    </>
  );
};
