import React from 'react';
import { Checkbox } from 'antd';
import styles from './TargetingGeos.module.css';

export const TargetingSelectGeos = (props) => {
  const {
    shownCountries,
    selectedItems,
    handleCountriesSelect,
    showRegions,
    allRegions,
    selectedSubItems,
    handleRegionsSelect,
    clearRegions,
    setSelectedAll,
    selectedAll,
    onCountriesUnselect
  } = props;

  const simpleSelectedItems = selectedItems.length > 0 ? selectedItems.map((el) => el) : [];

  const handleSimpleItemSelect = (listOfCountryCodes) => {
    if (listOfCountryCodes.length === 0) {
      clearRegions();
    }
    if (selectedAll) {
      setSelectedAll(false);
    }
    handleCountriesSelect(listOfCountryCodes);
    onCountriesUnselect(listOfCountryCodes)
  };

  const CheckBoxRefactored = (item) => {
    return (
      <Checkbox key={item.value} value={item.value}>
        <span>{item.label}</span>
        {selectedItems.map((countryCode, index) => {
          if (showRegions && countryCode === item.value) {
            return (
              <div key={index} className={styles.subItemsContainer}>
                {allRegions[item.value] ?
                  <Checkbox.Group onChange={(value) => handleRegionsSelect(countryCode, value)} value={selectedSubItems}>
                    {allRegions[item.value] && allRegions[item.value].map(({ id, name }) =>
                      <Checkbox key={id} value={id}>{name}</Checkbox>
                    )}
                  </Checkbox.Group>
                  : <p>No data available.</p>
                }
              </div>
            )
          }
          else {
            return <span key={index}></span>
          }
        }
        )}
      </Checkbox>
    );
  };

  return (
    <div className={styles.targetingCheckboxParentDiv}>
      <div>
        <Checkbox.Group
          className={styles.targetingCheckboxGroup}
          onChange={handleSimpleItemSelect}
          value={simpleSelectedItems}
        >
          {shownCountries.map((item) => CheckBoxRefactored(item))}
        </Checkbox.Group>
      </div>
    </div>
  );
};
