import { Spin } from 'antd';
import React, { useCallback, useEffect } from 'react';
import Highcharts from 'highcharts';
import { openWarningNotification } from '../../../utils/notifications';

const defaultOptions = {
  chart: {
    zoomType: 'x'
  },
  title: {
    text: ''
  },
  xAxis: [
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      }
    },
    {
      type: 'datetime',
      dateTimeLabelFormats: {
        month: '%e. %b',
        year: '%b'
      },
      title: {
        text: 'Date'
      },
      opposite: true
    }
  ],
  tooltip: {
    shared: true
  },
  legend: {
    floating: false,
    layout: 'vertical',
    navigation: {
      activeColor: '#3E576F',
      animation: true,
      arrowSize: 3,
      inactiveColor: '#CCC',
      style: {
        fontWeight: 'bold',
        color: '#333',
        fontSize: '12px'
      }
    },
    margin: 0,
    labelFormatter: function () {
      let max = this.xData[this.xData.length - 1];
      let min = this.xData[0];
      return Highcharts.dateFormat('%b, %e', new Date(min)) + ' - ' + Highcharts.dateFormat('%b, %e', new Date(max)) + ' ' + this.name;
    },
    backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || '#FFFFFF'
  }
};

export const GraphView = ({ loading, data, selectedColumns }) => {
  const addNewYAxis = useCallback((name, data, index, xAxis = 0, options) => {
    var text;
    switch (name) {
      case 'im':
        text = 'impressions';
        break;
      case 'dw':
        text = 'downloads';
        break;
      case 'pl':
        text = 'leads';
        break;
      case 'ri':
        text = 'real impressions';
        break;
      case 'pf':
        text = 'fired';
        break;
      case 'p1':
        text = 'PX 1';
        break;
      case 'cr':
        text = 'conversion rate';
        break;
      case 'p2':
        text = 'PX 2';
        break;
      default:
        text = name;
    }
    if (index > options.yAxis.length - 1) {
      let format = '{value}';
      if (name === 'spent' || name === 'earned') {
        format = '$ ' + format;
      }

      const colorIndex = options.series.length;
      const color = Highcharts.getOptions().colors[colorIndex];

      options.yAxis.push({
        labels: {
          format,
          style: {
            color
          }
        },
        title: {
          text: text,
          style: {
            color
          }
        },
        opposite: true
      });
    }

    options.series.push({
      name: text,
      type: 'spline',
      xAxis,
      yAxis: index,
      data
    });
  }, []);
  const metrics = selectedColumns
    .filter((item) => ['revenue', 'cpc', 'cpm', 'cr', 'impressions', 'cpa', 'clicks', 'conversions'].includes(item.dataIndex))
    .map((item) => item.dataIndex);
  const parseChartData = (chartData) => {
    // checking if response data is for the chart or the table
    if (chartData.values) {
      let options = { ...defaultOptions, series: [], yAxis: [] };
      let yAxisIndex = 0;
      if (metrics.length > 0) {
        metrics.forEach((metric, metricIndex) => {
          const values = chartData.values.map((value) => [value[0], value[metricIndex + 1]]);
          addNewYAxis(metric, values, yAxisIndex, 0, options);
          yAxisIndex++;
          if (yAxisIndex > options.yAxis.length) {
            yAxisIndex = options.yAxis.length;
          }
        });
      } else {
        openWarningNotification({ message: `There are no data for this filters`, duration: 8 });
      }
      if (options.series.length > 0) {
        Highcharts.chart('chart-wrapper', options);
      }
    }
  };
  useEffect(() => parseChartData(data), [data]);

  return (
    <Spin spinning={loading}>
      <div id="chart-wrapper" style={{ height: '500px' }} />
    </Spin>
  );
};
