import React from 'react';
import { Tabs } from 'antd';
// import CampaingsApi from './components/CampaignApi';
import { StatsApi } from './components/StatsApi';
import styles from './Api.module.css';

const { TabPane } = Tabs;

export const ApiIntegration = () => {
  return (
    <div className={styles.integrationContent}>
      <Tabs defaultActiveKey="1" tabPosition={'left'}>
        <TabPane tab="API Integrations" key="1">
          <StatsApi />
        </TabPane>
        {/* <TabPane tab="Campaigns API" key="2" disabled>
          <CampaingsApi />
        </TabPane> */}
      </Tabs>
    </div>
  );
};
