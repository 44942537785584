import { callApi } from './api';
import moment from 'moment';

const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return '';
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return query ? (withoutQuestionMark ? query : `?${query}`) : '';
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({
  limit: pageSize,
  offset: (currentPage - 1) * pageSize
});

class FinanceAccountStatements {
  async getStatementsPaged({ selectedFilters, currentPage, checkedColumns, pageSize, cancelToken }) {
    const { filters, dateRange } = selectedFilters;
    let colParameter = 'platform,id';

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += ',' + key;
      }
    });
    const query = getQueryString({
      ...getOffsetLimitFromPagination({ currentPage, pageSize }),
      columns: colParameter,
      date_from: moment(dateRange.from).format('YYYY-MM-DD'),
      date_to: moment(dateRange.to).format('YYYY-MM-DD'),
      statement_type: filters.type && filters.type,
      platform: filters.platform && filters.platform
    });
    const response = await callApi({
      url: `/api/finance/account_statement/${query}`,
      cancelToken
    });
    return { results: response.data.items, summary: response.data.items_totals, count: response.data.total };
  }
}

export const financeAccountStatementsService = new FinanceAccountStatements();
