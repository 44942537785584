import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { listsService } from '../services/lists';
import { targetingService } from '../services/targeting';
import { trackingService } from '../services/tracking';
import { openErrorNotification } from '../utils/notifications';
const TargetingContext = React.createContext({});
export default TargetingContext;

const agesAll = [
  { label: '0-3 days: Newly harvested', value: '1' },
  { label: '3-10 days: Vigorous', value: '2' },
  { label: '10-20 days: Moderate', value: '3' },
  { label: '20-35 days: Solid', value: '4' },
  { label: '35-50 days: Satisfactory', value: '5' },
  { label: '50+ days: Fair', value: '6' }
];

export const TargetingContextProvider = (props) => {
  const { pathname } = useLocation();
  const [countriesAll, setCountriesAll] = useState([]);
  const [regionsAll, setRegionsAll] = useState([]);
  const [countryListAll, setCountryListAll] = useState([]);
  const [osAll, setOsAll] = useState([]);
  const [browserAll, setBrowserAll] = useState([]);
  const [manufacturerAll, setManufacturerAll] = useState([]);
  const [typeDeviceAll, setTypeDeviceAll] = useState([]);
  const [categoriesAll, setCategoriesAll] = useState([]);
  const [connectionAll, setConnectionAll] = useState([]);
  const [ipListAll, setIpListAll] = useState([]);
  const [subidListAll, setSubidListAll] = useState([]);
  const [trackingUrls, setTrackingUrls] = useState({});
  const [loading, setLoading] = useState(false);

  const getAndParseTargetingOptions = async () => {
    setLoading(true);
    try {
      const [country, region, countryList, os, browser, connection, type, manufacturer, category, ip, subid, trackingUrls] = await Promise.all([
        targetingService.getAllCountries(),
        targetingService.getAllRegions(),
        listsService.getAllLists({ type: 'country' }),
        targetingService.getAllOs(),
        targetingService.getAllBrowsers(),
        targetingService.getAllConnections(),
        targetingService.getAllDeviceTypes(),
        targetingService.getAllDeviceManufacturers(),
        targetingService.getAllTrafficCategories(),
        listsService.getAllLists({ type: 'ip' }),
        listsService.getAllLists({ type: 'subid' }),
        trackingService.getUrl()
      ]);
      if (country.success) {
        let countriesArray = country.data.map((el) => {
          return { label: el.name, value: el.code };
        });
        setCountriesAll(countriesArray);
      }
      if (region.success) {
        setRegionsAll(region.data);
      }
      if (countryList.success) {
        setCountryListAll(countryList.data.items);
      }
      if (os.success) {
        setOsAll(os.data);
      }
      if (browser.success) {
        setBrowserAll(browser.data);
      }
      if (connection.success) {
        const connectionList = connection.data.items.map((el) => {
          return { label: el.name, value: el.id, isChecked: false };
        });
        setConnectionAll(connectionList);
      }
      if (type.success) {
        let devicesList = type.data.items.map((el) => {
          return { label: el.name, value: el.id };
        });
        setTypeDeviceAll(devicesList);
      }
      if (manufacturer.success) {
        let devicesList = manufacturer.data.map((el) => {
          return { label: el.name.charAt(0).toUpperCase() + el.name.slice(1).toLowerCase(), value: el.id };
        });
        setManufacturerAll(devicesList);
      }
      if (category.success) {
        setCategoriesAll(category.data);
      }
      if (ip.success) {
        setIpListAll(ip.data.items);
      }
      if (subid.success) {
        setSubidListAll(subid.data.items);
      }
      if (trackingUrls.success) {
        setTrackingUrls(trackingUrls.data);
      }
    } catch (e) {
      openErrorNotification({ message: 'Failed to load targeting!' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pathname === '/campaigns/new-campaign' || pathname.includes('/campaigns/')) {
      getAndParseTargetingOptions();
    }
  }, [pathname]);

  return (
    <TargetingContext.Provider
      value={{
        countriesAll,
        regionsAll,
        agesAll,
        osAll,
        browserAll,
        manufacturerAll,
        typeDeviceAll,
        categoriesAll,
        connectionAll,
        countryListAll,
        ipListAll,
        subidListAll,
        trackingUrls,
        loading,
        setLoading
      }}
    >
      {props.children}
    </TargetingContext.Provider>
  );
};
