import React from 'react';
import { Checkbox, Collapse, Tabs, Radio, Spin } from 'antd';
import { ExclamationCircleOutlined, CaretRightOutlined } from '@ant-design/icons';
import styles from './Targeting.module.css';
import { openWarningNotification } from '../../../utils/notifications';

const { Panel } = Collapse;
const { TabPane } = Tabs;
export const TargetingSelect = (props) => {
  const {
    isLoading,
    allItems,
    simpleItems,
    selectedItems,
    itemName,
    showVersions,
    isTwoColumns,
    checkAll,
    hasVersions,
    hasLists,
    targetingLists,
    selectedLists,
    setSelectedLists,
    setSelectedAll,
    selectedAll,
    height
  } = props;

  const simpleSelectedItems = hasVersions ? (selectedItems.length > 0 ? selectedItems.map((el) => el.id) : []) : selectedItems;
  const handleDefaultActiveTab = (item) => {
    let op = selectedItems.filter((el) => el.id === item.id)[0].op;
    return op === 'min_version' ? '2' : '1';
  };

  const handleSimpleItemSelect = (checked) => {
    if (selectedAll) {
      setSelectedAll(false);
    }
    let itemsToBeSelected = [];
    if (hasVersions) {
      checked.forEach((item) => {
        // let versionOBJ = allItems.filter((el) => el.id === item)[0].versions;
        // let version = versionOBJ ? Object.keys(versionOBJ)[Object.keys(versionOBJ).length - 1] : null;
        // itemsToBeSelected.push({ id: item, op: 'min_version', version: [version] });
        itemsToBeSelected.push({ id: item });
      });
      props.handleItemSelect(itemsToBeSelected);
    } else {
      props.handleItemSelect(checked);
    }
  };
  const handleSimpleListSelect = (checked) => {
    if (selectedAll) {
      setSelectedAll(false);
    }
    if (checked.length > 10) {
      openWarningNotification("You can't select more than 10 lists!");
    } else {
      setSelectedLists(checked);
    }
  };

  const handleItemSelectWithVersion = (e, item, versions) => {
    let itemsToBeSelected = selectedItems.filter((el) => el.id !== item.id);
    if (e.target.checked) {
      itemsToBeSelected.push({ id: item.id, op: 'exact', version: [] });
    } else {
      itemsToBeSelected = selectedItems.filter((el) => el.id !== item.id);
    }
    props.handleItemSelect(itemsToBeSelected);
  };

  const handleVersionTypeSelect = (k, e, item) => {
    let listWithVersions = selectedItems.filter((el) => el.id !== item.id);
    let targetedObject = selectedItems.filter((el) => el.id === item.id)[0];
    if (!targetedObject) {
      targetedObject = {
        id: item.id,
        op: 'exact',
        version: []
      };
    }
    delete targetedObject.version;
    targetedObject.op = k === '1' ? 'exact' : 'min_version';
    listWithVersions.push(targetedObject);
    props.handleItemSelect(listWithVersions);
  };

  const handleExactVersionSelect = (checked, isChecked, item) => {
    let listWithVersions = selectedItems.filter((el) => el.id !== item.id);
    let targetedObject = selectedItems.filter((el) => el.id === item.id)[0];
    if (!targetedObject) {
      targetedObject = {
        id: item.id,
        op: 'exact',
        version: []
      };
    }
    targetedObject.version = checked;
    listWithVersions.push(targetedObject);

    if (checked.length > 0) {
      props.handleItemSelect(listWithVersions);
    } else if (checked.length === 0 && isChecked) {
      props.handleItemSelect(selectedItems.filter((el) => el.id !== item.id));
    }
  };

  const handleMinimalVersionSelect = (checked, isChecked, item) => {
    let listWithVersions = selectedItems.filter((el) => el.id !== item.id);
    let targetedObject = selectedItems.filter((el) => el.id === item.id)[0];
    if (!targetedObject) {
      targetedObject = {
        id: item.id,
        op: 'min_version',
        version: []
      };
    }
    targetedObject.version = [checked];
    listWithVersions.push(targetedObject);
    props.handleItemSelect(listWithVersions);
  };

  const checkboxPanel = (item) => {
    let isChecked = simpleSelectedItems.indexOf(item.id) > -1 ? true : false;
    let defaultActiveTabKey = isChecked ? handleDefaultActiveTab(item) : '1';
    // console.log(item, selectedItems);
    let valueOfVersions = isChecked ? selectedItems.filter((el) => el.id === item.id)[0].version : [];
    let versions = item.versions
      ? Object.keys(item.versions).map((key) => {
          return { label: item.versions[key].split(' ')[0] + ` (${key})`, value: key };
        })
      : [];

    return (
      <Panel
        header={
          <div>
            <Checkbox checked={isChecked} onChange={(e) => handleItemSelectWithVersion(e, item, versions)}>
              {item.name}
            </Checkbox>
          </div>
        }
        key={item.id}
      >
        {versions.length ? (
          <Tabs
            style={{ width: '200px', marginLeft: '-20px' }}
            onTabClick={(key, event) => handleVersionTypeSelect(key, event, item)}
            defaultActiveKey={defaultActiveTabKey}
          >
            <TabPane tab="Exact" key="1">
              <Checkbox.Group
                style={{ display: 'flex', flexDirection: 'column' }}
                options={versions}
                onChange={(checked) => handleExactVersionSelect(checked, isChecked, item)}
                value={valueOfVersions}
              />
            </TabPane>
            <TabPane tab="Minimal" key="2">
              <Radio.Group
                options={versions}
                style={{ display: 'flex', flexDirection: 'column' }}
                onChange={(e) => handleMinimalVersionSelect(e.target.value, isChecked, item)}
                value={valueOfVersions ? valueOfVersions[0] : null}
              />
            </TabPane>
          </Tabs>
        ) : (
          <p>
            <ExclamationCircleOutlined /> No versions available{' '}
          </p>
        )}
      </Panel>
    );
  };

  const itemVersionsChecbox = (items) => {
    let sortedItems =
      simpleItems.length > 0
        ? simpleItems.map((el) => {
            return items.filter((item) => item.name === el.label)[0];
          })
        : [];
    // browsers =
    //   browsers.length > 0
    //     ? browsers.concat(this.state.simpleBrowsersAll.filter((item) => !browsers.some((browser) => browser.label === item.label)))
    //     : [];
    let options = [];
    // let activeKeys = items.map((el) => el.id);
    sortedItems.forEach((element) => {
      options.push(checkboxPanel(element));
    });
    return (
      <Collapse className={styles.accordionSelect} expandIconPosition="right" defaultActiveKey={simpleSelectedItems} ghost>
        {options}
      </Collapse>
    );
  };
  return (
    <div className={styles.targetingCheckboxParentDiv} style={height ? { height } : {}}>
      {isLoading ? (
        <Spin size="large" className={styles.targetingSpinner} />
      ) : (
        <div>
          {!showVersions ? (
            <Checkbox.Group
              className={isTwoColumns ? styles.targetingCheckboxGroupTwoColumns : styles.targetingCheckboxGroup}
              options={simpleItems}
              onChange={handleSimpleItemSelect}
              value={simpleSelectedItems}
            />
          ) : (
            itemVersionsChecbox(allItems)
          )}
        </div>
      )}
    </div>
  );
};
