import React, { useState, useEffect, useReducer, useMemo } from 'react';
import moment from 'moment';

import { Table } from './components/Table';
import styles from './BillingPaymentHistory.module.css';
import { CampaignFilters } from './components/CampaignsFilters';
import { allColumns, initialSelectedColumns, staticColumns } from './utils/columns';
import { filtersReducer } from './utils/reducers';
import { ActionsTableCol } from './components/ActionsTableCol';
import { financeInvoiceHistoryService } from '../../../services/financeInvoiceHistory';
import { useFetch } from '../../../utils/hooks';

export const InvoiceHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [selectedFilters, dispatchSelectedFilters] = useReducer(filtersReducer, {});
  const [dateRange, setDateRange] = useState({
    from: moment().subtract(30, 'days'),
    to: moment()
  });
  const [filters, setFilters] = useState({});
  const [checkedColumns, setCheckedColumns] = useState(initialSelectedColumns);

  const loadMediaCampaigns = (cancelToken) =>
    financeInvoiceHistoryService.getPaymentsPaged({
      selectedFilters,
      checkedColumns,
      currentPage,
      pageSize,
      cancelToken
    });

  const [
    {
      data: { results: campaigns, count: totalRows },
      isFetching
    },
    getMediaCampaigns
  ] = useFetch(loadMediaCampaigns);

  const actionTableColumn = useMemo(() => {
    return {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      align: 'center',
      render: ({ status, invoice, id }) => (
        <ActionsTableCol id={id} status={status} invoice={invoice} dispatchSelectedFilters={dispatchSelectedFilters} filters={filters} />
      )
    };
  }, [filters]);

  const [selectedColumns, setSelectedColumns] = useState([...staticColumns, ...allColumns, actionTableColumn]);

  useEffect(() => {
    dispatchSelectedFilters({
      payload: {
        filters: {},
        dateRange: { from: moment().subtract(30, 'days'), to: moment() }
      }
    });
  }, []);

  useEffect(() => {
    const filteredColumns = allColumns.filter((item) => {
      return Object.keys(checkedColumns).some((key) => {
        return checkedColumns[key] && key === item.dataIndex;
      });
    });
    setSelectedColumns([...staticColumns, ...filteredColumns, actionTableColumn]);
  }, [selectedFilters, actionTableColumn, checkedColumns]);

  useEffect(() => {
    if (selectedFilters.filters) {
      getMediaCampaigns();
    }
  }, [currentPage, pageSize, getMediaCampaigns, selectedFilters]);

  // const exportCampaings = (filters, dateRange) => {
  //   mediaCampaignsService.exportMediaCampaigns({
  //     filters,
  //     dateRange,
  //     checkedColumns,
  //   });
  // };

  return (
    <div className={styles.campaignsTable}>
      <h4>Payment History</h4>
      <p>You can access your payment history in the table below.</p>
      {/* <div className={styles.billingHeadInfo}>
        <div>
          <span>Balance</span>
          <span>$ 00,00</span>
        </div>
        <div>
          <span>Minimum payout</span>
          <span>5 USD</span>
        </div>
      </div> */}
      <CampaignFilters
        submit={(filters) => {
          dispatchSelectedFilters({
            type: '',
            payload: { filters, dateRange }
          });
        }}
        filters={filters}
        setFilters={setFilters}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Table
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        campaigns={campaigns}
        totalRows={totalRows}
        currentPage={currentPage}
        pageSize={pageSize}
        loadingCampaigns={isFetching}
        getMediaCampaigns={getMediaCampaigns}
        onPaginationChange={(currentPage, pageSize) => {
          setCurrentPage(currentPage);
          setPageSize(pageSize);
        }}
        checkedColumns={checkedColumns}
        setCheckedColumns={setCheckedColumns}
        selectedFilters={selectedFilters}
        date
      />
    </div>
  );
};
