import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Select, Radio, Input } from 'antd';

import styles from './Links.module.css';
import CampaignContext from '../../../../contexts/campaignContext';
import { trackers } from '../../utils';
import { TrackingInfo } from './components/TrackingInfo';
import TargetingContext from '../../../../contexts/targetingContext';

export const Links = ({ form, onNext, setMaxTabPage, maxTabPage, isManual, setIsManual }) => {
  const { trackingUrls } = useContext(TargetingContext);
  const { campaign, dispatchCampaign } = useContext(CampaignContext);
  const [trackerType, setTrackerType] = useState(null);
  const [trackerValue, setTrackerValue] = useState(null);

  useEffect(() => {
    setIsManual(false);
    if (campaign.general) {
      const { conversion_tracking_type } = campaign.general;
      const initialValues = {
        conversion_tracking_type: conversion_tracking_type ? conversion_tracking_type : 'Default',
        conversion_tracking_value:
          campaign.general && campaign.general.conversion_tracking_value ? campaign.general.conversion_tracking_value : null
      };
      setTrackerType(initialValues.conversion_tracking_type);
      setTrackerValue(initialValues.conversion_tracking_value);
      setIsManual(!!(campaign.general && campaign.general.conversion_tracking_value));
      form.setFieldsValue(initialValues);
    }
  }, [campaign]);

  useEffect(() => {
    if (!!campaign.limit) {
      form.setFieldsValue({ limit_value: campaign.limit.limit_value, period: campaign.limit.period ? campaign.limit.period : 'day' });
    }
  }, [campaign.limit]);

  const onButtonNextClick = (values) => {
    const formatedValues = {
      ...campaign.general,
      ...values,
      conversion_tracking_value: values.conversion_tracking_value && isManual ? values.conversion_tracking_value : false
    };
    if (maxTabPage === 5) {
      setMaxTabPage(6);
    }
    dispatchCampaign({ type: 'general', payload: formatedValues });
  };

  useEffect(() => {
    if (!isManual) {
      setTrackerValue(null);
      form.setFieldsValue({ conversion_tracking_value: null });
    }
  }, [isManual]);

  return (
    <div className={styles.generalWrapper}>
      <div className={styles.generalContentWrapper}>
        <Form layout="vertical" form={form} name="GeneralConfig" onFinish={onButtonNextClick}>
          <Form.Item
            style={{ maxWidth: '600px' }}
            label="Conversion tracking"
            name="conversion_tracking_type"
            rules={[
              {
                required: true,
                message: 'Please select conversion tracking'
              }
            ]}
          >
            <Select
              placeholder="Choose conversion tracking"
              dropdownStyle={{ fontWeight: 'bold' }}
              onChange={(value) => setTrackerType(value)}
            >
              <Select.Option value="Default">Default</Select.Option>

              {trackers.map((el) => (
                <Select.Option key={el.trackerName} value={el.trackerName}>
                  <img src={'../' + el.imgPath} alt="tracker" width="20px" height="20px" /> {el.trackerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            className={styles.autoManualPricing}
            style={{ display: 'inline-block' }}
            label="$ Value"
            rules={[
              {
                required: true,
                message: 'Please select conversion tracking'
              }
            ]}
          >
            <Radio.Group buttonStyle="solid" onChange={(e) => setIsManual(e.target.value)} value={isManual}>
              <Radio.Button className={styles.radioButtonLabels} value={false}>
                Auto
              </Radio.Button>
              <Radio.Button className={styles.radioButtonLabels} value={true}>
                Manual
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          {isManual && (
            <Form.Item
              label=" "
              style={{ display: 'inline-block', marginLeft: 30 }}
              className={styles.manualValueInput}
              name="conversion_tracking_value"
            >
              <Input
                type="text"
                placeholder="Enter amount"
                onChange={(e) => {
                  setTrackerValue(e.target.value ? e.target.value : null);
                }}
              />
            </Form.Item>
          )}
        </Form>
        <TrackingInfo trackingUrls={trackingUrls} trackerType={trackerType} trackerValue={trackerValue} />
      </div>
      <Button
        className={styles.proceedButton}
        type="primary"
        onClick={async () => {
          try {
            await form.validateFields();
            form.submit();
            onNext();
          } catch (e) {
            console.log(e);
          }
        }}
        style={{ float: 'right', marginTop: '20px' }}
      >
        Proceed to Creatives
      </Button>
    </div>
  );
};
