import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
// import { openSuccessNotification } from '../../../utils/notifications';
import { activationService } from '../../services/activation';
import styles from './Activation.module.css';
import { openSuccessNotification, openWarningNotification } from '../../utils/notifications';

export const ActivationPage = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    const passObj = { ...values, login: !!values.login };

    setLoading(true);
    try {
      const res = await activationService.activateUserProfile(passObj);
      if (res.success) {
        openSuccessNotification({ message: res.message });
        if (!values.login) {
          window.location.href = '/login/';
        } else {
          window.location.href = '/';
        }
      } else {
        openWarningNotification({ message: res.message });
      }
      // openSuccessNotification({ message: 'Password changed successfully!' });
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const validatePasswordConfirm = () => {
    let newPassword = form.getFieldValue('password');
    let confirmPassword = form.getFieldValue('confirm_password');
    if (newPassword !== confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const validateNewPassword = (value) => {
    return /(?=^.{8,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])/.test(value);
  };

  return (
    <div className={styles.activationSection}>
      <div className={styles.activation}>
        <div className={styles.activationLeft}>
          <div className={styles.logoLight}>
            <a href="/">
              <img src="images/logo-light.svg" alt="logo-light" />
            </a>
            <p>Activate Account</p>
          </div>
          <img className={styles.leftBg} src="images/activate-bg.png" alt="activate-bg" />
        </div>
        <div className={styles.activationRight}>
          <div className={styles.center}>
            <Spin spinning={loading}>
              <div className={styles.activationForm}>
                <img src="images/activate-logo.svg" alt="logo" />
                <h1>Activate Account</h1>
              </div>
              <Form
                form={form}
                name="userForm"
                onFinish={onSubmit}
                onFinishFailed={({ errorFields }) => {
                  form.scrollToField(errorFields[0].name);
                }}
              >
                {/* <p className={styles.formLabels}>* Password</p> */}
                <Form.Item
                  name="password"
                  rules={[
                    {
                      validator: async (rule, value) => {
                        if (!validateNewPassword(value))
                          throw new Error('Password must contain uppercase letter, lowercase letter and a number!');
                      }
                    },
                    {
                      required: true,
                      message: 'Please type new password!'
                    }
                  ]}
                >
                  <Input.Password
                    placeholder="Enter password"
                    size="middle"
                    iconRender={(visible) =>
                      visible ? <img alt="showpass" src="../images/show.png" /> : <img alt="hidepass" src="../images/hide.png" />
                    }
                  />
                </Form.Item>
                {/* <p className={styles.formLabels}>* Confirm Password</p> */}
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: 'Please type password confirmation!'
                    },
                    {
                      validator: async (rule, value) => {
                        if (validatePasswordConfirm()) throw new Error("Passwords don't match!");
                      }
                    }
                  ]}
                >
                  <Input.Password
                    placeholder="Enter identical password"
                    size="middle"
                    iconRender={(visible) =>
                      visible ? <img alt="showpass" src="../images/show.png" /> : <img alt="hidepass" src="../images/hide.png" />
                    }
                  />
                </Form.Item>
                <Form.Item name="login" valuePropName="checked">
                  <Checkbox>Sign in using these credentials</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Activate
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    </div>
  );
};
