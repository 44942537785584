import React from 'react';
import { Tooltip } from 'antd';
import styles from './Show.module.css';

export const CampaignNameCol = ({ owner, channel, companyDetails }) => {
  // const getInitials = () => {
  //   const { first_name, last_name, username } = owner;
  //   return first_name && last_name
  //     ? first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase()
  //     : username.charAt(0).toUpperCase();
  // };

  return (
    // <div className={styles.campaignNameWrapper}>
    // <span className={styles.nameInitials}>{getInitials()}</span>
    // <div>
    // <>
    //   <Tooltip title={channel}>
    <div className={styles.channelName}>{channel}</div>
    //   </Tooltip>
    // </>
    // <div className={styles.companyName}>{companyDetails.name}</div>
    // </div>
    // </div>
  );
};
