import React, { useContext } from 'react';
import { Row, Table } from 'antd';
import { PictureFilled } from '@ant-design/icons';
import styles from '../CampaignReview.module.css';
import CampaignContext from '../../../../../contexts/campaignContext';

export const CreativesInfo = ({ campaignType }) => {
  const { creatives } = useContext(CampaignContext);

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text = 'inactive') => {
        return <span className={styles[`status${text}`]}>{text}</span>;
      }
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title) => {
        return <p style={{ marginBottom: 0 }}>{title && title[Object.keys(title)[0]]}</p>;
      }
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      // sorter: {
      //   compare: (a, b) => a.type - b.type,
      //   multiple: 1
      // },
      render: () => {
        return (
          <div className={`${styles.campaignType} ${styles['campaignType' + campaignType.toUpperCase()]}`}>
            <span>{campaignType.toUpperCase()}</span>
          </div>
        );
      }
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (image) => {
        return (
          <div>
            {image && Object.keys(image)[0] && (
              <img style={{ width: 89, height: 50, objectFit: 'cover' }} src={image[Object.keys(image)[0]]} alt="creative" />
            )}
          </div>
        );
      }
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      render: (image) => {
        return <div>{image && <img style={{ width: 50, height: 50 }} src={image} alt="creative2" />}</div>;
      }
    }
  ];
  const renderColumns = (type) => {
    switch (type) {
      case 'Push':
        return columns;
      case 'Native':
        return columns.filter((item) => item.dataIndex !== 'icon');
      case 'Smartlink':
        return [
          ...columns.filter((item) => item.dataIndex === 'status' || item.dataIndex === 'type'),
          { title: 'Link', dataIndex: 'landing_url', key: 'landing_url' }
        ];
      case 'Contextual Ad':
        return [
          ...columns.filter((item) => item.dataIndex === 'status' || item.dataIndex === 'title'),
          { title: 'Link', dataIndex: 'landing_url', key: 'landing_url' }
        ];
      default:
        return [];
    }
  };

  const filterDeletedRows = (creatives) => creatives.filter((item) => item.action !== 'delete');

  return (
    <Row style={{ marginBottom: '30px', width: '100%' }}>
      <h3 className={styles.generalInfoHeader}>
        <PictureFilled /> Creatives
      </h3>

      <Table
        rowKey={(record) => record.id + record.gen_id}
        columns={renderColumns(campaignType)}
        dataSource={filterDeletedRows(creatives)}
        pagination={false}
      />
    </Row>
  );
};
