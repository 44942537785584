import { callApi } from './api';
const handleOrdering = (sorting) => {
  const ordering = [];
  for (const key in sorting) {
    const val = sorting[key];
    if (val) {
      if (val === 'ascend') {
        ordering.push('-' + key);
      } else {
        ordering.push(key);
      }
    }
  }
  return ordering;
};
const getQueryString = (queryParams, withoutQuestionMark = false) => {
  if (!queryParams) {
    return '';
  }

  const query = Object.keys(queryParams)
    .filter((key) => {
      const value = queryParams[key];
      return value !== undefined && value !== null && value !== '';
    })
    .map((key) => {
      const value = queryParams[key];
      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return query ? (withoutQuestionMark ? query : `?${query}`) : '';
};

export const getOffsetLimitFromPagination = ({ currentPage, pageSize }) => ({ limit: pageSize, offset: (currentPage - 1) * pageSize });

class ReportsService {
  async getCountries() {
    return await callApi({
      url: '/api/countries/'
    });
  }
  async getOperatingSystems() {
    return await callApi({
      url: '/api/os/'
    });
  }
  async getBrowsers() {
    return await callApi({
      url: '/api/browsers/'
    });
  }

  async getStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken, details }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    //If action is true, request is for stats for nested table
    let colParameter = details ? '' : '';

    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += ',' + key;
      }
    });
    if (checkedColumns['creator_first_name']) {
      colParameter += ',creator_last_name';
    }

    // Remove ',' from columns if request is for nested table -- dont need campaign_id for request, error on backend
    // if (details) {
    colParameter = colParameter.substring(1);
    // }

    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        ...getOffsetLimitFromPagination({ currentPage, pageSize }),
        columns: colParameter,
        date_from: dateRange.from.format('YYYY-MM-DD'),
        date_to: dateRange.to.format('YYYY-MM-DD'),
        os: filters.os && filters.os.join(','),
        browser: filters.browsers && filters.browsers.join(','),
        country: filters.countries && filters.countries.join(','),
        campaign_id: filters.campaigns && filters.campaigns.join(','),
        campaign_type: filters.campaign_types && filters.campaign_types.join(','),
        creative_id: filters.creative_id && filters.creative_id,
        ordering: ordering && ordering.join(','),
        details: details ? true : ''
      },
      true
    );

    // return {
    //   total: [{ source: 146, clicks: 297, ctr: 16.132536664856058, cr: 0.0, cpm: -0.011352525609258325 }],
    //   table: [
    //     { source: 'glx_w10_1606_phoenix1', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 1.5, base_price: 1 },
    //     { source: 'wba_w10_2807_giannis5', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 0.5, base_price: 1 },
    //     {
    //       source: 'benz_w10_0408-Meteoroid-2573',
    //       clicks: 2,
    //       ctr: 20.0,
    //       cr: 0.0,
    //       cpm: -0.009999999747378752,
    //       status: 'included',
    //       price: 1,
    //       base_price: 1
    //     },
    //     { source: 'panda_w10_1008_WWcpa', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 2, base_price: 1 },
    //     {
    //       source: 'lux_w10_0203-BERNIE-905',
    //       clicks: 2,
    //       ctr: 7.407407407407407,
    //       cr: 0.0,
    //       cpm: -0.007407407220280556,
    //       status: 'included',
    //       price: 1,
    //       base_price: 1
    //     },
    //     {
    //       source: 'zic_w10_1305-RESI-2582',
    //       clicks: 3,
    //       ctr: 23.076923076923077,
    //       cr: 0.0,
    //       cpm: -0.023076922493950967,
    //       status: 'included',
    //       price: 0.75,
    //       base_price: 1
    //     },
    //     { source: 'wba_w10_2807_giannis1', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 1.5, base_price: 1 },
    //     { source: 'primo_w10_1108-DURO', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 1.5, base_price: 1 },
    //     { source: 'bps_w10_1606', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 1.5, base_price: 1 },
    //     { source: 'glx_w10_1606_phoenix4', clicks: 0, ctr: 0.0, cr: 0.0, cpm: 0.0, status: 'included', price: 1.5, base_price: 1 }
    //   ],
    //   rows: 146
    // };

    return await callApi({ url: `/api/campaigns/stats/?resType=table&${query}`, cancelToken });
  }

  async getChartStatsPaged({ selectedFilters, currentPage, pageSize, sorting, cancelToken }) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = 'date,hour';

    Object.keys(checkedColumns).forEach(function (key) {
      if (
        checkedColumns[key] &&
        ['revenue', 'cpc', 'cpm', 'cr', 'impressions', 'cpa', 'clicks', 'conversions', 'expected_revenue'].includes(key)
      ) {
        colParameter += ',' + key;
      }
    });
    if (checkedColumns['creator_first_name']) {
      colParameter += ',creator_last_name';
    }
    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        columns: colParameter,
        date_from: dateRange.from.format('YYYY-MM-DD'),
        date_to: dateRange.to.format('YYYY-MM-DD'),
        os: filters.os && filters.os.join(','),
        browser: filters.browser && filters.browser.join(','),
        country: filters.countries && filters.countries.join(','),
        campaign_id: filters.campaigns && filters.campaigns.join(','),
        ordering: ordering && ordering.join(',')
      },
      true
    );
    return await callApi({ url: `/api/campaigns/stats/?resType=chart&${query}`, cancelToken });
  }

  async exportReports(selectedFilters, currentPage, pageSize, sorting) {
    const { filters, dateRange, checkedColumns } = selectedFilters;
    let colParameter = 'campaign_id';
    Object.keys(checkedColumns).forEach(function (key) {
      if (checkedColumns[key]) {
        colParameter += ',' + key;
      }
    });
    if (checkedColumns['creator_first_name']) {
      colParameter += ',creator_last_name';
    }
    // if (checkedColumns['lp_title'] && !checkedColumns['lp_id']) {
    //   colParameter += ',lp_id';
    // }
    const ordering = handleOrdering(sorting);
    const query = getQueryString(
      {
        ...getOffsetLimitFromPagination({ currentPage, pageSize }),
        columns: colParameter,
        date_from: dateRange.from.format('YYYY-MM-DD'),
        date_to: dateRange.to.format('YYYY-MM-DD'),
        os: filters.os && filters.os.join(','),
        browser: filters.browser && filters.browser.join(','),
        country: filters.countries && filters.countries.join(','),
        campaign_id: filters.campaigns && filters.campaigns.join(','),
        ordering: ordering && ordering.join(',')
      },
      true
    );
    window.location = `/api/campaigns/stats/?resType=csv&${query}`;
  }

  async updatePricingValue(data) {
    return await callApi({
      url: 'api/campaigns/price/',
      method: 'POST',
      data
    });
  }
  async changeTargetingStatus(data) {
    return await callApi({
      url: 'api/campaigns/targeting/',
      method: 'POST',
      data
    });
  }
  async updateCreatives(data) {
    return await callApi({
      url: 'api/creatives/status/update/',
      method: 'POST',
      data
    });
  }
}

export const reportsService = new ReportsService();
