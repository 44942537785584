import React from 'react';
import styles from '../BillingPaymentHistory.module.css';

export const initialSelectedColumns = {
  date: true,
  status: true,
  description: true,
  transaction_id: true,
  payment_method: true,
  amount: true
};

export const staticColumns = [
  {
    title: 'Date',
    dataIndex: 'date'
  },
  {
    title: 'Status',
    align: 'center',
    dataIndex: 'status',
    render: (type) => <div className={styles.statusContainer}><div className={`${styles.status} ${styles['status' + type]}`}>{type.replace('_', ' ')}</div></div>
  },
  {
    title: 'Description',
    dataIndex: 'description'
  },
  {
    title: 'Transaction ID',
    dataIndex: 'transaction_id'
  },
  {
    title: 'Payment Method',
    dataIndex: 'payment_method'
  },
  {
    title: 'Amount',
    dataIndex: 'amount'
  }
];

export const allColumns = [];
