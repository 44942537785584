import { ClockCircleOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Row, Button, Radio } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import styles from './Days.module.css';
import CampaignContext from '../../../../../../contexts/campaignContext';
import { TimesTable } from './TimesTable.js';

export const DaysTab = ({ changeActiveTab }) => {
  const { targeting, dispatchTargeting } = useContext(CampaignContext);
  const [selectionType, setSelectionType] = useState('all');

  useEffect(() => {
    if (targeting.day.item.length || targeting.hour.item.length) {
      setSelectionType('custom');
    }
  }, []);

  const handleAddDay = (value) => {
    dispatchTargeting({ type: 'day', payload: { ...targeting.day, item: [...targeting.day.item, value] } });
    setSelectionType('custom');
  };
  const handleRemoveDay = (value) => {
    const item = targeting.day.item.filter((day) => day !== value);
    dispatchTargeting({ type: 'day', payload: { ...targeting.day, item } });
    setSelectionType('custom');
  };
  const handleAddHour = (value) => {
    dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item: [...targeting.hour.item, value] } });
    setSelectionType('custom');
  };
  const handleRemoveHour = (value) => {
    const item = targeting.hour.item.filter((hour) => hour !== value);
    dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item } });
    setSelectionType('custom');
  };

  const handleValueChange = (value) => {
    switch (value) {
      case 'all':
        dispatchTargeting({ type: 'day', payload: { ...targeting.day, item: [] } });
        dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item: [] } });
        setSelectionType(value);
        break;
      case 'workdays':
        dispatchTargeting({ type: 'day', payload: { ...targeting.day, item: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'] } });
        dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item: [] } });
        setSelectionType(value);
        break;
      case 'weekends':
        dispatchTargeting({ type: 'day', payload: { ...targeting.day, item: ['saturday', 'sunday'] } });
        dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item: [] } });
        setSelectionType(value);
        break;
      case 'workinghours':
        dispatchTargeting({ type: 'day', payload: { ...targeting.day, item: [] } });
        dispatchTargeting({ type: 'hour', payload: { ...targeting.hour, item: ['9', '10', '11', '12', '13', '14', '15', '16'] } });
        setSelectionType(value);
        break;
      default:
        setSelectionType(value);
        return null;
    }
  };

  return (
    <div>
      <Row style={{ alignItems: 'center' }}>
        <div className={styles.headTabInfo}>
          <div>
            <ClockCircleOutlined />
            <p>Target Day & Time</p>
          </div>
          <p>Target specific time of the day and day in the week. If you don't make a selection, campaign will be active 24/7.</p>
        </div>
      </Row>
      <div className={styles.actionTimeButtons}>
        <Button onClick={() => handleValueChange('all')} type={selectionType === 'all' ? 'primary' : 'default'} value="all">
          All days & time
        </Button>
        <Button onClick={() => handleValueChange('workdays')} type={selectionType === 'workdays' ? 'primary' : 'default'} value="workdays">
          Workdays
        </Button>
        <Button onClick={() => handleValueChange('weekends')} type={selectionType === 'weekends' ? 'primary' : 'default'} value="weekends">
          Weekends
        </Button>
        <Button
          onClick={() => handleValueChange('workinghours')}
          type={selectionType === 'workinghours' ? 'primary' : 'default'}
          value="workinghours"
        >
          Working Hours
        </Button>
        <Button onClick={() => handleValueChange('custom')} type={selectionType === 'custom' ? 'primary' : 'default'} value="custom">
          Custom
        </Button>
      </div>
      <TimesTable
        handleAddHour={handleAddHour}
        handleRemoveHour={handleRemoveHour}
        handleAddDay={handleAddDay}
        handleRemoveDay={handleRemoveDay}
        targeting={targeting}
      />
      <p className={styles.timesInfoLabel}>All times are displayed in UTC+00:00 time zone.</p>
      <Row style={{ flexFlow: 'row-reverse' }}>
        <Button style={{ marginTop: '20px', borderRadius: '4px' }} onClick={() => changeActiveTab('ips')}>
          Next Step
        </Button>
      </Row>
    </div>
  );
};
